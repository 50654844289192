import { Grid, useTheme } from "@mui/material";
import React, { ReactNode } from "react";

interface CardMaxHeightProps {
  children: ReactNode;
}

const CardMaxHeight: React.FC<CardMaxHeightProps> = ({ children }) => {
  const theme = useTheme();
  return (
    <Grid
      container
      sx={{
        height: "100%",
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          background: theme.palette.background.paper,
          px: 2,
          py: 2,
          borderRadius: 2,
        }}
      >
        {children}
      </Grid>
    </Grid>
  );
};

export default CardMaxHeight;
