import {
  StepCurrentIcon,
  StepFinishedIcon,
  StepPendingIcon,
} from "@components/Icons";
import { Box, Grid, Typography } from "@mui/material";
import React, { useRef } from "react";

interface OrderStatusProgressProps {
  step: number;
  statusList?: Array<{
    description: string;
    isMarked?: boolean;
  }>;
}

//NOTE: Progesso com  3 tipos de status (icones): Finalizados -> Atual -> Pendentes
const OrderStatusProgressVertical: React.FC<OrderStatusProgressProps> = ({
  step,
  statusList = [],
}) => {
  const firstText = useRef<HTMLParagraphElement>(null);
  const lastText = useRef<HTMLParagraphElement>(null);

  const getReference = (index) => {
    return index === 0
      ? firstText
      : index === statusList.length - 1
      ? lastText
      : null;
  };

  const getIcon = (index) => {
    if (step === index) return <StepCurrentIcon />;
    if (step > index) return <StepFinishedIcon />;
    return <StepPendingIcon />;
  };

  return (
    <Box
      display={"flex"}
      flex={1}
      flexDirection={"column"}
      alignContent={"center"}
    >
      <Grid
        container
        direction="column"
        alignItems="left"
        justifyContent={"center"}
        sx={{
          pl: "10px",
          pr: "10px",
        }}
      >
        {statusList.map((item, index) => {
          const hasNext = index + 1 < statusList.length;

          return (
            <React.Fragment key={index}>
              <Grid item display={"flex"} alignItems="center">
                {getIcon(index)}
                <Typography
                  ref={getReference(index)}
                  ml={1}
                  fontSize={12}
                  fontWeight={step === index ? 700 : 400}
                  noWrap
                >
                  {item.description}
                </Typography>
              </Grid>
              {hasNext && (
                <Grid item alignItems="left" flex={1}>
                  <Box
                    border={0.8}
                    marginLeft={"10px"}
                    height="30px"
                    width="1px"
                    borderColor={"shadesOfDark.dark"}
                  />
                </Grid>
              )}
            </React.Fragment>
          );
        })}
      </Grid>
    </Box>
  );
};

export default OrderStatusProgressVertical;
