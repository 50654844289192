import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import Button from "@components/Button";
import Modal from "@components/Modal";
import { useHistory } from "react-router-dom";
import iconTableLastOrderFT from "../../../../../assets/iconTableLastOrderFT.svg";
import ROUTES from "@config/routes";
import useStyles from "./styles";

interface ConfirmationModalProps {
  open: boolean;
  programNumber: string;
  onClose: () => void;
}

const ModalFinalCreateTransport: React.FC<ConfirmationModalProps> = ({
  open,
  programNumber,
  onClose,
}) => {
  const history = useHistory();
  const classes = useStyles();

  const handleConfirm = () => {
    onClose();
    history.push(ROUTES.USER_ROUTES.ROADLOAD);
  };

  return (
    <Modal open={open} onCloseCallback={onClose} maxWidth="60vw" hiddenButton>
      <Box className={classes.modalContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img
              src={iconTableLastOrderFT}
              alt="Icone confirmação"
              className={classes.icon}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" className={classes.title}>
              Programação
              <span className={classes.programNumber}>{programNumber}</span>
              criada com sucesso.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2" className={classes.subtitle}>
              Informe o número da programação ao faturista para prosseguir com o
              carregamento.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleConfirm}
            >
              Ok, entendi
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ModalFinalCreateTransport;
