import { Typography } from "@material-ui/core";
import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";

interface MessageInfoProps {
  title: string;
}

const MessageInfo: React.FC<MessageInfoProps> = ({ title }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const styles = {
    margin: isMobile ? "1rem 1rem 0 1rem" : "0 1rem 1rem 1rem",
  };
  return (
    <Typography
      style={{
        fontSize: isMobile ? "1.2rem" : "1.5rem",
        margin: styles.margin,
        textAlign: isMobile ? "center" : "left",
        fontWeight: "bold",
      }}
    >
      {title}
    </Typography>
  );
};

export default MessageInfo;
