import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";

interface ScheduleInfoProps {
  agendamento: string;
  dataAgendada: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.shadesOfDark.white,
    boxShadow: theme.shadows[1],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    marginTop: theme.spacing(2),
  },
  leftContent: {
    flex: 1,
    marginRight: theme.spacing(2),
  },
  rightContent: {
    flex: 1,
    textAlign: "right",
  },
  title: {
    fontWeight: 700,
    color: theme.palette.primary.highlightedplus,
    opacity: 0.6,
    fontSize: "1.2rem",
  },
  value: {
    fontWeight: 500,
    fontSize: "1rem",
    color: theme.palette.primary.highlightedplus,
    opacity: 0.6,
  },
  valueHighlight: {
    fontWeight: 500,
    color: theme.palette.success.light,
    fontSize: "1.2rem",
  },
}));

const ScheduleInfo: React.FC<ScheduleInfoProps> = ({
  agendamento,
  dataAgendada,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.leftContent}>
        <Typography className={classes.title}>Agendamento</Typography>
        <Typography className={classes.value}>{agendamento}</Typography>
      </Box>

      <Box className={classes.rightContent}>
        <Typography className={classes.title}>Data agendada</Typography>
        <Typography className={classes.valueHighlight}>
          {dataAgendada}
        </Typography>
      </Box>
    </Box>
  );
};

export default ScheduleInfo;
