import React, { useState } from "react";
import { Grid, IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  CardHeadTypography,
  CardBodyTypography,
} from "@pages/User/RoadLoad/resource/typography";
import { IScheduledLoad } from "@pages/User/RoadLoad/model";
import {
  cardExpandColumns,
  cardExpandItemsColumns,
} from "@pages/User/RoadLoad/resource/card/header";
import { RoadLoadRedIcon } from "@components/Icons";
import {
  applyColor,
  formatAllFistLetterCapitalized,
  formatDateAndHourPTBR,
  formatToAmountLiters,
  maskCNPJ,
} from "@utils/index";
import { KEY } from "@utils/enum";
import { useTheme } from "@material-ui/core";

interface CardExpandProps {
  data?: IScheduledLoad | null | undefined;
}

const CardExpandRoad: React.FC<CardExpandProps> = (props) => {
  const { data } = props;
  const theme = useTheme();
  const [expandedRow, setExpandedRow] = useState<string | null>(null);
  const salesOrder = data?.salesOrder || [];

  const handleExpandRow = (rowId: string | undefined) => {
    if (rowId) {
      setExpandedRow(expandedRow === rowId ? null : rowId);
    } else {
      setExpandedRow(null);
    }
  };

  const dataHeader = {
    Ref: data?.Ref ?? "-",
    scheduleDate: data?.scheduleDate ?? "-",
    quantity: data?.totalAmountLiters ?? "-",
    truckDriver: data?.truckDriver ?? "-",
    filial: data?.filial ?? "-",
  };

  const dataLiner = salesOrder?.map((item) => {
    return {
      order: item?.order ?? "-",
      cnpj: item?.cnpj ?? "-",
      quantity: item?.quantity ?? "-",
      deliveryDate: item?.deliveryDate ?? "-",
      status: item?.status ?? "-",
      customer: item?.customer ?? "-",
      product: item?.product ?? "-",
    };
  });

  const applyTypography = (data) => {
    return <CardBodyTypography>{data ?? ""}</CardBodyTypography>;
  };

  const applyTypographyHead = (data) => {
    return <CardHeadTypography>{data ?? ""}</CardHeadTypography>;
  };

  const formatEntrys = (key: string, data: string, row: IScheduledLoad) => {
    switch (key) {
      case KEY.TRUCKDRIVER:
      case KEY.CUSTOMER:
        return applyTypography(data);
      case KEY.CNPJ:
        return maskCNPJ(data);
      case KEY.QUANTITY:
        return applyTypography(formatToAmountLiters(data));
      case KEY.STATUS:
        return applyTypography(
          <span style={{ color: applyColor(KEY.STATUS, data, theme) }}>
            {data}
          </span>
        );
      case KEY.SCHEDULEDATE:
        return applyTypography(
          <span style={{ color: applyColor(KEY.SCHEDULEDATE, data, theme) }}>
            {formatDateAndHourPTBR(data, row["timezone"])}
          </span>
        );
      default:
        return applyTypography(formatAllFistLetterCapitalized(data));
    }
  };

  const Header = () => {
    return (
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
          background: "#fff",
          gap: 2,
          px: 0.8,
          py: 1.6,
          borderRadius: "8px",
        }}
      >
        <Grid item>
          <RoadLoadRedIcon style={{ width: "35px" }} />{" "}
        </Grid>

        {cardExpandColumns.map((col, colIndex) => {
          const key = col.key;
          const value = dataHeader[col.key];

          return (
            <Grid
              item
              key={colIndex}
              sx={{
                textAlign: col.align ?? "auto",
                border: "0px solid red",
                minWidth: col.minWidth ?? "inherit",
              }}
            >
              <Grid item sx={{ pt: "1rem", pb: "1rem" }}>
                {applyTypographyHead(col.label)}
              </Grid>
              <Grid item>{data ? formatEntrys(key, value, data) : null}</Grid>
            </Grid>
          );
        })}

        <Grid item>
          <IconButton
            aria-expanded={expandedRow === data?.Ref}
            onClick={() => handleExpandRow(data?.Ref)}
          >
            <ExpandMoreIcon
              sx={{
                fontSize: "30px",
                transform:
                  expandedRow === data?.Ref ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  const Lines = () => {
    return (
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          flexWrap: "wrap",
          background: "#fff",
          mt: "2px",
          gap: 2,
          px: 2,
          py: 1.6,
          borderRadius: "8px",
        }}
      >
        {cardExpandItemsColumns?.map((col, colIndex) => {
          const key = col.key;
          return (
            <Grid item key={colIndex}>
              <Grid item sx={{ pt: "1rem", pb: "1rem" }}>
                {applyTypographyHead(col.label)}
              </Grid>
              {dataLiner && dataLiner.length > 0 ? (
                dataLiner.map((data, dataIndex) => {
                  const value = data[col.key as keyof typeof data];
                  return (
                    <div key={dataIndex}>
                      <Grid item sx={{ pt: "1rem", pb: "1rem" }}>
                        <Grid item>
                          {data && value
                            ? formatEntrys(key, value, data)
                            : null}
                        </Grid>
                      </Grid>
                    </div>
                  );
                })
              ) : (
                <div>-</div>
              )}
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return (
    <Grid
      container
      sx={{
        justifyContent: "center",
      }}
    >
      <Header />

      {expandedRow != null && <Lines />}
    </Grid>
  );
};

export { CardExpandRoad };
