import React, { useCallback } from "react";
import { Box, Grid } from "@mui/material";
import { WarnRedIcon } from "@components/Icons";
import Button from "@components/Button";
import Modal from "@components/Modal";
import { TypographyTitle } from "../typography";
import { Typography } from "@material-ui/core";

interface Props {
  open: boolean;
  onClose: () => void;
  handleCancelProgramming: () => Promise<void>;
  refSchedule?: string;
}

const ModalCancelProgramming: React.FC<Props> = ({
  open,
  onClose,
  refSchedule,
  handleCancelProgramming,
}) => {
  const handleSubmit = useCallback(async () => {
    await handleCancelProgramming();
    onClose();
  }, [handleCancelProgramming, onClose]);

  return (
    <Modal open={open} onClose={onClose} hiddenButton>
      {({ handleClose }) => (
        <Box
          sx={{
            py: { xs: 1, md: 2 },
            px: { xs: 0.3, md: 0.5 },
            width: { xs: "280px", md: "420px" },
          }}
        >
          <Grid container gap={4}>
            <Grid item xs={12}>
              <WarnRedIcon style={{ width: 90, height: 90 }} />
            </Grid>
            <Grid item xs={12}>
              <TypographyTitle>
                {`Tem certeza que deseja cancelar a Programação de ${refSchedule}?`}
              </TypographyTitle>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ fontSize: 14 }}>
                Ao cancelar, as ordens ficarão disponíveis para serem
                programadas novamente.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container gap={3} justifyContent={"center"}>
                <Grid item>
                  <Button variant="outlined" onClick={handleClose}>
                    Cancelar
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" onClick={handleSubmit}>
                    Confirmar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </Modal>
  );
};

export default ModalCancelProgramming;
