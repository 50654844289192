import React, { useState } from "react";
import { Box, Grid, useMediaQuery } from "@mui/material";
import { Link, Typography, useTheme } from "@material-ui/core";
import { Link as LinkRouter, useHistory } from "react-router-dom";
import ROUTES from "@config/routes";
import OrderStatusProgress from "@components/ButtonGroupOrderStatusProgress/ButtonGroupOrderStatusProgress";
import ProgrammingSectionOne from "./ProgrammingSectionOne";
import ProgrammingSectionTwo from "./ProgrammingSectionTwo";
import ProgrammingSectionThree from "./ProgrammingSectionThree";
import { ArrowLeftRedIcon } from "@components/Icons";
import Layout from "@components/Layout";
import BreadcrumbAndTitle from "@components/BreadcrumbAndTitle";
import { BreadcrumbChild } from "@components/BreadcrumbCustom";
import { TypographyTitle } from "../resource/typography";
import CardMaxHeight from "@components/CardMaxHeight/CardMaxHeight";
import { useProgrammingTransport } from "@context/ProgrammingTransport/ProgrammingTransportContext";
import { CardExpandRoad } from "../resource/card/CardExpand";
import OrderStatusProgressVertical from "@components/ButtonGroupOrderStatusProgressVertical/ButtonGroupOrderStatusProgressVertical";

enum Steps {
  SELECIONAR = 0,
  CARREGAR = 1,
  CONFIRMAR = 2,
}

enum StepDescriptions {
  SELECIONAR = "Selecionar",
  CARREGAR = "Carregar",
  CONFIRMAR = "Confirmar",
}

const breadcrumbItems: BreadcrumbChild[] = [
  {
    link: ROUTES.USER_ROUTES.HOME,
    label: "Início",
  },
  {
    label: "Agenda e Vem",
  },
];

const Programming = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const history = useHistory();

  const [step, setStep] = useState(Steps.SELECIONAR);

  const { rowSelected } = useProgrammingTransport();

  const getStepTitle = (step: Steps): string => {
    const stepTitles = {
      [Steps.SELECIONAR]: "Programar carregamento 1/3",
      [Steps.CARREGAR]: "Programar carregamento 2/3",
      [Steps.CONFIRMAR]: "Programar carregamento 3/3",
    };

    return stepTitles[step] || "";
  };

  const renderStepTitle = (step: Steps) => {
    const title = getStepTitle(step);

    return (
      <Typography
        style={{
          fontSize: isMobile ? "1.6rem" : "2rem",
          fontWeight: 700,
          color: isMobile
            ? theme.palette.text.primary
            : theme.palette.error.light,
        }}
      >
        {title}
      </Typography>
    );
  };

  const Breadcrumb = () => {
    return (
      <TypographyTitle>
        <span
          onClick={() => {
            history.push(ROUTES.USER_ROUTES.ROADLOAD);
          }}
          style={{ cursor: "pointer", opacity: 0.5 }}
        >
          Carregamentos rodoviários
        </span>{" "}
        {">"}{" "}
        <span
          onClick={() => {
            history.push(ROUTES.USER_ROUTES.ROADLOAD);
          }}
          style={{ cursor: "pointer", opacity: 0.5 }}
        >
          Todos os carregamentos
        </span>{" "}
        {">"} <span>Programar Carregamento</span>
      </TypographyTitle>
    );
  };

  const handleNextStep = () => {
    setStep((prevStep) => Math.min(prevStep + 1, Steps.CONFIRMAR));
  };

  const handlePreviousStep = () => {
    setStep((prevStep) => Math.max(prevStep - 1, Steps.SELECIONAR));
  };

  const renderStepContent = () => {
    const stepComponents = {
      [Steps.SELECIONAR]: <ProgrammingSectionOne onNext={handleNextStep} />,
      [Steps.CARREGAR]: (
        <ProgrammingSectionTwo
          onNext={handleNextStep}
          onBack={handlePreviousStep}
        />
      ),
      [Steps.CONFIRMAR]: (
        <ProgrammingSectionThree onBack={handlePreviousStep} />
      ),
    };

    return stepComponents[step] || null;
  };

  return (
    <>
      {isMobile ? (
        <div>
          <Box
            sx={{
              margin: isMobile ? 0 : "0 4rem",
              width: isMobile ? "100%" : "auto",
              padding: isMobile ? "2rem" : "1rem",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              boxShadow: 1,
            }}
          >
            <Link
              component={LinkRouter}
              to={ROUTES.USER_ROUTES.ROADLOAD}
              underline="none"
            >
              <ArrowLeftRedIcon />
            </Link>
            <Box width={"2rem"} />
            {renderStepTitle(step)}
          </Box>

          <Box
            sx={{
              margin: isMobile ? 0 : "0 4rem",
              width: isMobile ? "100%" : "auto",
              padding: isMobile ? "2rem" : "1rem",
              pt: "4rem",
            }}
          >
            <Grid item xs={12}>
              <OrderStatusProgress
                step={step}
                statusList={[
                  { description: StepDescriptions.SELECIONAR },
                  { description: StepDescriptions.CARREGAR },
                  { description: StepDescriptions.CONFIRMAR },
                ]}
              />
            </Grid>

            <Grid item xs={12}>
              {step === Steps.SELECIONAR && (
                <ProgrammingSectionOne onNext={handleNextStep} />
              )}

              {step === Steps.CARREGAR && (
                <ProgrammingSectionTwo
                  onNext={handleNextStep}
                  onBack={handlePreviousStep}
                />
              )}

              {step === Steps.CONFIRMAR && (
                <ProgrammingSectionThree onBack={handlePreviousStep} />
              )}
            </Grid>
          </Box>
        </div>
      ) : (
        <Layout disableToolbarMobile={true} disableToolbarCNPJ={true}>
          <Box
            style={{
              margin: "0 4rem",
              width: "auto",
              padding: "1rem",
            }}
          >
            <BreadcrumbAndTitle
              breadcrumbData={breadcrumbItems}
              title="Agenda e Vem"
            />

            <Grid
              item
              xs={12}
              style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
            >
              <Breadcrumb />
            </Grid>
          </Box>
          <Box
            style={{
              margin: "0 4rem",
              width: "auto",
              padding: "1rem",
              display: "flex",
              flexDirection: "column",
              paddingBottom: "4rem",
            }}
          >
            <Grid item xs={12} style={{ flex: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={4} md={4} lg={2}>
                  <CardMaxHeight>
                    <Grid item xs={12}>
                      <OrderStatusProgressVertical
                        step={step}
                        statusList={[
                          { description: StepDescriptions.SELECIONAR },
                          { description: StepDescriptions.CARREGAR },
                          { description: StepDescriptions.CONFIRMAR },
                        ]}
                      />
                    </Grid>
                  </CardMaxHeight>
                </Grid>
                <Grid item xs={8} md={8} lg={10}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <CardExpandRoad data={rowSelected} />
                    </Grid>
                    <Grid item xs={12}>
                      <CardMaxHeight>
                        <Grid container>
                          <Grid item xs={12} padding={1} mb={2}>
                            {renderStepTitle(step)}
                          </Grid>
                        </Grid>

                        {renderStepContent()}
                      </CardMaxHeight>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Layout>
      )}
    </>
  );
};

export default Programming;
