import { makeStyles, Theme, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: theme.spacing(2),
      marginBottom: "2rem",
    },
    statusItem: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
      fontSize: "1.3rem",
      color: theme.palette.text.primary,
      marginBottom: "2rem",
    },
    boxVazio: {
      width: "1rem",
      height: "1rem",
      border: "1px solid #ccc",
      borderRadius: "0.2rem",
      backgroundColor: theme.palette.shadesOfDark.white,
    },
    boxCarregado: {
      width: "1rem",
      height: "1rem",
      borderRadius: "0.2rem",
      backgroundColor: theme.palette.primary.main,
    },
    boxOcupado: {
      width: "1rem",
      height: "1rem",
      borderRadius: "0.2rem",
      backgroundColor: theme.palette.shadesOfDark.dark,
    },
    caption: {
      fontSize: "10pt",
    },
  })
);

export default useStyles;
