import appConfig from "@config/appConfig";
import { IGetCardTitleService } from "@modules/cardTitles/models/IGetCardTitleService";
import { GetCardTitleService } from "@modules/cardTitles/services/GetCardTitleService";
import { IFilterComissionService } from "@modules/comissionCalculator/models/IFilterComissionService";
import { FilterComissionService } from "@modules/comissionCalculator/services/FilterComissionService";
import { ICommunicationService } from "@modules/communications/model/ICommunication";
import { CommunicationService } from "@modules/communications/service/CommunicationService";
import { IEmployeeService } from "@modules/employee/models/IEmployeeService";
import { EmployeeService } from "@modules/employee/service/EmployeeService";
import { IDownloadPDFInvoicesService } from "@modules/invoices/models/IDownloadPDFInvoicesService";
import { IQueryInvoicesService } from "@modules/invoices/models/IQueryInvoicesService";
import { DownloadPDFInvoicesService } from "@modules/invoices/services/IDownloadPDFInvoicesService";
import { QueryInvoicesService } from "@modules/invoices/services/QueryInvoicesService";
import { IGetLastOrdersService } from "@modules/lastOrders/models/IGetLastOrdersService";
import { GetLastOrdersService } from "@modules/lastOrders/services/GetLastOrdersService";
import { IListLoginImageService } from "@modules/loginImages/models/IListLoginImageService";
import { IRemoveLoginImageService } from "@modules/loginImages/models/IRemoveLoginImageService";
import { IUploadLoginImageService } from "@modules/loginImages/models/IUploadLoginImageService";
import { ListLoginImageService } from "@modules/loginImages/service/ListLoginImagesService";
import { RemoveLoginImageService } from "@modules/loginImages/service/RemoveLoginImageService";
import { UploadLoginImageService } from "@modules/loginImages/service/UploadLoginImageService";
import { IListLogsService } from "@modules/logs/models/IListLogsService";
import { ListLogsService } from "@modules/logs/service/ListLogsService";
import { IGetNozzlesService } from "@modules/nozzles/models";
import { GetNozzlesService } from "@modules/nozzles/services";
import { ICancelOrderService } from "@modules/orders/models/ICancelOrderService";
import { IDownloadByLineService } from "@modules/orders/models/IDownlodByLine";
import { IGetClientInfoService } from "@modules/orders/models/IGetClientInfoService";
import { IGetPriceProductsService } from "@modules/orders/models/IGetPriceProductsService";
import { ITimeLimitLocationService } from "@modules/orders/models/ITimeLimitLocationService";
import { IUpdateOrderService } from "@modules/orders/models/IUpdateOrderService";
import { CancelOrderService } from "@modules/orders/services/CancelOrderService";
import { DownloadByLineService } from "@modules/orders/services/DownloadByLine";
import { GetClientInfoService } from "@modules/orders/services/GetClientInfoService";
import { GetPriceProductsService } from "@modules/orders/services/GetPriceProductsService";
import { TimeLimitLocationService } from "@modules/orders/services/TimeLimitLocationService";
import { UpdateOrderService } from "@modules/orders/services/UpdateOrderService";
import { IProductBlockService } from "@modules/productBlock/models/IProductBlockService";
import { ProductBlockService } from "@modules/productBlock/services/ProductBlockService";
import { IGetProjectionSalesService } from "@modules/projectionSales/models/IGetProjectionSalesService";
import { ISendProjectionSalesService } from "@modules/projectionSales/models/ISendProjectionSalesService";
import { GetProjectionSalesService } from "@modules/projectionSales/service/GetProjectionSalesService";
import { SendProjectionSalesService } from "@modules/projectionSales/service/SendProjectionSalesService";
import { IFilterAllReclamationService } from "@modules/reclamation/models/IFilterAllReclamationService";
import { IGetOneReclamationUserService } from "@modules/reclamation/models/IGetOneReclamationUserService";
import { IGetReclamationAdminService } from "@modules/reclamation/models/IGetReclamationAdminService";
import { ISendReclamationCloseService } from "@modules/reclamation/models/ISendReclamationCloseService";
import { ISendReclamationService } from "@modules/reclamation/models/ISendReclamationService";
import { FilterAllReclamationService } from "@modules/reclamation/services/FilterAllReclamationService";
import { GetOneReclamationUserService } from "@modules/reclamation/services/GetOneReclamationUserService";
import { GetReclamationAdminService } from "@modules/reclamation/services/GetReclamationAdminService";
import { SendReclamationCloseService } from "@modules/reclamation/services/SendReclamationCloseService";
import { SendReclamationService } from "@modules/reclamation/services/SendReclamationService";
import { IGetSalesLastMonthService } from "@modules/salesLastMonth/model";
import { GetSalesLastMonthService } from "@modules/salesLastMonth/service";
import { IGetProductService } from "@modules/salesPerformance/models/IGetProductService";
import { IGetSalesPerformanceService } from "@modules/salesPerformance/models/IGetSalesPerformanceService";
import { GetProductService } from "@modules/salesPerformance/services/GetProductService";
import { GetSalesPerformanceService } from "@modules/salesPerformance/services/GetSalesPerformanceService";
import { IGetSalesVolumeService } from "@modules/salesVolume/models/IGetSalesVolumeService";
import { GetSalesVolumeService } from "@modules/salesVolume/services/GetSalesVolumeService";
import { IGetSalesVolumeServiceHistory } from "@modules/salesVolumeHistory/models/IGetSalesVolumeServiceHistory";
import { GetSalesVolumeServiceHistory } from "@modules/salesVolumeHistory/services/GetSalesVolumeServiceHistory";
import { ISimulateLoadService } from "@modules/simulation/models/ISimulateLoadService";
import { IGetSummaryChartService } from "@modules/summaryChart/model";
import { GetSummaryChartService } from "@modules/summaryChart/service";
import { ICreatePDFTitlesService } from "@modules/titles/models/ICreatePDFTitlesService";
import { IDownloadAllReportTitlesService } from "@modules/titles/models/IDownloadAllReportTitlesService";
import { IDownloadPDFBoletoTitlesService } from "@modules/titles/models/IDownloadPDFBoletoTitlesService";
import { IGetTitleImagesSeenOrNotService } from "@modules/titles/models/IGetTitleImagesSeenOrNotService";
import { IMarkImageAsSeenService } from "@modules/titles/models/IMarkImageAsSeenService";
import { IQueryTitlesService } from "@modules/titles/models/IQueryTitlesService";
import { IUploadTitleImageService } from "@modules/titles/models/IUploadTitleImageService";
import { CreatePDFTitlesService } from "@modules/titles/services/CreatePDFTitlesService";
import { DownloadAllReportTitlesService } from "@modules/titles/services/DownloadAllReportTitlesService";
import { DownloadPDFBoletoTitlesService } from "@modules/titles/services/DownloadPDFBoletoTitlesService";
import { GetTitleImagesSeenOrNotService } from "@modules/titles/services/GetTitleImagesSeenOrNotService";
import { MarkImageAsSeenService } from "@modules/titles/services/MarkImageAsSeenService";
import { QueryTitlesService } from "@modules/titles/services/QueryTitlesService";
import { UploadTitleImageService } from "@modules/titles/services/UploadTitleImageService";
import { IAuthService } from "@modules/user/models/IAuthService";
import { IBlockUserService } from "@modules/user/models/IBlockUserService";
import { IChangePasswordService } from "@modules/user/models/IChangePasswordService";
import { ICreateUserService } from "@modules/user/models/ICreateUserService";
import { IGetDetailsInstantRewardPoshService } from "@modules/user/models/IGetDetailsInstantRewardPoshService";
import { IGetHistoryQtUserRegisterService } from "@modules/user/models/IGetHistoryQtUserRegisterService";
import { IGetHistoryTotalQtVolLTPerProductService } from "@modules/user/models/IGetHistoryTotalQtVolLTPerProductService";
import { IGetHistoryTotalQtVolLTService } from "@modules/user/models/IGetHistoryTotalQtVolLTService";
import { IGetTotalAverageTicketService } from "@modules/user/models/IGetTotalAverageTicketService";
import { IGetTotalQTVolLTService } from "@modules/user/models/IGetTotalQTVolLTService";
import { IGetTotalTaxReceiptWithActionsService } from "@modules/user/models/IGetTotalTaxReceiptWithActionsService";
import { IListCustomersMarketingPlanService } from "@modules/user/models/IListCustomersMarketingPlanService";
import { IListUsersService } from "@modules/user/models/IListUsersService";
import { ISendEmailToNotVerifiedEmailUsers } from "@modules/user/models/ISendEmailToNotVerifiedEmailUsers";
import { IUnlockUserService } from "@modules/user/models/IUnlockUserService";
import { IUpdateUserService } from "@modules/user/models/IUpdateUserService";
import { AuthService } from "@modules/user/services/AuthService";
import { BlockUserService } from "@modules/user/services/BlockUserService";
import { ChangePasswordService } from "@modules/user/services/ChangePasswordService";
import { CreateUserService } from "@modules/user/services/CreateUserService";
import { GetDetailsInstantRewardPoshService } from "@modules/user/services/GetDetailsInstantRewardPoshService";
import { GetHistoryQtUserRegisterService } from "@modules/user/services/GetHistoryQtUserRegisterService";
import { GetHistoryTotalQtVolLTPerProductService } from "@modules/user/services/GetHistoryTotalQtVolLTPerProductService";
import { GetHistoryTotalQtVolLTService } from "@modules/user/services/GetHistoryTotalQtVolLTService";
import { GetTotalAverageTicketService } from "@modules/user/services/GetTotalAverageTicketService";
import { GetTotalQTVolLTService } from "@modules/user/services/GetTotalQTVolLTService";
import { GetTotalTaxReceiptWithActionsService } from "@modules/user/services/GetTotalTaxReceiptWithActionsService";
import { ListCustomersMarketingPlanService } from "@modules/user/services/ListCustomersMarketingPlanService";
import { ListUsersService } from "@modules/user/services/ListUsersInterface";
import { SendEmailToNotVerifiedEmailUsers } from "@modules/user/services/SendEmailToNotVerifiedEmailUsers";
import { UnlockUserService } from "@modules/user/services/UnlockUserService";
import { UpdateUSerService } from "@modules/user/services/UpdateUserService";
import { IGetYourSalesService } from "@modules/yourSales/models/IGetYourSalesService";
import { GetYourSalesService } from "@modules/yourSales/services/GetYourSalesService";
import { Container } from "inversify";
import { ICreateHandoutAckService } from "../modules/handouts/models/ICreateHandoutAckService";
import { ICreateHandoutService } from "../modules/handouts/models/ICreateHandoutService";
import { IGetHandoutsSeenOrNotService } from "../modules/handouts/models/IGetHandoutsSeenOrNotService";
import { IRemoveHandoutService } from "../modules/handouts/models/IRemoveHandoutService";
import { CreateHandoutAckService } from "../modules/handouts/services/CreateHandoutAckService";
import { CreateHandoutService } from "../modules/handouts/services/CreateHandoutService";
import { GetHandoutsSeenOrNotService } from "../modules/handouts/services/GetHandoutsSeenOrNotService";
import { RemoveHandoutService } from "../modules/handouts/services/RemoveHandoutService";
import AxiosHttpService from "../modules/infra/http/implementations/AxiosHttpService";
import { IHttpService } from "../modules/infra/http/models/IHttpService";
import { ICreateOrderService } from "../modules/orders/models/ICreateOrderService";
import { ICreatePDFOrdersService } from "../modules/orders/models/ICreatePDFOrdersService";
import { IQueryOrdersService } from "../modules/orders/models/IQueryOrdersService";
import { CreateOrderService } from "../modules/orders/services/CreateOrderService";
import { CreatePDFOrdersService } from "../modules/orders/services/CreatePDFOrdersService";
import { QueryOrdersService } from "../modules/orders/services/QueryOrdersService";
import { SimulateLoadService } from "../modules/simulation/services/SimulateLoadService";
import { Types } from "./types";
import { IRoadLoadService } from "@modules/roadLoad/model";
import { RoadLoadService } from "@modules/roadLoad/service";
import { IDriveCustomerService } from "@modules/driverAndCustomer/model/IDriverCustomerService";
import { DriverCustomerService } from "@modules/driverAndCustomer/service/DriverCustomerService";
import { IGetLastPaymentService } from "@modules/orders/models/IGetLastPaymentService";
import { GetLastPaymentService } from "@modules/orders/services/GetLastPaymentService";
import { IDiscountService } from "@modules/discount/model/IDiscountService";
import { DiscountService } from "@modules/discount/service/DiscountService";
import { IProductService } from "@modules/product/models/IProductService";
import { ProductService } from "@modules/product/services/ProductService";
import { IGetBpIdService } from "@modules/user/models/IGetBpIdService";
import { GetBpIdService } from "@modules/user/services/GetBpIdService";
import { IProgrammingTransportService } from "@modules/transport/models/IProgrammingTransportService";
import { ProgrammingTransportService } from "@modules/transport/services/ProgrammingTransportService";

const appIocContainer = new Container({ defaultScope: "Singleton" });

appIocContainer
  .bind<IHttpService>(Types.IHttpService)
  .toConstantValue(AxiosHttpService.factory())
  .whenTargetIsDefault();

appIocContainer
  .bind<IHttpService>(Types.IHttpService)
  .toConstantValue(AxiosHttpService.factory(appConfig.api.urlPump.production))
  .whenTargetNamed("pump");

appIocContainer
  .bind<IHttpService>(Types.IHttpService)
  .toConstantValue(
    AxiosHttpService.factory(
      process.env.REACT_APP_ENV === "development" ||
        window.location.hostname === "localhost"
        ? appConfig.api.urlCognito.development
        : Boolean(window.location.origin.split(".")[0].match(/-dev$/))
        ? appConfig.api.urlCognito.homologation
        : appConfig.api.urlCognito.production
    )
  )
  .whenTargetNamed("cognito");

appIocContainer.bind<IAuthService>(Types.Users.IAuthService).to(AuthService);

appIocContainer
  .bind<IGetSummaryChartService>(Types.SummaryChart.IGetSummaryChartService)
  .to(GetSummaryChartService);

appIocContainer
  .bind<IGetSalesLastMonthService>(
    Types.SalesLastMonth.IGetSalesLastMonthService
  )
  .to(GetSalesLastMonthService);

appIocContainer
  .bind<IDownloadByLineService>(Types.Orders.IDownloadByLineService)
  .to(DownloadByLineService);

appIocContainer
  .bind<IGetYourSalesService>(Types.YourSales.IGetYourSalesService)
  .to(GetYourSalesService);

appIocContainer
  .bind<IGetNozzlesService>(Types.Nozzles.IGetNozzlesService)
  .to(GetNozzlesService);

appIocContainer
  .bind<IEmployeeService>(Types.Employee.IEmployeeService)
  .to(EmployeeService);

appIocContainer
  .bind<IQueryOrdersService>(Types.Orders.IQueryOrdersService)
  .to(QueryOrdersService);

appIocContainer
  .bind<ICreateOrderService>(Types.Orders.ICreateOrderService)
  .to(CreateOrderService);

appIocContainer
  .bind<ICreatePDFOrdersService>(Types.Orders.ICreatePDFOrdersService)
  .to(CreatePDFOrdersService);

appIocContainer
  .bind<IGetHandoutsSeenOrNotService>(
    Types.Handouts.IGetHandoutsSeenOrNotService
  )
  .to(GetHandoutsSeenOrNotService);

appIocContainer
  .bind<IRemoveHandoutService>(Types.Handouts.IRemoveHandoutService)
  .to(RemoveHandoutService);

appIocContainer
  .bind<ICreateHandoutService>(Types.Handouts.ICreateHandoutService)
  .to(CreateHandoutService);

appIocContainer
  .bind<ICreateHandoutAckService>(Types.Handouts.ICreateHandoutAckService)
  .to(CreateHandoutAckService);

appIocContainer
  .bind<IQueryTitlesService>(Types.Titles.IQueryTitlesService)
  .to(QueryTitlesService);

appIocContainer
  .bind<IQueryInvoicesService>(Types.Invoices.IQueryInvoicesService)
  .to(QueryInvoicesService);

appIocContainer
  .bind<IDownloadPDFBoletoTitlesService>(
    Types.Titles.IDownloadPDFBoletoTitlesService
  )
  .to(DownloadPDFBoletoTitlesService);

appIocContainer
  .bind<IDownloadAllReportTitlesService>(
    Types.Titles.IDownloadAllReportTitlesService
  )
  .to(DownloadAllReportTitlesService);

appIocContainer
  .bind<IDownloadPDFInvoicesService>(Types.Invoices.IDownloadPDFInvoicesService)
  .to(DownloadPDFInvoicesService);

appIocContainer
  .bind<IGetTitleImagesSeenOrNotService>(
    Types.Titles.IGetTitleImagesSeenOrNotService
  )
  .to(GetTitleImagesSeenOrNotService);

appIocContainer
  .bind<IMarkImageAsSeenService>(Types.Titles.IMarkImageAsSeenService)
  .to(MarkImageAsSeenService);

appIocContainer
  .bind<IUploadTitleImageService>(Types.Titles.IUploadTitleImageService)
  .to(UploadTitleImageService);

appIocContainer
  .bind<IGetClientInfoService>(Types.Orders.IGetClientInfoService)
  .to(GetClientInfoService);

appIocContainer
  .bind<IGetPriceProductsService>(Types.Orders.IGetPriceProductsService)
  .to(GetPriceProductsService);

appIocContainer
  .bind<IListUsersService>(Types.Users.IListUsersService)
  .to(ListUsersService);

appIocContainer
  .bind<ICreateUserService>(Types.Users.ICreateUserService)
  .to(CreateUserService);

appIocContainer
  .bind<IBlockUserService>(Types.Users.IBlockUserService)
  .to(BlockUserService);

appIocContainer
  .bind<IUnlockUserService>(Types.Users.IUnlockUserService)
  .to(UnlockUserService);

appIocContainer
  .bind<IUpdateUserService>(Types.Users.IUpdateUSerService)
  .to(UpdateUSerService);

appIocContainer
  .bind<IListCustomersMarketingPlanService>(
    Types.Users.IListCustomersMarketingPlanService
  )
  .to(ListCustomersMarketingPlanService);

appIocContainer
  .bind<IGetTotalTaxReceiptWithActionsService>(
    Types.Users.IGetTotalTaxReceiptWithActionsService
  )
  .to(GetTotalTaxReceiptWithActionsService);

appIocContainer
  .bind<IGetTotalQTVolLTService>(Types.Users.IGetTotalQTVolLTService)
  .to(GetTotalQTVolLTService);

appIocContainer
  .bind<IGetHistoryQtUserRegisterService>(
    Types.Users.IGetHistoryQtUserRegisterService
  )
  .to(GetHistoryQtUserRegisterService);

appIocContainer
  .bind<IGetHistoryTotalQtVolLTService>(
    Types.Users.IGetHistoryTotalQtVolLTService
  )
  .to(GetHistoryTotalQtVolLTService);

appIocContainer
  .bind<IGetHistoryTotalQtVolLTPerProductService>(
    Types.Users.IGetHistoryTotalQtVolLTPerProductService
  )
  .to(GetHistoryTotalQtVolLTPerProductService);

appIocContainer
  .bind<IGetDetailsInstantRewardPoshService>(
    Types.Users.IGetDetailsInstantRewardPoshService
  )
  .to(GetDetailsInstantRewardPoshService);

appIocContainer
  .bind<IGetTotalAverageTicketService>(
    Types.Users.IGetTotalAverageTicketService
  )
  .to(GetTotalAverageTicketService);

appIocContainer
  .bind<IListLoginImageService>(Types.LoginImages.IListLoginImageService)
  .to(ListLoginImageService);

appIocContainer
  .bind<IRemoveLoginImageService>(Types.LoginImages.IRemoveLoginImageService)
  .to(RemoveLoginImageService);

appIocContainer
  .bind<IUploadLoginImageService>(Types.LoginImages.IUploadLoginImageService)
  .to(UploadLoginImageService);

appIocContainer
  .bind<ITimeLimitLocationService>(Types.Orders.ITimeLimitLocationService)
  .to(TimeLimitLocationService);

appIocContainer
  .bind<IListLogsService>(Types.Logs.IListLogsService)
  .to(ListLogsService);

appIocContainer
  .bind<ISendReclamationService>(Types.Reclamation.ISendReclamationService)
  .to(SendReclamationService);

appIocContainer
  .bind<IFilterAllReclamationService>(
    Types.Reclamation.IFilterAllReclamationService
  )
  .to(FilterAllReclamationService);

appIocContainer
  .bind<IGetOneReclamationUserService>(
    Types.Reclamation.IGetOneReclamationUserService
  )
  .to(GetOneReclamationUserService);

appIocContainer
  .bind<ISendReclamationCloseService>(
    Types.Reclamation.ISendReclamationCloseService
  )
  .to(SendReclamationCloseService);

appIocContainer
  .bind<ISendEmailToNotVerifiedEmailUsers>(
    Types.Users.ISendEmailToNotVerifiedEmailUsers
  )
  .to(SendEmailToNotVerifiedEmailUsers);

appIocContainer
  .bind<IChangePasswordService>(Types.Users.IChangePasswordService)
  .to(ChangePasswordService);

appIocContainer
  .bind<ICreatePDFTitlesService>(Types.Titles.ICreatePDFTitlesService)
  .to(CreatePDFTitlesService);

appIocContainer
  .bind<ICancelOrderService>(Types.Orders.ICancelOrderService)
  .to(CancelOrderService);

appIocContainer
  .bind<IUpdateOrderService>(Types.Orders.IUpdateOrderService)
  .to(UpdateOrderService);

appIocContainer
  .bind<ISimulateLoadService>(Types.Simulation.ISimulateLoad)
  .to(SimulateLoadService);

appIocContainer
  .bind<IGetLastOrdersService>(Types.LastOrders.IGetLastOrdersService)
  .to(GetLastOrdersService);

appIocContainer
  .bind<IGetCardTitleService>(Types.CardTitle.IGetCardTitleService)
  .to(GetCardTitleService);

appIocContainer
  .bind<IGetProjectionSalesService>(
    Types.ProjectionSales.IGetProjectionSalesService
  )
  .to(GetProjectionSalesService);

appIocContainer
  .bind<ISendProjectionSalesService>(
    Types.ProjectionSales.ISendProjectionSalesService
  )
  .to(SendProjectionSalesService);

appIocContainer
  .bind<IGetSalesPerformanceService>(
    Types.SalesPerformance.IGetSalesPerformanceService
  )
  .to(GetSalesPerformanceService);

appIocContainer
  .bind<IGetProductService>(Types.SalesPerformance.IGetProductService)
  .to(GetProductService);

appIocContainer
  .bind<IFilterComissionService>(Types.Comission.IFilterComissionService)
  .to(FilterComissionService);

appIocContainer
  .bind<IGetSalesVolumeService>(Types.SalesVolume.IGetSalesVolumeService)
  .to(GetSalesVolumeService);

appIocContainer
  .bind<IGetSalesVolumeServiceHistory>(
    Types.SalesVolumeHistory.IGetSalesVolumeServiceHistory
  )
  .to(GetSalesVolumeServiceHistory);

appIocContainer
  .bind<IProductBlockService>(Types.ProductBlock.IProductBlockService)
  .to(ProductBlockService);

appIocContainer
  .bind<ICommunicationService>(Types.Communication.ICommunicationService)
  .to(CommunicationService);

appIocContainer
  .bind<IGetReclamationAdminService>(
    Types.Reclamation.IGetReclamationAdminService
  )
  .to(GetReclamationAdminService);

appIocContainer
  .bind<IRoadLoadService>(Types.RoadLoad.IRoadLoadService)
  .to(RoadLoadService);

appIocContainer
  .bind<IDriveCustomerService>(Types.DriverCustomer.IDriverCustomerService)
  .to(DriverCustomerService);

appIocContainer
  .bind<IGetLastPaymentService>(Types.Orders.IGetLastPaymentService)
  .to(GetLastPaymentService);

appIocContainer
  .bind<IDiscountService>(Types.Discount.IDiscountService)
  .to(DiscountService);

appIocContainer
  .bind<IProductService>(Types.Product.IProductService)
  .to(ProductService);

appIocContainer
  .bind<IGetBpIdService>(Types.Users.IGetBpIdService)
  .to(GetBpIdService);

appIocContainer
  .bind<IProgrammingTransportService>(
    Types.Transport.IProgrammingTransportService
  )
  .to(ProgrammingTransportService);

export { appIocContainer };
