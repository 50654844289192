import { makeStyles, Theme, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    centeredGrid: {
      textAlign: "center",
    },
    boldText: {
      textAlign: "center",
      fontWeight: "bold",
    },
    productInfoText: {
      textAlign: "center",
    },
    container: {
      width: "100%",
    },
    compartmentItem: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: `1px solid ${theme.palette.shadesOfDark.light}`,
      "&:last-child": {
        borderBottom: "none",
      },
    },
    label: {
      display: "flex",
      alignItems: "center",
      gap: "0.5rem",
      cursor: "pointer",
    },
    checkbox: {
      color: theme.palette.primary.main,
    },
    volumeText: {
      fontSize: "1.2rem",
      color: "#666",
    },
  })
);

export default useStyles;
