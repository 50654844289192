import { Form, Formik } from "formik";
import React from "react";

import {
  currentinitialValues,
  validationSchemaNewRequest,
} from "@pages/User/NewRequest/resources/form/IForm";

import {
  ICustomerPayloadRequest,
  INewRequestPayloadRequest,
  IOrders,
  IProductPayloadRequest,
  IProductPayloadResponse,
} from "@pages/User/NewRequest/Model";
import { useForm } from "@pages/User/NewRequest/resources/context/FormContext";
import FormLayout from "@pages/User/NewRequest/resources/form/FormLayout";

import { ICreateOrderDataDTO } from "@modules/orders/dtos/ICreateOrderDataDTO";
import { ICreateOrderService } from "@modules/orders/models/ICreateOrderService";

import { useIoCContext } from "@context/IoCContext/IoCContext";

import useDialogAlert from "@hooks/useDialogAlert";

import { applyTaxAccordingFreight, formatTimezoneToDate } from "@utils/index";
import { FreightType } from "@utils/interfaces";

import { Types } from "@ioc/types";
import { useFlags } from "flagsmith/react";
import { addMinutes, format } from "date-fns";

interface RequestValidateProps {
  children?: React.ReactNode;
}

const RequestValidate: React.FC<RequestValidateProps> = () => {
  const flags = useFlags(["user_create_order_v2"]);
  const {
    productCart,
    selectedAddress,
    dataCustomer,
    selectedFilial,
    setLoadingSubmitButton,
    setNewOrders,
    setDeliveryDate,
  } = useForm();

  const { snackbar } = useDialogAlert();
  const iocContext = useIoCContext();

  const ProductCartEmptyMessage = () => {
    console.info("Carrinho de produtos vazio!");
    snackbar({
      message: "Carrinho de produtos vazio!",
      variant: "error",
    });
  };

  const selectedAddressEmptyMessage = () => {
    console.info("Endereço de entrega não selecionado!");
    snackbar({
      message: "Endereço de entrega não selecionado!",
      variant: "error",
    });
  };

  const selectedFilialEmptyMessage = () => {
    console.info("Filial não selecionada!");
    snackbar({
      message: "Filial não selecionada!",
      variant: "error",
    });
  };

  const dataCustomerEmptyMessage = () => {
    console.info("Dados do cliente não encontrados!");
    snackbar({
      message: "Dados do cliente não encontrados!",
      variant: "error",
    });
  };

  return (
    <Formik
      key={"formik-orders"}
      initialValues={currentinitialValues}
      validationSchema={validationSchemaNewRequest}
      onSubmit={async (values) => {
        /*Logica de submissao do formulario */
        try {
          setLoadingSubmitButton(true);
          if (productCart.length === 0) return ProductCartEmptyMessage();
          if (!selectedAddress) return selectedAddressEmptyMessage();
          if (!selectedFilial) return selectedFilialEmptyMessage();
          if (!dataCustomer) return dataCustomerEmptyMessage();

          const customer: ICustomerPayloadRequest = {
            CNPJ: dataCustomer.CNPJ,
            distributionChannel: dataCustomer.DistributionChannel,
            division: dataCustomer.Division,
            id: dataCustomer.id,
          };

          const products: IProductPayloadRequest[] = productCart.map(
            (product) => {
              return {
                id: product.code!,
                description: product.name!,
                deadlinePayment: parseInt(product.paymentDaysSelected!),
                hashPrice: product.id!,
                payCond: parseInt(product.paymentDays!),
                price: applyTaxAccordingFreight(
                  values.freight,
                  product.value!,
                  product.priceFreight!
                ),
                quantity: parseFloat(product.amount!.replace(".", "")),
                payment: product.payment!,
              };
            }
          );

          const date = new Date(values.dateDelivery) || new Date();

          setDeliveryDate(date); // Data de entrega salva para ser usada como Query Params

          const deliveryDate = format(
            addMinutes(date, date.getTimezoneOffset()),
            "yyyy-MM-dd"
          );

          const newRequestpayload: INewRequestPayloadRequest = {
            address: { id: selectedAddress.key },
            comments: values.observation,
            includeCommentsInInvoice: values.commentsInInvoice,
            customer: customer,
            deliveryDate,
            filialID:
              dataCustomer.withdrawBasis[selectedFilial.filialIndex].filialID, // values.subsidiary
            freightType: values.freight as FreightType, // [`${values.freight}`]
            products: products,
            withdrawBasisID:
              dataCustomer.withdrawBasis[selectedFilial.filialIndex]
                .shippingPoint, //values.withDrawalBase
          };

          const createOrderService = iocContext.serviceContainer.get<
            ICreateOrderService
          >(Types.Orders.ICreateOrderService);

          const response: ICreateOrderDataDTO = flags.user_create_order_v2
            .enabled
            ? await createOrderService.executeV2(newRequestpayload)
            : await createOrderService.execute(newRequestpayload);

          const newOrdersResponse: IOrders[] = response.response.map(
            (order, index) => {
              return {
                id: index.toString(),
                subsidiary:
                  dataCustomer.withdrawBasis[selectedFilial.filialIndex]
                    .branchName,
                numberOrder: order.numberOrder ?? "Não gerada",
                product: order.product as IProductPayloadResponse,
                atemStatus: order.atemStatus ?? "error",
                deliveryDate: order.deliveryDate
                  ? formatTimezoneToDate(order.deliveryDate)
                  : "Não definido",
              };
            }
          );

          setNewOrders(newOrdersResponse);

          if (response.status === "error") {
            snackbar({
              message: "Alguns pedidos não foram efetuados",
              variant: "warning",
            });
          } else {
            snackbar({
              message: "Pedidos efetuados com sucesso!",
              variant: "success",
            });
          }
        } catch (error) {
          snackbar({
            message:
              "Ocorreu um erro ao realizar o pedido, tente novamente mais tarde",
            variant: "error",
          });
        } finally {
          setLoadingSubmitButton(false);
        }
      }}
    >
      {() => {
        return (
          <Form id={"formik-form"}>
            <FormLayout />
          </Form>
        );
      }}
    </Formik>
  );
};

export default RequestValidate;
