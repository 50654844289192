export enum TransportItemType {
  BLOCKED = "blocked",
  ALLOCATED = "allocated",
}

export interface ITransportItem {
  slotIndex: number;
  type: TransportItemType;
  orderRef?: string;
  volume: number;
  productName?: string;
}

export interface ICreateTransportBody {
  plate: string;
  items: ITransportItem[];
}
