import React from "react";
import { Grid } from "@mui/material";
import { Box, Button } from "@material-ui/core";

interface Props {
  handleApprove: () => void;
  handleClose: () => void;
  secondButtonDescription: string;
  firstButtonDescription: string;
  disableSecondButton?: boolean;
}

const GroupMenuBackAndNext: React.FC<Props> = ({
  secondButtonDescription,
  firstButtonDescription,
  handleApprove,
  handleClose,
  disableSecondButton = false,
}) => {
  return (
    <Grid container alignItems="center" justifyContent="flex-end">
      <Grid item>
        <Button
          onClick={handleClose}
          color="primary"
          fullWidth
          style={{ width: "auto", textTransform: "uppercase" }}
        >
          {firstButtonDescription}
        </Button>
      </Grid>
      <Box width={"2rem"} />

      <Grid item>
        <Button
          onClick={handleApprove}
          variant="contained"
          color="primary"
          fullWidth
          style={{ width: "auto", textTransform: "uppercase" }}
          disabled={disableSecondButton}
        >
          {secondButtonDescription}
        </Button>
      </Grid>
    </Grid>
  );
};

export default GroupMenuBackAndNext;
