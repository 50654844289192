import { addDays } from "date-fns";

import {
  ICenters,
  ICreateSchedule,
  ICreateScheduleReponse,
  ICustomers,
  IDrivers,
  IProducts,
  IQueryRoadLoad,
  IRoadLoadResponse,
  IScheduleVacanciesResponse,
  TableMetadataItems,
  Transporte,
} from "@modules/roadLoad/model";

export interface IRoadLoadType {
  // NOTE: Modalidade de Carregamento rodoviario
  icon: React.ReactNode;
  type: string;
  subtitle: string;
  quantity: number;
  loading: boolean;
}

export interface IRoadLoadAll {
  upcomingSchedule?: IRoadLoadResponse;
  scheduled?: IRoadLoadResponse;
  waiting?: IRoadLoadResponse;
  finished?: IRoadLoadResponse;
  blocked?: IRoadLoadResponse;
  canceled?: IRoadLoadResponse;
}

export interface IHandleRoadLoadMethod {
  fetchAllWithFilter: (query?: IQueryRoadLoad) => Promise<void>;
  fetchScheduled: (query?: IQueryRoadLoad) => void;
  fetchWaiting: (query?: IQueryRoadLoad) => void;
  fetchFinished: (query?: IQueryRoadLoad) => void;
  fetchBlocked: (query?: IQueryRoadLoad) => void;
  fetchVacancies: (
    order?: string,
    date?: string
  ) => Promise<IScheduleVacanciesResponse | undefined>;
  fetchCreateSchedule: (
    data: ICreateSchedule,
    scheduleId?: string,
    newOrder?: boolean
  ) => Promise<void>;
  fetchCancelScheduling: (scheduleID: string) => Promise<void>;

  resetVacancies: () => void;
  fetchVacanciesReserve: (order, date, time, scheduleId?) => Promise<boolean>;
  handleSetTabSelected: (tabIndex: number) => void;
  fetchAvailableDate: (orderID, scheduleID?) => Promise<void>;
  resetFirstDateTimeAvailable: () => void;
  resetFilter: () => void;
  resetRoadCreated: () => void;
  fetchDeleteOrder: (
    scheduleGroupIndex: number,
    orderIndex: number
  ) => Promise<void>;
  fetchAddOrder: (scheduleGroupIndex: number, roadsSelected) => Promise<void>;
  fetchDownloadPDF: (
    refID: string,
    scheduleUUID: string,
    rowIdex
  ) => Promise<void>;
}

export interface IRoadLoadFiltersOptions {
  customers?: ICustomers[];
  products?: IProducts[];
  centers?: ICenters[];
  drivers?: IDrivers[];
}

export interface IRoadLoadAllNormalized {
  upcomingScheduled?: IScheduledLoad[];
  scheduledNormalized?: IScheduledLoad[];
  waitingNormalized?: IRoadNotLoad[];
  finishedNormalized?: IScheduledLoad[];
  blockedNormalized?: IRoadBlockedLoad[];
  canceledNormalized?: IScheduledLoad[];
}

export interface IRoadRowCount {
  scheduledRow: number;
  waitingRow: number;
  finishedRow: number;
  blockedRow: number;
  canceledRow: number;
}

export interface ISalesOrder extends TableMetadataItems {
  customerID?: string;
  scheduleID?: string;
  customer: string;
  product: string;
  productID?: string;
  quantity: string;
  order: string;
  filial?: string;
  deliveryDate: string;
  cnpj?: string;
  schedulable?: string;
  status?: string;
  freight?: string;
  Tipo_de_frete?: string;
}

export enum StatusSchedule {
  PENDING = "PEDING",
  PROGRAMMED = "PROGRAMMED",
}

// Note: Interface para carregamentos "agendados" e "concluidos" <> IScheduledLoad
export interface IScheduledLoad {
  cnpj?: string;
  index?: number;
  Ref?: string;
  scheduleGroupID?: string;
  totalAmountLiters?: string;
  customerID?: string;
  Status_Programacao?: StatusSchedule;
  Transporte?: Transporte[];
  Items?: TableMetadataItems[];
  truckDriverId?: string;
  truckDriver?: string;
  filial?: string;
  plate?: string;
  filialName?: string;
  salesOrderQuantity?: string;
  scheduleDate?: string;
  salesOrder?: ISalesOrder[];
  reschedulable?: boolean;
  freight?: string;
  bpId?: string;
  timezone?: string;
  reasonCancellation?: string;
  descriptionCancellation?: string;
  cancelable?: boolean;
  icon?;
}

export interface IRoadNextLoad {
  // NOTE: Proximos Carregamentos <> Carregamentos agendados
  Ref?: string;
  customerID?: string;
  scheduleID?: string;
  customer?: string;
  product?: string;
  quantity?: string;
  order?: string;
  filial?: string;
  deliveryDate?: string;
  scheduleDate?: string;
  truckDriver?: string;
  cnpj?: string;
  schedulable?: boolean;
  freight?: string;
}

export interface IRoadNotLoad extends Omit<IRoadNextLoad, "deliveryDate"> {
  // Carregamentos não realizados
  releaseDate?: string; // Data de liberação
}
export interface IRoadInvoicedLoad extends Omit<IRoadNextLoad, "deliveryDate"> {
  // Carregamentos faturados
  status: string;
  invoiceDate: string; // Data de faturamento
}
export interface IRoadBlockedLoad
  extends Omit<IRoadInvoicedLoad, "invoiceDate"> {
  // Carregamentos bloqueados
  blockedDate: string; // Data de bloqueio
}

export interface IQueryRoadLoadModel extends IQueryRoadLoad {
  customers?: string[];
  scheduleDate?: Date | null;
  products?: string[];
  filiais?: string[];
  driverName?: string;
  plate?: string;
}

const notLoadsMock: IRoadNotLoad[] = [
  {
    customerID: "1125",
    cnpj: "04874416000190",
    customer: "FLUMINENSE TRANS REV RETAL LTDA",
    product: "GASOLINA C COMUM",
    quantity: "1000",
    filial: "C008",
    order: "772835",
    freight: "FOB",
    releaseDate: "2024-12-02T04:00:00.000Z",
    schedulable: true,
  },
  {
    customer: "TESTE 02",
    product: "Etanol Premium",
    quantity: "15000L",
    filial: "Posto 2",
    order: "859382",
    freight: "CIF",
    releaseDate: "2024-12-02T04:00:00.000Z",
    schedulable: true,
  },
  {
    customer: "Bob Johnson",
    product: "Diesel Fintech",
    quantity: "15000L",
    filial: "Posto 3",
    order: "859383",
    freight: "FOB",
    releaseDate: "2024-12-02T04:00:00.000Z",
    schedulable: true,
  },
  {
    customer: "Alice Garcia",
    product: "Gasolina Regular",
    quantity: "18000L",
    filial: "Posto 4",
    order: "859384",
    freight: "FOB",
    releaseDate: "2024-12-02T04:00:00.000Z",
    schedulable: true,
  },
  {
    customer: "John Doe",
    product: "Etanol Regular",
    quantity: "12000L",
    filial: "Posto 5",
    order: "859385",
    freight: "CIF",
    releaseDate: "2024-12-02T04:00:00.000Z",
    schedulable: true,
  },
  {
    customer: "Mary Johnson",
    product: "Diesel Premium",
    quantity: "17000L",
    filial: "Posto 6",
    order: "859386",
    freight: "CIF",
    releaseDate: "2024-12-02T04:00:00.000Z",
    schedulable: true,
  },
  {
    customer: "Carlos Oliveira",
    product: "Gasolina Aditivada",
    quantity: "19000L",
    filial: "Posto 7",
    order: "859387",
    freight: "CIF",
    releaseDate: "2024-12-02T04:00:00.000Z",
    schedulable: true,
  },
  {
    customer: "Ana Silva",
    product: "Etanol Aditivado",
    quantity: "16000L",
    filial: "Posto 8",
    order: "859388",
    releaseDate: "2024-02-02T22:00",
  },
  {
    customer: "David Brown",
    product: "Diesel S-10",
    quantity: "13000L",
    filial: "Posto 9",
    order: "859389",
    releaseDate: "2024-02-02T22:00",
  },
  {
    customer: "Emily White",
    product: "Gasolina Premium",
    quantity: "21000L",
    filial: "Posto 10",
    order: "859390",
    releaseDate: "2024-02-02T22:00",
  },
  {
    customer: "Michael Taylor",
    product: "Etanol S-10",
    quantity: "14000L",
    filial: "Posto 11",
    order: "859391",
    releaseDate: "2024-11-30T22:00",
  },
];

export const scheduledLoadsMock: IScheduledLoad[] = [
  {
    scheduleGroupID: "550e8400-e29b-41d4-a716-446655440000",
    filial: "ATEM-MANAUS",
    truckDriver: "Ruan Gato",
    totalAmountLiters: "20000",
    salesOrderQuantity: "2",
    scheduleDate: new Date().toString(),
    freight: "CIF",
    salesOrder: [
      {
        customerID: "123",
        scheduleID: "1",
        customer: "Posto Ponta Negra Platinum",
        product: "GASOLINA ADITIVADA",
        quantity: "8000",
        order: "432010",
        filial: "Filial A",
        deliveryDate: "2024-11-29T04:00:00.000Z",
        cnpj: "12345678901234",
        schedulable: "true",
        status: "FT",
      },
      {
        customerID: "2",
        scheduleID: "1",
        customer: "Posto Ponta Negra Platinum",
        product: "Etanol Hidratado",
        quantity: "12000",
        order: "432011",
        deliveryDate: "2024-05-10",
        schedulable: "true",
        status: "LIB",
      },
    ],
  },
  {
    scheduleGroupID: "002",
    totalAmountLiters: "8000",
    filial: "ATEM-MANAUS",
    truckDriver: "Ana Silva",
    salesOrderQuantity: "3",
    scheduleDate: addDays(new Date(), 1).toString(),
    freight: "FOB",
    salesOrder: [
      {
        customerID: "3",
        scheduleID: "2",
        customer: "Customer B",
        product: "Product C",
        quantity: "1000",
        order: "Order C",
        deliveryDate: "2024-05-11",
        schedulable: "false",
        status: "LIB",
      },
      {
        customerID: "4",
        scheduleID: "2",
        customer: "Customer B",
        product: "Product D",
        quantity: "4000",
        order: "Order D",
        deliveryDate: "2024-05-11",
        schedulable: "true",
        status: "LIB",
      },
      {
        customerID: "4",
        scheduleID: "2",
        customer: "Customer B",
        product: "Product D",
        quantity: "3000",
        order: "Order D",
        deliveryDate: "2024-05-11",
        schedulable: "true",
        status: "LIB",
      },
    ],
  },
  {
    scheduleGroupID: "003",
    totalAmountLiters: "1500",
    filial: "ATEM-MANAUS",
    truckDriver: "José Abreu da Silva",
    salesOrderQuantity: "1",
    scheduleDate: addDays(new Date(), 2).toString(),
    freight: "-",
    salesOrder: [
      {
        customerID: "3",
        scheduleID: "2",
        customer: "Posto Ponta Negra 2",
        product: "GASOLINA COMUM",
        quantity: "1000",
        order: "300102",
        deliveryDate: "2024-05-11",
        schedulable: "false",
        status: "LIB",
      },
    ],
  },
];

export const scheduleLoadMockResponse: IRoadLoadResponse = {
  total: 1,
  content: [
    {
      tableMetadata: {
        Ref: "0021",
        Id: "550e8400-e29b-41d4-a716-446655440000",
        Cliente: "Posto Ponta Negra Platinum",
        Produto: "Gasolina Aditivada",
        Quantidade: 10000,
        Data_solicitada_de_entrega: "2024-05-24",
        Data_de_entrega: "2024-05-24",
        Hora_de_entrega: "10:00:00",
        Filial: "Manaus",
        NomeFilial: "ATEM-MANAUS",
        Agendamento: "2024-05-23",
        CNPJ: "12345678901234",
        Motorista: "João Silva",
        Qtde_ordens_de_venda: 2,
        Data_e_hora_agendamento: "2024-05-23T21:25:00-04:00",
        Hora_agendendamento: "21:25:00",
        Data_agendamento: "2024-05-23",
        Timezone: "America/Manaus",
        Items: [
          {
            Cliente: "Posto Ponta Negra Platinum",
            CNPJ: "12345678901234",
            Produto: "Gasolina Aditivada",
            CodProduto: "GAS-ADI",
            Quantidade: 9000,
            Ordem_de_venda: "859381",
          },
          {
            Cliente: "Posto Manauara",
            CNPJ: "98765432109876",
            Produto: "Diesel S10",
            CodProduto: "DIES-S10",
            Quantidade: 5000,
            Ordem_de_venda: "859382",
          },
        ],
      },
      numberOrder: "859381",
      status: "PENDING",
      sortKey: 1,
      schedulable: "true",
    },
  ],
};

const invoicedLoadsMock: IRoadInvoicedLoad[] = [
  {
    customer: "Posto Ponta Negra",
    product: "Gasolina Extreme",
    quantity: "5000",
    filial: "ATEM-MANAUS",
    order: "85938X",

    invoiceDate: "2024-02-02T22:00", // Data de faturamento
    truckDriver: "José de Abreu da Silva",
    scheduleDate: "2024-02-02T22:00",
    status: "FT",
  },
  {
    customer: "Posto Ponta Negra",
    product: "Gasolina Aditivada",
    quantity: "4000",
    order: "85938X",

    filial: "ATEM-MANAUS",
    invoiceDate: "2024-02-03T22:00", // Data de faturamento
    truckDriver: "Ana da Silva",
    scheduleDate: "2024-02-02T22:00",
    status: "FT",
  },
];

const customerOptionsList = [
  {
    value: "1",
    label: "Posto Ponta negra",
  },
  {
    value: "2",
    label: "Posto X",
  },
  {
    value: "3",
    label: "Posto Y",
  },
];

const productOptionsList = [
  {
    value: "1",
    label: "Gasolina",
  },
  {
    value: "2",
    label: "Etanol",
  },
  {
    value: "3",
    label: "Diesel",
  },
];

const filiaisOptionsList = [
  {
    value: "1",
    label: "Filial X",
  },
  {
    value: "2",
    label: "Filial Y",
  },
  {
    value: "3",
    label: "Filial Z",
  },
];

const roadCreatedMock: ICreateScheduleReponse = {
  scheduleResolvedDateTime: "2024-09-07T21:00:00.000Z",
  id: "d6c7d762-11ef-45ca-90a6-a061ed74bbee",
  items: [
    {
      id: "09c54f11-e223-4537-b6f8-fc2cae34665d",
      status: "PENDING",
      customerRef: "31860017000142",
      volume: 1000,
      orderRef: "770849",
      productRef: "10122",
      scheduleId: "d6c7d762-11ef-45ca-90a6-a061ed74bbee",
      updatedAt: "2024-09-06T19:10:38.426Z",
      createdAt: "2024-09-06T19:10:38.426Z",
      metadata: null,
      deletedAt: null,
    },
  ],
  incotermsRef: "FOB",
  handlerRef: "be101f01-ccb1-489b-bfd2-1a8a37b36cbc",
  handlerMetadata: {
    userId: "026c3e74-4a34-4377-8c41-c3c530d1dc86",
    driverId: "be101f01-ccb1-489b-bfd2-1a8a37b36cbc",
    userName: "Von Regenwald",
    driverName: "Brendo Motorista",
  },
  vehicleRef: null,
  scheduleDate: "2024-09-07",
  scheduleStatus: "PENDING",
  scheduleTime: "17:00:00",
  subsidiaryRef: "C001",
  subsidiaryRuleId: "daf2d76d-9fca-46b7-884c-fd128d0e0f08",
  subsidiaryRulesOverridesIds: ["e38ffc9f-2911-479f-b26a-f31bfdbccb96"],
  type: "ROAD",
  vacancyStatus: "ACTIVE",
  scheduleTimezone: "America/Manaus",
  scheduleVolume: 1000,
  updatedAt: "2024-09-06T19:10:38.420Z",
  createdAt: "2024-09-06T19:10:38.420Z",
  ref: "421",
  startedAt: null,
  startedBy: null,
  finishedBy: null,
  finishedAt: null,
  cancellationMetadata: null,
  metadata: null,
  deletedAt: null,
};

export {
  customerOptionsList,
  filiaisOptionsList,
  invoicedLoadsMock,
  notLoadsMock,
  productOptionsList,
  roadCreatedMock,
};
