import React from "react";
import useStyles from "./styles";
import { Box, Grid, Typography } from "@material-ui/core";

const StatusIndicadores: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.container}>
      <Grid item className={classes.statusItem}>
        <Box className={classes.boxVazio}></Box>
        <Typography className={classes.caption}>Vazio</Typography>
      </Grid>
      <Grid item className={classes.statusItem}>
        <Box className={classes.boxCarregado}></Box>
        <Typography className={classes.caption}>Carregado</Typography>
      </Grid>
      <Grid item className={classes.statusItem}>
        <Box className={classes.boxOcupado}></Box>
        <Typography className={classes.caption}>Ocupado</Typography>
      </Grid>
    </Grid>
  );
};

export default StatusIndicadores;
