import React, { useState, useEffect } from "react";
import TruckSVG from "@components/TruckSVG";
import { Checkbox, Typography, useTheme } from "@material-ui/core";
import FloatingMenuList from "@components/FloatingMenuListMobile";
import { useTruckInteractiveStyles } from "./styles";
import { Grid, useMediaQuery } from "@mui/material";
import { ModalGeneral } from "@components/ModalGeneral/ModalGeneral";
import StatusIndicadores from "@components/StatusIndicadores";

interface Compartments {
  capacity: number;
  index: number;
  type: string;
  isChecked: boolean;
}

interface TruckInteractiveProps {
  step: number;
  compartments: Compartments[];
  filledCompartments: number[];
  occupiedCompartments: number[];
  onFilledCompartmentsChange: (filled: number[]) => void;
  forceOpenModal?: boolean;
  onModalDidOpen?: () => void;
}

interface RenderCompartimentsProps {
  title?: string;
}

const TruckInteractive: React.FC<TruckInteractiveProps> = ({
  step,
  compartments,
  filledCompartments,
  occupiedCompartments,
  onFilledCompartmentsChange,
  forceOpenModal = false,
  onModalDidOpen,
}) => {
  const [selectedCompartment, setSelectedCompartment] = useState<number | null>(
    null
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const classes = useTruckInteractiveStyles();

  const allocatedColor =
    step === 2 ? theme.palette.primary.main : theme.palette.background.paper;
  const occupiedColor = theme.palette.primary.light;

  const availableCompartments = compartments.filter(
    (item) => !occupiedCompartments.includes(item.index)
  );

  const availableCapacity = `Livre: ${availableCompartments
    .reduce((acc, c) => acc + c.capacity, 0)
    .toLocaleString()} litros`;

  const capacityText = `Capacidade: ${compartments
    .reduce((acc, c) => acc + c.capacity, 0)
    .toLocaleString()} litros`;

  const handleClickCompartment = (index: number) => {
    setSelectedCompartment(index);
  };

  const handleCloseMenuModal = () => setSelectedCompartment(null);

  const handleToggleCompartment = (index: number) => {
    const newFilled = filledCompartments.includes(index)
      ? filledCompartments.filter((compId) => compId !== index)
      : [...filledCompartments, index];
    onFilledCompartmentsChange(newFilled);
  };

  const RenderCompartiments: React.FC<RenderCompartimentsProps> = ({
    title,
  }) => {
    return (
      <div className={classes.modalContainer}>
        {title && (
          <Typography variant="body1" className={classes.modalTitle}>
            {title}
          </Typography>
        )}

        <div className={classes.compartmentList}>
          {compartments.map((compartiment) => (
            <div key={compartiment.index} className={classes.compartmentItem}>
              <label>
                <Checkbox
                  checked={filledCompartments.includes(compartiment.index)}
                  onChange={() => handleToggleCompartment(compartiment.index)}
                  style={{ color: theme.palette.primary.main }}
                />
                {`Compartimento ${compartiment.index}`}
              </label>
              <span>{`${compartiment.capacity} L`}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (forceOpenModal) {
      setSelectedCompartment(0);
      if (onModalDidOpen) {
        onModalDidOpen();
      }
    }
  }, [forceOpenModal, onModalDidOpen]);

  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          height: "100%",
          minHeight: "35vh",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          borderRadius: "4px",
          backgroundColor: theme.palette.shadesOfDark.background,
        }}
      >
        <Typography
          style={{
            fontSize: "1.2rem",
            fontWeight: 700,
            marginBottom: "4rem",
            color: theme.palette.text.primary,
          }}
        >
          Compartimentos ocupados
        </Typography>
        <Grid container sx={{ marginBottom: "2rem", justifyContent: "center" }}>
          <TruckSVG
            onClickCompartment={handleClickCompartment}
            filledCompartments={step === 2 ? filledCompartments : []}
            occupiedCompartments={occupiedCompartments}
            allocatedColor={allocatedColor}
            occupiedColor={occupiedColor}
            compartmentQuantity={compartments.length}
          />
        </Grid>
        <Grid
          container
          spacing={1}
          sx={{ marginBottom: "2rem", justifyContent: "center" }}
        >
          <Typography
            style={{
              fontSize: "1.2rem",
              fontWeight: 500,
              marginRight: "1rem",
              color: theme.palette.text.primary,
            }}
          >
            {capacityText}
          </Typography>
          <Typography
            style={{
              fontSize: "1.2rem",
              fontWeight: 500,
              marginRight: "1rem",
              color: theme.palette.text.primary,
            }}
          >
            |
          </Typography>
          <Typography
            style={{
              fontSize: "1.2rem",
              fontWeight: 500,
              color: theme.palette.text.primary,
            }}
          >
            {availableCapacity}
          </Typography>
        </Grid>

        <StatusIndicadores />
      </Grid>

      {selectedCompartment !== null && (
        <div>
          {isMobile ? (
            <FloatingMenuList handleClose={handleCloseMenuModal}>
              <RenderCompartiments
                title={"Selecione o compartimento que está ocupado"}
              />
            </FloatingMenuList>
          ) : (
            <ModalGeneral
              open={selectedCompartment !== null}
              closeModal={handleCloseMenuModal}
              titleHeader="Selecione o compartimento que está ocupado"
              width={"500px"}
              height={"400px"}
              hasDialogActions={false}
              resetPadding={true}
            >
              <RenderCompartiments />
            </ModalGeneral>
          )}
        </div>
      )}
    </>
  );
};

export default TruckInteractive;
