import React, { useEffect, useMemo, useState, useCallback } from "react";
import {
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Box,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import debounce from "lodash/debounce";
import { FormikProps } from "formik";
import ScheduleInfo from "@components/ScheduleInfo";
import TruckInteractive from "@components/TruckInteractive";
import FloatingMenuFixedMobile from "@components/FloatingMenuFixedMobile";
import { useProgrammingTransport } from "@context/ProgrammingTransport/ProgrammingTransportContext";
import { formatDateAndHourPTBR } from "@utils/index";
import { useHistory } from "react-router-dom";
import ROUTES from "@config/routes";
import { FormHelperText, useMediaQuery } from "@mui/material";
import GroupMenuBackAndNext from "@components/GroupMenuBackAndNext";

export interface Compartment {
  capacity: number;
  index: number;
  type: string;
  isChecked: boolean;
}

interface StepOneFormValues {
  truckPlate: string;
  truckCompartmentOccupied: boolean;
}

interface InnerFormProps {
  formikProps: FormikProps<StepOneFormValues>;
  occupiedCompartments: Compartment[];
  setOccupiedCompartments: React.Dispatch<React.SetStateAction<Compartment[]>>;
}

const InnerForm: React.FC<InnerFormProps> = ({
  formikProps,
  occupiedCompartments,
  setOccupiedCompartments,
}) => {
  const { values, setFieldValue, handleBlur } = formikProps;
  const [plates, setPlates] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [forceOpenModal, setForceOpenModal] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const history = useHistory();
  const {
    getVehicle,
    compartments,
    setCompartments,
    rowSelected,
    setCreateTransportBody,
    resetContext,
  } = useProgrammingTransport();

  const notRow = !rowSelected;

  const normalizePlate = useCallback(
    (plate: string) => plate.replace(/-/g, ""),
    []
  );

  const fetchPlates = useCallback(
    async (input: string) => {
      setLoading(true);
      try {
        const response = await getVehicle(
          rowSelected?.scheduleGroupID ?? "",
          normalizePlate(input)
        );
        const fetchedPlates = response.map((vehicle) => vehicle.plate);
        setPlates(fetchedPlates);
      } catch (error) {
        throw error;
      }
      setLoading(false);
    },
    [getVehicle, rowSelected?.scheduleGroupID, normalizePlate]
  );

  const fetchVehicleData = useCallback(
    async (plate: string) => {
      if (!plate) return;
      try {
        const response = await getVehicle(
          rowSelected?.scheduleGroupID ?? "",
          normalizePlate(plate)
        );
        const vehicleData = response.find(
          (vehicle) => normalizePlate(vehicle.plate) === normalizePlate(plate)
        );

        if (vehicleData?.compartments?.length) {
          const fetchedCompartments = vehicleData.compartments.map((comp) => ({
            ...comp,
            isChecked: false,
          }));
          setCompartments(fetchedCompartments);
        } else {
          setCompartments([]);
        }
      } catch (error) {
        setCompartments([]);
        throw error;
      }
    },
    [getVehicle, rowSelected?.scheduleGroupID, normalizePlate, setCompartments]
  );

  const debounceFetchPlates = useMemo(
    () => debounce((input: string) => fetchPlates(input), 500),
    [fetchPlates]
  );

  const handleTruckPlateChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: string | null) => {
      setFieldValue("truckPlate", newValue || "");

      if (newValue) {
        const normalizedValue = normalizePlate(newValue);
        fetchVehicleData(normalizedValue);
        setCreateTransportBody((prev) => ({
          ...prev,
          plate: normalizedValue,
        }));
      }
    },
    [normalizePlate, fetchVehicleData, setFieldValue, setCreateTransportBody]
  );

  const handleInputPlateChange = useCallback(
    (event: React.ChangeEvent<{}>, newInputValue: string) => {
      if (newInputValue) {
        debounceFetchPlates(newInputValue);
      }
    },
    [debounceFetchPlates]
  );

  useEffect(() => {
    return () => {
      debounceFetchPlates.cancel();
    };
  }, [debounceFetchPlates]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setFieldValue("truckCompartmentOccupied", isChecked);
    if (!isChecked) {
      setOccupiedCompartments([]);
      setForceOpenModal(false);
    } else {
      setForceOpenModal(true);
    }
  };

  const isAdvanceDisabled =
    !values.truckPlate ||
    (values.truckCompartmentOccupied && occupiedCompartments.length === 0);

  const handleCancel = () => {
    resetContext();
    history.push(ROUTES.USER_ROUTES.ROADLOAD);
  };

  return (
    <>
      <Grid container spacing={2}>
        {isMobile && (
          <Grid item xs={12}>
            <ScheduleInfo
              agendamento={rowSelected?.Ref || ""}
              dataAgendada={formatDateAndHourPTBR(
                rowSelected?.scheduleDate,
                rowSelected?.timezone
              )}
            />
          </Grid>
        )}

        <Grid item xs={12} md={12} lg={6}>
          <Grid item xs={12}>
            <Autocomplete
              disabled={notRow}
              options={plates}
              noOptionsText="Informe uma placa"
              loadingText="Carregando opções"
              getOptionLabel={(option) => option}
              value={values.truckPlate}
              onInputChange={handleInputPlateChange}
              onChange={handleTruckPlateChange}
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Placa do caminhão"
                  variant="outlined"
                  onBlur={handleBlur}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <span>Loading...</span> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
            {notRow && (
              <FormHelperText error sx={{ fontSize: "1.1rem" }}>
                Escolha um carregamento na tabela anterior para habilitar a
                seleção de placa.
              </FormHelperText>
            )}
          </Grid>

          {compartments.length > 0 && (
            <Grid item xs={12}>
              <FormControlLabel
                classes={{ label: classes.formControlLabel }}
                control={
                  <Checkbox
                    checked={values.truckCompartmentOccupied}
                    onChange={handleCheckboxChange}
                    style={{ color: theme.palette.primary.main }}
                  />
                }
                label="O caminhão possui compartimento ocupado."
              />
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} md={12} lg={6}>
          <Grid
            xs={12}
            style={{
              display: "flex",
              minHeight: "35vh",
              alignItems: "center",
              borderRadius: "4px",
              backgroundColor: theme.palette.shadesOfDark.background,
            }}
          >
            {values.truckCompartmentOccupied && compartments.length > 0 && (
              <Box display="block" width="100%" textAlign="center">
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "1rem",
                  }}
                >
                  <TruckInteractive
                    step={1}
                    compartments={compartments}
                    filledCompartments={occupiedCompartments.map(
                      (comp) => comp.index
                    )}
                    occupiedCompartments={occupiedCompartments.map(
                      (comp) => comp.index
                    )}
                    onFilledCompartmentsChange={(filled) => {
                      const updated = compartments.filter((comp) =>
                        filled.includes(comp.index)
                      );
                      setOccupiedCompartments(updated);
                    }}
                    forceOpenModal={forceOpenModal}
                    onModalDidOpen={() => setForceOpenModal(false)}
                  />
                </Grid>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>

      {isMobile ? (
        <FloatingMenuFixedMobile
          firstButtonDescription="Cancelar"
          secondButtonDescription="Avançar"
          disableSecondButton={isAdvanceDisabled}
          handleApprove={() => formikProps.handleSubmit()}
          handleClose={handleCancel}
        />
      ) : (
        <Grid container style={{ paddingTop: "30px" }}>
          <GroupMenuBackAndNext
            firstButtonDescription="Cancelar"
            secondButtonDescription="Avançar"
            disableSecondButton={isAdvanceDisabled}
            handleApprove={() => formikProps.handleSubmit()}
            handleClose={handleCancel}
          />
        </Grid>
      )}
    </>
  );
};

export default InnerForm;

const useStyles = makeStyles({
  formControlLabel: {
    fontSize: "10pt",
  },
});
