import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, Tooltip } from "@mui/material";
import AirportShuttleOutlinedIcon from "@mui/icons-material/AirportShuttleOutlined";

import SelectAutocomplete, { Option } from "@components/SelectAutocomplete";
import TextFieldCustom from "@components/TextFieldCustom";
import Modal from "@components/Modal";
import Button from "@components/Button";

import { TypographyCaption, TypographyTitle } from "../typography";
import { useRoadLoad } from "../../context/RoadLoadContext";
import { IScheduledLoad } from "../../model";

import {
  arrayToStringJoin,
  formatDateAndHourPTBR,
  maskVehiclePlateMercoSul,
  validatePlate,
} from "@utils/index";
import { KEY } from "@utils/enum";

import { IDrivers } from "@modules/roadLoad/model";
import { freighTypesEnum } from "@pages/User/MyOrders/models/ENUM_MY_ORDERS";

interface Props {
  children?: React.ReactNode;
  schedule?: IScheduledLoad;
  hiddenIcon?: boolean;
}

const ModalEditDriver: React.FC<Props> = ({ children, ...props }) => {
  const { schedule, hiddenIcon } = props;

  const {
    hasCarrier,
    onlyDriver,
    roadLoadFilterOptions,
    handleFetchDriverByCNPJ,
    handleRoadLoadMethod,
  } = useRoadLoad();

  const isCIFSelected =
    schedule?.salesOrder?.[0]?.freight === freighTypesEnum.CIF;

  //NOTE: Verifica se o frete é do tipo CIF e o usuário é do Tipo Transportadora
  const hasCarrierAndHasCIF = isCIFSelected && hasCarrier;

  const [loadEditDriver, setLoadEditDriver] = useState(false);

  const [selectedDrivers, setSelectedDrivers] = useState<Option | undefined>(
    undefined
  );
  const [selectedDriverName, setSelectedDriverName] = useState<string>("");
  const [selectedPlate, setSelectedPlate] = useState<string>("");
  const [error, setError] = useState("");

  const isValid = validatePlate(selectedPlate) && selectedDriverName;

  const handlePlateChange = (e) => {
    const plate = maskVehiclePlateMercoSul(e.target.value);
    setSelectedPlate(plate);

    const isValid = validatePlate(plate);
    setError(
      isValid
        ? ""
        : "Placa inválida. Use o formato correto (ABC-1234) ou (ABC-1D34)"
    );
  };

  const optionsDrivers = (drivers?: IDrivers[]) => {
    return Array.isArray(drivers)
      ? drivers?.map((driver, index) => {
          return {
            id: driver?.id ?? `${index}`,
            name: driver?.name ?? "Nao definido",
          };
        }) ?? []
      : [];
  };

  const handleOnClickEditDriver = async (selectedDrivers?: Option) => {
    if (!selectedDrivers) return;
    setLoadEditDriver(true);

    const orderId = arrayToStringJoin(schedule?.salesOrder, KEY.ORDER);

    const payloadFOB = { orderId, driverId: selectedDrivers.id };

    const payloadCIF = {
      orderId,
      driverName: selectedDriverName,
      plate: selectedPlate,
    };

    const payload = hasCarrierAndHasCIF ? payloadCIF : payloadFOB;

    await handleRoadLoadMethod.fetchCreateSchedule(
      payload,
      schedule?.scheduleGroupID
    );
    setLoadEditDriver(false);
  };

  useEffect(() => {
    setSelectedDriverName(schedule?.truckDriver ?? "");
    setSelectedPlate(schedule?.plate ?? "");
  }, [schedule?.plate, schedule?.truckDriver]);

  return (
    <Modal
      ModalButton={
        <Tooltip
          title={
            onlyDriver ? (
              <TypographyCaption sx={{ fontSize: 10 }}>
                {" "}
                Voce nao possui permissao para alterar motorista
              </TypographyCaption>
            ) : (
              <TypographyCaption sx={{ fontSize: 10 }}>
                {" "}
                Editar Motorista{" "}
              </TypographyCaption>
            )
          }
        >
          <Grid component={"span"}>
            <IconButton
              disabled={onlyDriver}
              sx={{
                p: { xs: "4px", md: "8px" },
                gap: 1,
                borderRadius: "0",
              }}
              onClick={(event) => {
                event.preventDefault();
                handleFetchDriverByCNPJ(
                  arrayToStringJoin(schedule?.salesOrder, KEY.CNPJ)
                );
                setSelectedDrivers({
                  id: schedule?.truckDriverId ?? "0",
                  name: schedule?.truckDriver ?? "Nao definido",
                });
              }}
            >
              {children}
              <AirportShuttleOutlinedIcon
                color="primary"
                sx={{
                  width: { lg: "22px" },
                  height: { lg: "22px" },
                  display: hiddenIcon ? "none" : "block",
                }}
              />{" "}
            </IconButton>
          </Grid>
        </Tooltip>
      }
    >
      {({ handleClose }) => {
        return (
          <Box>
            <Grid container gap={5} justifyContent={"center"}>
              <Grid item xs={12}>
                <TypographyTitle>Alterar Motorista</TypographyTitle>
              </Grid>

              <Grid item xs={12}>
                <Grid container gap={3}>
                  {!hasCarrierAndHasCIF && (
                    <Grid item xs={12}>
                      <SelectAutocomplete
                        label="Motorista"
                        value={selectedDrivers}
                        options={optionsDrivers(roadLoadFilterOptions?.drivers)}
                        getOptionLabel={(option) => option.name}
                        onChange={(value) => {
                          value !== null &&
                            !Array.isArray(value) &&
                            setSelectedDrivers(value);
                        }}
                      />
                    </Grid>
                  )}

                  {hasCarrierAndHasCIF && (
                    <Grid container spacing="2" xs={12}>
                      <Grid item xs={12} md={6}>
                        <TextFieldCustom
                          name={"driver"}
                          type={"text"}
                          variant={"outlined"}
                          label={"Motorista"}
                          onChange={(e) => {
                            setSelectedDriverName(e.target.value);
                          }}
                          value={selectedDriverName}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextFieldCustom
                          name={"plate"}
                          type={"text"}
                          variant={"outlined"}
                          label={"Placa"}
                          onChange={(e) => {
                            handlePlateChange(e);
                          }}
                          inputProps={{ maxLength: 8 }}
                          value={selectedPlate}
                          error={!!error}
                          helperText={error}
                        />
                      </Grid>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <TextFieldCustom
                      name={"Ref"}
                      type={"text"}
                      variant={"outlined"}
                      label={"Numero do Agendamento"}
                      value={schedule?.Ref ?? "Nao definido"}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldCustom
                      name={"scheduleDate"}
                      type={"text"}
                      variant={"outlined"}
                      label={"Data de Entrega"}
                      value={
                        formatDateAndHourPTBR(
                          schedule?.scheduleDate,
                          schedule?.timezone
                        ) ?? "Nao definido"
                      }
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldCustom
                      name={"filial"}
                      type={"text"}
                      variant={"outlined"}
                      label={"Nome da Filial"}
                      value={`${
                        schedule
                          ? `${schedule.filialName}-${schedule.filial}`
                          : '"Nao definido'
                      }`}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldCustom
                      name={"filial"}
                      type={"text"}
                      variant={"outlined"}
                      label={"Tipo do Frete"}
                      value={
                        schedule?.salesOrder?.[0]?.freight || "Não definido"
                      }
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container justifyContent={"right"} gap={3}>
                  <Grid item>
                    <Button
                      variant="outlined"
                      disabled={loadEditDriver}
                      onClick={() => handleClose()}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      disabled={!isValid}
                      loading={loadEditDriver}
                      onClick={async () => {
                        await handleOnClickEditDriver(selectedDrivers);
                        handleClose();
                      }}
                    >
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        );
      }}
    </Modal>
  );
};

export default ModalEditDriver;
