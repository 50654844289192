/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { v4 as uuidv4 } from "uuid";

import {
  IOrders,
  IPayment,
  IPaymentDays,
  IProductCard,
} from "@pages/User/NewRequest/Model";

import { IGetPriceProductsService } from "@modules/orders/models/IGetPriceProductsService";
import { IGetClientInfoService } from "@modules/orders/models/IGetClientInfoService";
import { IGetPriceProductDTO } from "@modules/orders/dtos/IGetPriceProductDTO";

import { OptionsSelectTextField } from "@components/TextFieldSelect";

import { useIoCContext } from "@context/IoCContext/IoCContext";
import { useUserState } from "@context/UserContext";

import { createOptionListPaymentDay, maskCNPJ } from "@utils/index";
import { IClient, IPaymentSchedule } from "@utils/interfaces";

import useDialogAlert from "@hooks/useDialogAlert";
import { Types } from "@ioc/types";
import AppError from "@utils/AppError";
import { IGetLastPaymentService } from "@modules/orders/models/IGetLastPaymentService";

interface FormContext {
  loading: boolean;
  loadingCreateOrder: boolean;
  dataCustomer: IClient | null;
  dataAllLastPayment: IPaymentSchedule | null;
  cnpjs: OptionsSelectTextField[];
  setLoading(loading: boolean): void;
  setLoadingCreateOrder(loading: boolean): void;
  setDataCustomer(data: IClient): void;
  selectedCNPJ: OptionsSelectTextField | null;
  setSelectedCNPJ(cnpj: OptionsSelectTextField | null): void;
  withDrawalBases: OptionsSelectTextField[];
  selectedWithDrawalBase: OptionsSelectTextField | null;
  setSelectedWithDrawalBase(
    withDrawalBases: OptionsSelectTextField | null
  ): void;
  filials: { filialID: string; branchName: string }[] | undefined;
  setSelectedFilial(
    filial: { filialIndex: number; filialID: string; branchName: string } | null
  ): void;
  selectedFilial: {
    filialIndex: number;
    filialID: string;
    branchName: string;
  } | null;
  productsOptions: OptionsSelectTextField[];
  setSelectedProduct(product: OptionsSelectTextField | null): void;
  selectedAmount: string;
  setSelectedAmount: React.Dispatch<React.SetStateAction<string>>;
  historyLastPaymentProduct: string | null;
  setHistoryLastPaymentProduct: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  loadingProductPrice: boolean;
  addressOptions: OptionsSelectTextField[] | null;
  setSelectedAddress(address: OptionsSelectTextField | null): void;
  selectedAddress: OptionsSelectTextField | null;
  productCart: IProductCard[];
  removeProductCart(product: IProductCard): void;
  updateAmountInProductCart(product: IProductCard, amount: string): void;
  updatePaymentInProductCart(
    product: IProductCard,
    payment: string,
    paymentDay: string
  ): void;
  updatePaymentDaysInProductCart(
    product: IProductCard,
    paymentDay: string
  ): void;
  loadingCNPJDetails: boolean;
  loadingMapProductsCartPrice: boolean[];
  loadingSubmitButton: boolean;
  setLoadingSubmitButton(loading: boolean): void;
  newOrders: IOrders[];
  setNewOrders(orders: IOrders[]): void;
  resetFormContext(): void;
  resetProductCartContext(): void;
  deliveryDate: Date | undefined;
  setDeliveryDate(date: Date | undefined): void;
}

const FormContext = createContext<FormContext>({} as FormContext);

const FormProvider: React.FC = ({ children }) => {
  const { snackbar } = useDialogAlert();
  const userState = useUserState();

  const cnpjList: OptionsSelectTextField[] = userState.listCNPJ.map((item) => {
    return {
      key: item.CNPJ,
      value: `${item.companyName} | ${maskCNPJ(item.CNPJ)}`,
    };
  });

  const [cnpjs] = useState<OptionsSelectTextField[]>(cnpjList);
  const [
    selectedCNPJ,
    setSelectedCNPJ,
  ] = useState<OptionsSelectTextField | null>(null);

  const [withDrawalBases, setWithDrawalBase] = useState<
    OptionsSelectTextField[]
  >([]);
  const [
    selectedWithDrawalBase,
    setSelectedWithDrawalBase,
  ] = useState<OptionsSelectTextField | null>(null);

  const [loading, setLoadingState] = useState(false);
  const [loadingCNPJDetails, setLoadingCNPJDetails] = useState(false);
  const [loadingProductPrice, setLoadingProductPrice] = useState(false);
  const [loadingCreateOrder, setLoadingCreateOrderState] = useState(false);
  const [loadingSubmitButton, setLoadingSubmitButton] = useState(false);

  const [dataCustomer, setDataCustomer] = useState<IClient | null>(null);
  const [
    dataAllLastPayment,
    setDataAllLastPayment,
  ] = useState<IPaymentSchedule | null>(null);

  const [historyLastPaymentProduct, setHistoryLastPaymentProduct] = useState<
    string | null
  >(null);
  const [deliveryDate, setDeliveryDate] = useState<Date>();

  const [addressOptions, setAddressOptions] = useState<
    OptionsSelectTextField[] | null
  >([]);
  const [
    selectedAddress,
    setSelectedAddress,
  ] = useState<OptionsSelectTextField | null>(addressOptions![0]);

  const [filials, setFilials] = useState<
    { filialIndex: number; filialID: string; branchName: string }[] | undefined
  >(undefined);
  const [selectedFilial, setSelectedFilial] = useState<{
    filialIndex: number;
    filialID: string;
    branchName: string;
  } | null>(null);

  const [productsOptions, setProductsOptions] = useState<
    OptionsSelectTextField[]
  >([]);
  const [
    selectedProduct,
    setSelectedProduct,
  ] = useState<OptionsSelectTextField | null>(null);

  const [selectedAmount, setSelectedAmount] = useState("0");

  {
    /* carrinho de produtos */
  }
  const [productCart, setProductCart] = useState<IProductCard[]>([]); // [] || productCartMock

  const [
    loadingMapProductsCartPrice,
    setLoadingMapProductsCartPrice,
  ] = useState<boolean[]>(productCart.map((produto) => produto.load || false));
  const [indexProductCartSelected, setIndexProductCartSelected] = useState<
    number
  >(-1);

  const [newOrders, setNewOrders] = useState<IOrders[]>([]); // [] ||  OrdersMock

  const setLoading = useCallback((loading: boolean) => {
    setLoadingState(loading);
  }, []);

  const setLoadingCreateOrder = useCallback((loading: boolean) => {
    setLoadingCreateOrderState(loading);
  }, []);

  const iocContext = useIoCContext();
  const getClientInfoService = iocContext.serviceContainer.get<
    IGetClientInfoService
  >(Types.Orders.IGetClientInfoService);
  const getPriceProductService = iocContext.serviceContainer.get<
    IGetPriceProductsService
  >(Types.Orders.IGetPriceProductsService);
  const getLastPaymentService = iocContext.serviceContainer.get<
    IGetLastPaymentService
  >(Types.Orders.IGetLastPaymentService);

  {
    /* BUSCA informacoes de detalhes do cliente com base no CNPJ selecionado */
  }
  useEffect(() => {
    const fetchAllInfoCNPJSelected = async () => {
      try {
        setLoadingCNPJDetails(true);
        if (selectedCNPJ === null || selectedCNPJ === undefined)
          throw new Error("Nenhum CNPJ selecionado");

        const CNPJData = await getClientInfoService.execute(selectedCNPJ.key);
        setDataCustomer(CNPJData);

        const withDrawalBasesOptions = CNPJData.withdrawBasis.map(
          (item, index) => {
            return {
              key: `${index}`,
              value: item.name,
            };
          }
        );

        setWithDrawalBase(withDrawalBasesOptions);

        const filialList = CNPJData?.withdrawBasis.map((item, index) => {
          return {
            filialIndex: index,
            filialID: item.filialID,
            branchName: item.branchName,
          };
        });

        setFilials(filialList);
        if (filialList.length <= 0)
          throw new Error("Nenhuma Filial encontrada");
        setSelectedFilial(filialList[0]); // Seleciona por padrao a primeira filial da lista

        const productsOptions = CNPJData?.products.map((item) => {
          return {
            key: item.id,
            value: item.description,
          };
        });

        setProductsOptions(productsOptions);

        const addressOptions = CNPJData?.address.map((item) => {
          return {
            key: item.id,
            value: item.street,
          };
        });

        setAddressOptions(addressOptions);
        setSelectedAddress(addressOptions[0]);
      } catch (error) {
        if (error instanceof AppError) {
          snackbar({
            message: `Ocorreu um erro ao recuperar dados do CNPJ selecionado - ${error.message}`,
            variant: "error",
          });
        }
      } finally {
        setLoadingCNPJDetails(false);
      }
    };

    fetchAllInfoCNPJSelected();
  }, [selectedCNPJ]);

  {
    /* NOTE: BUSCA informacoes do ultimo pagamento do ultimo pedido com base no CNPJ selecionado */
  }
  useEffect(() => {
    const fetchAInfoLastPayment = async () => {
      try {
        setLoadingCNPJDetails(true);
        if (selectedCNPJ === null || selectedCNPJ === undefined)
          throw new Error("Nenhum CNPJ selecionado");

        const TypePaymentData = await getLastPaymentService.execute(
          selectedCNPJ.key
        );

        setDataAllLastPayment(TypePaymentData);
      } catch (error) {
        if (error instanceof AppError) {
          snackbar({
            message: `Ocorreu um erro ao recuperar dados do CNPJ selecionado - ${error.message}`,
            variant: "error",
          });
        }
      } finally {
        setLoadingCNPJDetails(false);
      }
    };

    fetchAInfoLastPayment();
  }, [selectedCNPJ]);

  {
    /* BUSCA informacoes de detalhes do produto, com base no produto selecionado, Ex: Price */
  }
  useEffect(() => {
    const fetchInfoSelectedProductDetails = async () => {
      try {
        if (selectedProduct === null || selectedProduct === undefined) return;
        if (dataCustomer === null || dataCustomer === undefined) return;
        if (selectedFilial === null || selectedFilial === undefined) return;

        setLoadingProductPrice(true);

        const payMaxCond = dataCustomer?.payCond;

        const deadlineBasedProductsInCustomer =
          (await dataCustomer?.products.find(
            (product) => product.description === selectedProduct.value
          )?.payCond) ??
          payMaxCond ??
          0;

        const paymentByTransfer = 0;
        const paymentByOneDay = 1;

        const getInitialPaymentDay: () => string = () => {
          let initialPaymentDay: number;
          const isTransfer =
            deadlineBasedProductsInCustomer === paymentByTransfer;

          initialPaymentDay = isTransfer ? paymentByTransfer : paymentByOneDay;

          return initialPaymentDay.toString();
        };

        const filialID = dataCustomer?.withdrawBasis.find(
          (basis) => basis.branchName === selectedFilial?.branchName
        )?.filialID;

        if (deadlineBasedProductsInCustomer === undefined) return; // deadlinePayment pode ser 0
        if (!filialID) return;

        const historyOfLastPayment =
          dataAllLastPayment?.[selectedProduct?.key] ?? null;
        console.info("checking historyOfLastPayment... ", historyOfLastPayment);

        const payloadPriceDetailsProduct: IGetPriceProductDTO = {
          addressID: dataCustomer?.address[0].id,
          deadlinePayment:
            historyOfLastPayment?.deadlinePayment ?? getInitialPaymentDay(),
          productID: selectedProduct.key,
          customerID: dataCustomer?.id,
          filialID: filialID,
          transportationZone: dataCustomer?.address[0].transportationZone,
        };

        const priceProductDetails = await getPriceProductService.execute(
          payloadPriceDetailsProduct
        );

        const newProductCart: IProductCard = {
          code: selectedProduct?.key,
          name: selectedProduct?.value,
          payment:
            priceProductDetails?.payCond === IPaymentDays.CASH
              ? IPayment.TRANSFER
              : IPayment.TICKET,
          value: priceProductDetails?.price?.toString(),
          priceFreight: priceProductDetails?.freight?.toString(),
          paymentDays: payMaxCond.toString(),
          paymentDaysSelected:
            historyOfLastPayment?.deadlinePayment ??
            priceProductDetails?.payCond,
          paymentDayOptions: createOptionListPaymentDay(
            deadlineBasedProductsInCustomer
          ),
        };

        {
          /**** Adicionar produto no carrinho *****/
        }
        addProductCart(newProductCart);
      } catch (error) {
        if (error instanceof AppError) {
          snackbar({
            message: `Ocorreu um erro ao RECUPERAR dados do produto selecionado - ${error.message}`,
            variant: "error",
          });
        }
      } finally {
        setLoadingProductPrice(false);
        setSelectedProduct(null);
      }
    };

    fetchInfoSelectedProductDetails();
  }, [selectedProduct]);

  {
    /* ATUALIZA  informacoes do produto, apos usuario ja ter inserido no carrinho, Ex: Price */
  }

  const fetchUpdateInfoProductDetails = useCallback(async () => {
    try {
      setLoadingMapProductsCartPrice(
        productCart.map((produto, index) =>
          index === indexProductCartSelected ? true : false
        )
      );

      if (!dataCustomer) return;
      if (!selectedAddress) return;
      if (!selectedFilial) return;
      if (indexProductCartSelected === -1) return;

      const deadlinePayment =
        productCart[indexProductCartSelected].payment === IPayment.TRANSFER
          ? IPaymentDays.CASH
          : productCart[indexProductCartSelected].paymentDaysSelected;

      const addressSelected = dataCustomer.address.find(
        (address) => address.id === selectedAddress.key
      );

      if (!addressSelected) return;

      const payloadPriceDetailsProduct: IGetPriceProductDTO = {
        addressID: addressSelected.id,
        deadlinePayment: parseInt(deadlinePayment!),
        productID: productCart[indexProductCartSelected].code!,
        customerID: dataCustomer.id,
        filialID: selectedFilial.filialID,
        transportationZone: addressSelected.transportationZone,
      };

      const priceProductDetails = await getPriceProductService.execute(
        payloadPriceDetailsProduct
      );

      const updatedProductCart: IProductCard[] = productCart.map(
        (product, idx) =>
          idx === indexProductCartSelected
            ? {
                ...product,
                value: String(priceProductDetails?.price),
                paymentDaysSelected: priceProductDetails?.payCond,
                priceFreight: String(priceProductDetails?.freight),
              }
            : product
      );

      setProductCart(updatedProductCart);
    } catch (error) {
      if (error instanceof AppError) {
        snackbar({
          message: `Ocorreu um erro ao ATUALIZAR dados do produto selecionado, ${error.message}`,
          variant: "error",
        });
      }
    } finally {
      setLoadingMapProductsCartPrice(productCart.map(() => false));
      setIndexProductCartSelected(-1);
    }
  }, [setProductCart, productCart, indexProductCartSelected]);

  useEffect(() => {
    fetchUpdateInfoProductDetails();
  }, [indexProductCartSelected]);

  const addProductCart = (product: IProductCard) => {
    const addProduct = { ...product, id: uuidv4() };
    setProductCart([...productCart, addProduct]);
  };

  const removeProductCart = (productRemove: IProductCard) => {
    const updatedCart = productCart.filter(
      (product) => product.id !== productRemove.id
    );
    setProductCart(updatedCart);
  };

  const updateAmountInProductCart = (
    updateProductCart: IProductCard,
    newAmount: string
  ) => {
    try {
      const updatedCartAmount = productCart.map((product) => {
        if (product.id === updateProductCart.id) {
          // Se o ID do produto corresponder ao produto que queremos atualizar
          // Retornamos um novo objeto com a propriedade "amount" atualizada
          return { ...product, amount: newAmount };
        }
        // Se o ID do produto não corresponder, mantemos o produto inalterado
        return product;
      });
      setProductCart(updatedCartAmount);
    } catch (error) {
      snackbar({
        message:
          "Ocorreu um erro ao atualizar a quantidade do produto no carrinho de compras, tente novamente mais tarde",
        variant: "error",
      });
    }
  };

  {
    /* atualiza metodo de pagamento e dias de pagamento no carrinho de produtos */
  }
  const updatePaymentInProductCart = (
    productTobBeUpdated: IProductCard,
    newPayment: IPayment,
    newPaymentDay: string
  ) => {
    let indexProductCartUpdatedSelected = -1;

    const updatedProductCartPayment = productCart.map((product, index) => {
      if (product.id === productTobBeUpdated.id) {
        indexProductCartUpdatedSelected = index;
        return {
          ...product,
          payment: newPayment,
          paymentDaysSelected: newPaymentDay,
        };
      }
      return product;
    });

    setProductCart(updatedProductCartPayment);

    if (indexProductCartUpdatedSelected !== -1) {
      setIndexProductCartSelected(indexProductCartUpdatedSelected);
    }
  };

  {
    /* atualiza apenas o dias de pagamento no carrinho de produtos */
  }
  const updatePaymentDaysInProductCart = (
    productTobBeUpdated: IProductCard,
    newPaymentDay: string
  ) => {
    setProductCart((prevProductCart: IProductCard[]) => {
      let indexProductCartUpdatedSelected = -1;

      const updatedCartPaymentDays = prevProductCart.map((product, index) => {
        if (product.id === productTobBeUpdated.id) {
          indexProductCartUpdatedSelected = index;
          return { ...product, paymentDaysSelected: newPaymentDay };
        }
        return product;
      });

      setProductCart(updatedCartPaymentDays);

      if (indexProductCartUpdatedSelected !== -1) {
        setIndexProductCartSelected(indexProductCartUpdatedSelected);
      }

      return updatedCartPaymentDays;
    });
  };

  const resetFormContext = () => {
    setSelectedCNPJ(null);

    setAddressOptions([]);
    setSelectedAddress(null);

    setWithDrawalBase([]);
    setSelectedWithDrawalBase(null);

    setProductsOptions([]);
    setProductCart([]);
  };

  const resetProductCartContext = () => {
    setProductCart([]);
  };

  return (
    <FormContext.Provider
      value={{
        loading,
        dataCustomer,
        dataAllLastPayment,
        setLoading,
        setLoadingCreateOrder,
        loadingCreateOrder,
        setDataCustomer,
        cnpjs,
        selectedCNPJ,
        setSelectedCNPJ,
        withDrawalBases,
        selectedWithDrawalBase,
        setSelectedWithDrawalBase,
        filials,
        setSelectedFilial,
        selectedFilial,
        productsOptions,
        setSelectedProduct,
        selectedAmount,
        setSelectedAmount,
        setHistoryLastPaymentProduct,
        historyLastPaymentProduct,
        loadingProductPrice,
        addressOptions,
        setSelectedAddress,
        selectedAddress,
        productCart,
        removeProductCart,
        updateAmountInProductCart,
        updatePaymentInProductCart,
        updatePaymentDaysInProductCart,
        loadingCNPJDetails,
        loadingMapProductsCartPrice,
        setLoadingSubmitButton,
        loadingSubmitButton,
        newOrders,
        setNewOrders,
        resetFormContext,
        resetProductCartContext,
        deliveryDate,
        setDeliveryDate,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

const useForm = (): FormContext => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error("useForm deve ser utilizado dentro de um FormProvider");
  }
  return context;
};

export { useForm, FormProvider };
