import { makeStyles, Theme, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    card: {
      border: `1px solid ${theme.palette.shadesOfDark.ultraLight}`,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.shadesOfDark.white,
      padding: theme.spacing(1.5),
      cursor: "pointer",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    leftSide: {
      display: "flex",
      textAlign: "left",
      alignItems: "center",
    },
    primaryText: {
      fontWeight: 700,
      color: theme.palette.shadesOfDark.dark,
      fontSize: "1.3rem",
    },
    secondaryText: {
      fontSize: "1.2rem",
      color: theme.palette.shadesOfDark.medium,
      marginTop: theme.spacing(0.5),
    },
    compartmentsContainer: {
      display: "flex",
      gap: "0.3rem",
    },
    compartmentBadge: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.shadesOfDark.white,
      borderRadius: "0.25rem",
      padding: "0.4rem 0.5rem",
      fontSize: "1rem",
      fontWeight: 700,
      minWidth: "2.6rem",
      minHeight: "2.2rem",
      textAlign: "center",
    },
    compartmentOccupiedBadge: {
      backgroundColor: theme.palette.shadesOfDark.medium,
      color:theme.palette.shadesOfDark.white,
      borderRadius: "0.25rem",
      padding: "0.4rem 0.5rem",
      fontSize: "1rem",
      fontWeight: 700,
      minWidth: "2.6rem",
      minHeight: "2.2rem",
      textAlign: "center",
    },
    warningText: {
      fontWeight: 700,
      fontSize: "1.3rem",
      color: theme.palette.error.main,
      marginTop: theme.spacing(1),
      textAlign: "left",
    },
  })
);

export default useStyles;
