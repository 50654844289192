import React, { ReactElement, useState } from "react";
import { useTheme } from "@material-ui/core";

import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import ModalCancelScheduling from "../modal/ModalCancelScheduling";
import ModalSchedule from "../modal/ModalSchedule";

import { TypographyCaption } from "../typography";
import { IScheduledLoad } from "../../model";
import { KEY, STATUSROADLOAD, STATUSTYPE } from "@utils/enum";
import ModalEditDriver from "../modal/ModalEditDriver";
import { useFlags } from "flagsmith/react";

interface Props<T> {
  row: T;
  rowIndex: number;
  rescheduleAllowed: boolean;
  handleCancelScheduling: (scheduleGroupID: string) => Promise<void>;
  handleCancelCarCharging: (rowData: T) => Promise<void>;
  handleEditAndCreateCarCharging: () => void;
  cancelSchedulingAllowed: boolean;
  scheduleCarCharging: boolean;
  cancelCarCharging: boolean;
  editCarCharging: boolean;
  editDriverAllowed?: boolean;
  status: STATUSROADLOAD;
  hasProgramming?: boolean;
}

const LongMenu = <T,>(props: Props<T>): ReactElement => {
  const {
    row,
    rowIndex,
    rescheduleAllowed,
    handleCancelScheduling,
    handleCancelCarCharging,
    handleEditAndCreateCarCharging,
    scheduleCarCharging,
    editCarCharging,
    cancelCarCharging,
    cancelSchedulingAllowed,
    editDriverAllowed,
    hasProgramming,
  } = props;

  const theme = useTheme();

  const featureFlagProgrammingTransport = useFlags([
    "user_sidebar_programming_transport",
  ]).user_sidebar_programming_transport.enabled;

  const containsProgramming = featureFlagProgrammingTransport;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = [
    {
      allowed: rescheduleAllowed,
      onComponent: (
        <ModalSchedule
          key={rowIndex}
          roadLoadScheduled={row as IScheduledLoad}
          isDisable={!row?.[KEY.RESCHEDULABLE]}
          hiddenIcon
        >
          <TypographyCaption
            sx={{ color: theme.palette.text.primary, ml: "-8px" }}
          >
            Reagendar
          </TypographyCaption>
        </ModalSchedule>
      ),
    },
    {
      allowed: cancelSchedulingAllowed,
      onComponent: (
        <ModalCancelScheduling
          isDisabled={row[KEY.SALESORDER]?.some(
            (item) => item.status === STATUSTYPE.FATURADO
          )}
          scheduleID={row[KEY.REF]}
          handleCancelScheduling={() =>
            handleCancelScheduling(row[KEY.SCHEDULEGROUPID])
          }
          hiddenIcon
        >
          <TypographyCaption
            sx={{ color: theme.palette.text.primary, ml: "-2px" }}
          >
            Cancelar Agendamento
          </TypographyCaption>
        </ModalCancelScheduling>
      ),
    },
    {
      allowed: editDriverAllowed,
      onComponent: (
        <ModalEditDriver schedule={row as IScheduledLoad} hiddenIcon>
          <TypographyCaption
            sx={{ color: theme.palette.text.primary, ml: "0px" }}
          >
            Editar motorista
          </TypographyCaption>
        </ModalEditDriver>
      ),
    },
  ];

  if (containsProgramming) {
    if (!hasProgramming) {
      options.push({
        allowed: scheduleCarCharging,
        onComponent: (
          <span>
            <IconButton
              disabled={false}
              onClick={handleEditAndCreateCarCharging}
              sx={{
                opacity: false ? "0.4" : "1",
                p: { xs: "4px", md: "8px" },
                gap: 1,
                borderRadius: "0",
              }}
            >
              <TypographyCaption
                sx={{ color: theme.palette.text.primary, ml: "0px" }}
              >
                Programar Carregamento
              </TypographyCaption>
            </IconButton>
          </span>
        ),
      });
    }

    if (hasProgramming) {
      options.push(
        {
          allowed: editCarCharging,
          onComponent: (
            <span>
              <IconButton
                disabled={false}
                onClick={handleEditAndCreateCarCharging}
                sx={{
                  opacity: false ? "0.4" : "1",
                  p: { xs: "4px", md: "8px" },
                  gap: 1,
                  borderRadius: "0",
                }}
              >
                <TypographyCaption
                  sx={{ color: theme.palette.text.primary, ml: "0px" }}
                >
                  Editar Carregamento
                </TypographyCaption>
              </IconButton>
            </span>
          ),
        },
        {
          allowed: cancelCarCharging,
          onComponent: (
            <span>
              <IconButton
                disabled={false}
                onClick={() => {
                  handleCancelCarCharging(row);
                  handleClose();
                }}
                sx={{
                  opacity: false ? "0.4" : "1",
                  p: { xs: "4px", md: "8px" },
                  gap: 1,
                  borderRadius: "0",
                }}
              >
                <TypographyCaption
                  sx={{ color: theme.palette.text.primary, ml: "0px" }}
                >
                  Cancelar Programação
                </TypographyCaption>
              </IconButton>
            </span>
          ),
        }
      );
    }
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          color: theme.palette.primary.main,
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{ "aria-labelledby": "long-button" }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        sx={{
          "& .MuiMenu-list": {
            border: `1px solid ${theme.palette.background.default}`,
            borderRadius: 2,
          },
        }}
      >
        {options.map((option, index) => (
          <React.Fragment key={index}>
            <MenuItem
              disabled={!option.allowed}
              className={`menu-item-${index}`}
            >
              {option.onComponent}
            </MenuItem>
          </React.Fragment>
        ))}
      </Menu>
    </div>
  );
};

export default LongMenu;
