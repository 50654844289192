import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IProgrammingTransportService } from "../models/IProgrammingTransportService";
import { IGetVehicleResponse } from "../dtos/IGetVehicleResponse";
import { ICreateTransportBody } from "../models/ICreateTransportBody";

@injectable()
export class ProgrammingTransportService
  implements IProgrammingTransportService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async getVehicle(
    id: string,
    plate: string
  ): Promise<IGetVehicleResponse[]> {
    const url = `/schedules/${id}/transport/vehicles?query=${plate}`;
    return this.httpInstance.get<IGetVehicleResponse[]>(url);
  }

  public async createTransport(
    id: string,
    body: ICreateTransportBody
  ): Promise<void> {
    try {
      const url = `/schedules/${id}/transport`;
      await this.httpInstance.put<void>(url, body);
    } catch (err) {
      throw err;
    }
  }

  public async deleteTransport(id: string): Promise<void> {
    const url = `/schedules/${id}/transport`;
    await this.httpInstance.delete<void>(url);
  }

  public setAuthorization(token: string): void {
    this.httpInstance.setAuthorization(token);
  }
}
