import React from "react";
import { Formik, Form } from "formik";
import { useProgrammingTransport } from "@context/ProgrammingTransport/ProgrammingTransportContext";
import InnerForm from "./InnerForm";

interface SectionOneProps {
  onNext: () => void;
}

const ProgrammingSectionOne: React.FC<SectionOneProps> = ({ onNext }) => {
  const {
    createTransportBody,
    setCreateTransportBody,
    occupiedCompartments,
    setOccupiedCompartments,
  } = useProgrammingTransport();

  return (
    <Formik
      enableReinitialize
      initialValues={{
        truckPlate: createTransportBody.plate || "",
        truckCompartmentOccupied:
          createTransportBody.truckCompartmentOccupied || false,
      }}
      onSubmit={(values) => {
        // Grava no Context os valores do formulário
        setCreateTransportBody((prev) => ({
          ...prev,
          plate: values.truckPlate,
          truckCompartmentOccupied: values.truckCompartmentOccupied,
        }));

        // Avança para o Step 2
        onNext();
      }}
    >
      {(formikProps) => (
        <Form>
          {/* 
            InnerForm tem os campos (Autocomplete, checkbox, etc.),
            e lê/escreve nos valores de Formik via formikProps (useFormikContext).
          */}
          <InnerForm
            formikProps={formikProps}
            occupiedCompartments={occupiedCompartments}
            setOccupiedCompartments={setOccupiedCompartments}
          />
        </Form>
      )}
    </Formik>
  );
};

export default ProgrammingSectionOne;
