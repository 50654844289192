/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Collapse, useMediaQuery, useTheme } from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  Hidden,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";

import { ProductCartIcon, ProductCartRedIcon } from "@components/Icons";
import TextFieldCheckBox from "@components/TextFieldCheckBox";
import TexFieldLoose from "@components/TextFieldLoose";
import TextFieldSelect, {
  OptionsSelectTextField,
} from "@components/TextFieldSelect";

import ProductsCards from "@pages/User/NewRequest/resources/card/ProductCard";
import { useForm } from "@pages/User/NewRequest/resources/context/FormContext";
import {
  FormikInitialValuesNewRequest,
  FormikName,
} from "@pages/User/NewRequest/resources/form/IForm";

import {
  calculateMinDate,
  convertDateInFormatZ,
  formatAllFistLetterCapitalized,
  formatCurrencyPriceWithTwoDigit,
  formatDateWithSlash,
  invalidCNPJ,
  isNotEmpty,
  unmaskCNPJ,
} from "@utils/index";

import BreadcrumbCustom, {
  BreadcrumbChild,
} from "@components/BreadcrumbCustom";
import CalendarSpecific from "@components/CalendarSpecific";
import ROUTES from "@config/routes";
import useDialogAlert from "@hooks/useDialogAlert";
import { LocalStorageKeysOrders } from "@pages/User/MyOrders/utilsOrders/localStorageOrders";
import { Add, Remove } from "@mui/icons-material";

import { styled } from "@mui/system";
import ModalCancel from "../../../../../components/ModalCancel/ModalCancel";
import ModalShowDetails from "@components/ModalShowDetails/ModalShowDetails";
import TablePageNewRequest from "../table/TablePage";
import TittleAndSubTitle from "@components/TittleAndSubTitle/TitleAndSubTitle";
import FormLayoutMobile from "./FormLayoutMobile";
import { IPaymentDetails } from "@utils/interfaces";
import { FILIAL_DEFAULT_C001 } from "@utils/filialDefault";
import { DeliveryType } from "@utils/DeliveryType";

const StyledIconButton = styled(IconButton)({
  "& svg": {
    color: "red",
  },
});

interface FormLayoutProps {
  children?: React.ReactNode;
}

const FormLayout: React.FC<FormLayoutProps> = ({ children, ...props }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const [expandedPurchaseData, setExpandedPurchaseData] = useState(true);
  const [expandedObservation, setExpandedObservation] = useState(true);

  const buttonExpandedPurchaseData = () => {
    setExpandedPurchaseData((prevExpanded) => !prevExpanded);
  };

  const buttonExpandedObservation = () => {
    setExpandedObservation((prevExpanded) => !prevExpanded);
  };

  const breadcrumbDataNewOrder: BreadcrumbChild[] = [
    {
      link: ROUTES.USER_ROUTES.HOME,
      label: "Início",
    },
    {
      label: "Novo Pedido",
    },
  ];

  const { values } = useFormikContext<FormikInitialValuesNewRequest>();
  const isSmallScreen = useMediaQuery("(max-width:1100px)");

  const { dataCustomer } = useForm();
  const controlLockProductWithHiddenPrice = dataCustomer?.hiddePrice;

  return (
    <Grid
      container
      className="formlayout-container"
      sx={{
        border: "0px solid red",
        margin: { xs: 0, lg: 4 },
        padding: { xs: "8px 8px", lg: 2 },
        justifyContent: { xs: "center", lg: "space-between" },
        textAlign: "center",
      }}
    >
      <Grid
        item
        xs={12}
        style={{ padding: 8, marginLeft: isMobile ? "-22px" : "7px" }}
      >
        <BreadcrumbCustom data={breadcrumbDataNewOrder} />
      </Grid>
      <Grid item className="FormLayout-item-titulo" xs={12}>
        <Typography
          style={{
            color: "#3E3D3D",
            fontWeight: "bold",
            fontFamily: "Montserrat",
            fontSize: isMobile ? "2.0rem" : "3.0rem",
            paddingBottom: "20px",
            textAlign: "left",
            paddingLeft: isMobile ? "5px" : "20px",
          }}
        >
          Novo Pedido
        </Typography>
      </Grid>

      <Grid
        item
        className="FormLayout-item-body"
        xs={12}
        sx={{ border: "0px solid red", padding: { xs: "4px 4px", lg: 2 } }}
      >
        {/* MOBILE - VERSION */}
        <Hidden mdUp>
          <FormLayoutMobile />
        </Hidden>

        {/* DESKTOP - VERSION */}
        <Hidden mdDown>
          <Grid
            container
            className="FormLayout-item-body-container"
            justifyContent={"center"}
            gap={1}
            sx={{ border: "0px solid red" }}
          >
            {/* BLOCO 1 -  DADOS DA COMPRA */}
            <Grid
              className="grid-item-dados-da-compras"
              item
              xs={12}
              sx={{ p: 2, border: "0px solid blue" }}
            >
              <CardLayer>
                {/* TITULO */}
                <Grid item xs={12}>
                  <Grid container alignItems={"center"}>
                    <Grid item xs={3}>
                      <Grid
                        container
                        justifyContent={"flex-start"}
                        alignContent={"baseline"}
                      >
                        <SubTitle subtitle={"Dados da compra"} />
                      </Grid>
                    </Grid>

                    {/* RESUMO */}
                    <Grid item xs={8}>
                      <Grid
                        container
                        justifyContent={"flex-start"}
                        alignContent={"baseline"}
                      >
                        {!expandedPurchaseData && (
                          <>
                            <TittleAndSubTitle
                              inLine={true}
                              title="Razão Social:"
                              subTitle={values.companyName}
                              paddingRight={"20px"}
                            />
                            <TittleAndSubTitle
                              inLine={true}
                              title="Endereço de entrega:"
                              subTitle={values.deliveryAddress}
                              paddingRight={"20px"}
                            />
                            <TittleAndSubTitle
                              inLine={true}
                              title="Data de entrega:"
                              subTitle={formatDateWithSlash(
                                values.dateDelivery
                              )}
                            />
                          </>
                        )}
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={1}
                      textAlign={"right"}
                      alignContent={"center"}
                    >
                      <StyledIconButton onClick={buttonExpandedPurchaseData}>
                        {expandedPurchaseData ? <Remove /> : <Add />}
                      </StyledIconButton>
                    </Grid>
                  </Grid>
                </Grid>
                {/* CONTEÚDO Collapse */}
                <Collapse in={expandedPurchaseData}>
                  <Grid container justifyContent={"space-between"} gap={4}>
                    {/* CAMPOS - DADOS DE COMPRAS */}
                    <Grid item xs={12} sx={{ pt: 4, border: "0px solid red" }}>
                      <GroupedPurchaseData />
                    </Grid>

                    {/* TITULO - DADOS DO RESPONSAVEL */}
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12} md={6}>
                          <SubTitle subtitle={"Dados do responsável"} />
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* CAMPOS - DADOS DO RESPONSAVEL */}
                    <Grid item xs={12}>
                      <GroupedResponsibleData />
                    </Grid>
                  </Grid>
                </Collapse>
              </CardLayer>
            </Grid>

            {/* BLOCO 2 - DADOS DE ADICIONAR OBSERVACAO */}

            <Grid item xs={12} sx={{ p: 2, border: "0px solid blue" }}>
              <CardLayer>
                <Grid container>
                  <Grid item xs={11}>
                    <Grid
                      container
                      justifyContent={"flex-start"}
                      alignContent={"baseline"}
                    >
                      <SubTitle subtitle={"Adicionar observação"} />
                      {!expandedObservation && (
                        <Description
                          text={values.observation}
                          justifyContentText="flex-start"
                          maxChars={isSmallScreen ? 40 : 60}
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={1} textAlign={"right"} alignContent={"center"}>
                    <StyledIconButton onClick={buttonExpandedObservation}>
                      {expandedObservation ? <Remove /> : <Add />}
                    </StyledIconButton>
                  </Grid>
                </Grid>

                {/* CONTEÚDO Collapse */}
                <Collapse in={expandedObservation}>
                  <Grid item xs={12} sx={{ height: "100%", pt: 2 }}>
                    <GroupedObservationData />
                  </Grid>
                </Collapse>
              </CardLayer>
            </Grid>
            {/*  BLOCO 3 - DADOS DE PRODUTOS  */}
            <Grid
              item
              xs={12}
              sx={{
                p: 2,
                border: "0px solid blue",
                opacity: controlLockProductWithHiddenPrice ? 1 : 0.4,
                pointerEvents: controlLockProductWithHiddenPrice
                  ? "auto"
                  : "none",
              }}
            >
              <CardLayer>
                <Grid
                  container
                  justifyContent={"space-between"}
                  alignContent={"baseline"}
                  sx={{
                    border: "0px solid red",
                    height: { md: "auto", lg: "100%" },
                  }}
                  gap={4}
                >
                  <Grid item xs={12} sx={{ height: "fit-content" }}>
                    <Grid container>
                      <Grid item xs={6}>
                        <SubTitle subtitle={"Produtos"} />
                      </Grid>
                      <Grid item xs={6} textAlign={"right"}>
                        <ProductCartIcon />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sx={{ height: { md: "auto", lg: "100%" } }}
                  >
                    <GroupedProductData />
                  </Grid>
                </Grid>
              </CardLayer>
            </Grid>

            {/*  BLOCO 4 - DADOS TOTAIS DE DE QUANTIDADE DE LITRO E PRECO TOTAL DO PEDIDO */}
            <Grid item xs={12} sx={{ p: 2, border: "0px solid blue" }}>
              <CardLayer>
                <Grid
                  container
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  alignContent={"baseline"}
                  sx={{ height: "100%" }}
                >
                  <Grid item xs={12} sx={{ height: "fit-content" }}>
                    <Grid container alignItems={"center"}>
                      <Grid item xs={12} md={12} lg={1}>
                        <SubTitle subtitle={"Total"} />
                      </Grid>
                      <Grid item xs={12} md={12} lg={11}>
                        <GroupedTotalData />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardLayer>
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
    </Grid>
  );
};

export default FormLayout;

export const SubTitle: React.FC<{ subtitle: string }> = ({ subtitle }) => {
  return (
    <Typography
      sx={{
        textAlign: "left",
        fontFamily: "Montserrat",
        padding: 1,
        fontSize: {
          xs: "1.4rem",
          sm: "1.5rem",
          md: "1.6rem",
          lg: "1.8rem",
          xl: "2.2rem",
        },
        fontWeight: 700,
        color: "#3E3D3D",
      }}
    >
      {subtitle}
    </Typography>
  );
};

export const Description: React.FC<{
  text: string;
  justifyContentText?: string;
  maxChars?: number;
}> = ({ text, justifyContentText, maxChars }) => {
  let truncatedText = "";

  if (maxChars) {
    truncatedText =
      text.length > maxChars ? text.slice(0, maxChars) + "..." : text;
  }

  return (
    <Typography
      sx={{
        fontFamily: "Montserrat",
        padding: 1,
        fontSize: { xs: "1.1rem", md: "1.5rem" },
        fontWeight: 500,
        color: "#3E3D3D",
        display: "flex",
        alignItems: "center",
        justifyContent: justifyContentText ? justifyContentText : "center",
      }}
    >
      {maxChars ? truncatedText : text}
    </Typography>
  );
};

export const CardLayer: React.FC = ({ children }) => {
  return (
    <Grid container id={"cardLayer-container"} sx={{ height: "100%" }}>
      <Grid
        id={"cardLayer-item"}
        item
        xs={12}
        sx={{
          boxShadow: "0px 4px 4px 0px rgb(115, 97, 97,0.1)",
          backgroundColor: "#FFFFFF",
          width: "100%",
          borderRadius: 2,
          padding: { xs: "16px", lg: "16px" },
        }}
      >
        {" "}
        {children}{" "}
      </Grid>
    </Grid>
  );
};

export const GroupedPurchaseData: React.FC = () => {
  const [isCDM, setIsCDM] = useState<boolean>(false);
  const { snackbar } = useDialogAlert();

  const {
    loading,
    dataCustomer,
    cnpjs,
    selectedCNPJ,
    setSelectedCNPJ,
    withDrawalBases,
    selectedFilial,
    filials,
    setSelectedFilial,
    addressOptions,
    setSelectedAddress,
    selectedAddress,
    loadingCNPJDetails,
    setSelectedWithDrawalBase,
    productCart,
    resetProductCartContext,
  } = useForm();
  const resetAllFields = () => {
    setFieldValue(FormikName.COMPANY, "");
    setFieldValue(FormikName.DELIVERY, "");
    setFieldValue(FormikName.CEP, "");
    setFieldValue(FormikName.CITY, "");
    setFieldValue(FormikName.DISTRICT, "");
    setFieldValue(FormikName.FREIGHT, "");

    setFieldValue(FormikName.WITHDRAWALBASE, "");
    setFieldValue(FormikName.SUBSIDIARY, "");

    setFieldValue(FormikName.DATEDELIVERY, "");
    setFieldValue(FormikName.ADVISOR, "");
    setFieldValue(FormikName.OBSERVATION, "");
    setFieldValue(FormikName.COMMENTS_IN_INVOICE, false);
  };

  const verifiedAdressValue = (str: string, addressUF?: string) => {
    if (!dataCustomer) return;
    if (dataCustomer.address.length > 0) {
      if (addressUF) {
        return `${dataCustomer.address[0][str]} - ${dataCustomer.address[0][addressUF]}`;
      }
      return dataCustomer.address[0][str];
    }
    return "Não Informado";
  };

  const verifiedWithdrawBasisValue = (str: string) => {
    if (!dataCustomer) return;
    if (dataCustomer.withdrawBasis.length > 0) {
      return dataCustomer.withdrawBasis[0][str];
    }
    return "Não Informado";
  };

  {
    /* Atualiza todos os campos dinamicamente apos alteracao do CNPJ Selecionado */
  }
  useEffect(() => {
    const updateFieldsAftersReloadDataCustomer = () => {
      if (!selectedCNPJ) return;
      if (!dataCustomer) return;

      setFieldValue(FormikName.DELIVERY, verifiedAdressValue("street"));
      setFieldValue(FormikName.CITY, verifiedAdressValue("city", "UF"));
      setFieldValue(FormikName.DISTRICT, verifiedAdressValue("district"));
      setFieldValue(FormikName.CEP, verifiedAdressValue("postalCode"));

      setFieldValue(
        FormikName.FREIGHT,
        dataCustomer.freightType || "Não Informado"
      );

      setFieldValue(
        FormikName.EMAIL,
        dataCustomer.address[0].email || "Não Informado"
      );

      setFieldValue(
        FormikName.TELEPHONE,
        dataCustomer.address[0].phone || "Não Informado"
      );

      setFieldValue(
        FormikName.ADVISOR,
        dataCustomer.accessor?.name || "Não Informado"
      );

      setFieldValue(
        FormikName.WITHDRAWALBASE,
        verifiedWithdrawBasisValue("name")
      );

      setFieldValue(
        FormikName.DATEDELIVERY,
        calculateMinDate(dataCustomer.freightType.toString() || "CIF")
      );

      setFieldValue(
        FormikName.SUBSIDIARY,
        dataCustomer?.withdrawBasis.length > 0
          ? dataCustomer.withdrawBasis[0].branchName
          : "Não Informado"
      );
    };

    updateFieldsAftersReloadDataCustomer();
  }, [dataCustomer, selectedCNPJ]); // dataCustomer

  {
    /* Atualiza todos os campos dinamicamente apos alteracao do endereco */
  }
  useEffect(() => {
    const updateFieldsAftersReloadDelivery = () => {
      if (!selectedAddress) return;
      if (!dataCustomer) return;

      if (
        addressOptions === undefined ||
        addressOptions === null ||
        addressOptions.length === 0
      )
        return;

      const indexSelectedAddress = dataCustomer.address.findIndex(
        (address) => address.street === selectedAddress.value
      );

      if (indexSelectedAddress !== -1) {
        setFieldValue(
          FormikName.CITY,
          `${dataCustomer?.address[indexSelectedAddress].city} - ${dataCustomer?.address[indexSelectedAddress].UF}`
        );
        setFieldValue(
          FormikName.DISTRICT,
          dataCustomer?.address[indexSelectedAddress].district
        );
        setFieldValue(
          FormikName.CEP,
          dataCustomer?.address[indexSelectedAddress].postalCode
        );
        setFieldValue(FormikName.FREIGHT, dataCustomer?.freightType);

        setFieldValue(
          FormikName.DATEDELIVERY,
          calculateMinDate(dataCustomer.freightType.toString() || "CIF")
        );

        setFieldValue(FormikName.SUBSIDIARY, selectedFilial?.branchName);
      }
    };
    updateFieldsAftersReloadDelivery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAddress]);

  useEffect(() => {
    const cleanAllFields = () => {
      if (selectedCNPJ === null) {
        resetAllFields();
      }
    };
    cleanAllFields();
  }, [selectedCNPJ]);

  const {
    errors,
    setFieldValue,
    values,
    touched,
    handleBlur,
  } = useFormikContext<FormikInitialValuesNewRequest>();

  useEffect(() => {
    if (values.dateDelivery) {
      localStorage.setItem(
        LocalStorageKeysOrders.DateDelivery,
        convertDateInFormatZ(values.dateDelivery + "")
      );
    }
  }, [values.dateDelivery]);

  const extractCNPJ = (value: string): string => {
    return value.match(/\|\s*(.*)/)?.[1] || "";
  };

  const isInvalidCNPJ = (cnpj: string): boolean => {
    return cnpj === invalidCNPJ;
  };

  const findCNPJ = (
    cnpj: string,
    cnpjs: Array<{ key: string; value: string }>
  ) => {
    const unmaskedCNPJ = unmaskCNPJ(cnpj);
    return cnpjs.find((item) => item.key === unmaskedCNPJ) || null;
  };

  const handleRemoveProductsCart = () => {
    if (isNotEmpty(productCart)) resetProductCartContext();
  };

  const handleOnChangeCompanyName = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const cnpjCompanyMask = extractCNPJ(event.target.value);

    handleRemoveProductsCart();

    if (isInvalidCNPJ(cnpjCompanyMask)) {
      setFieldValue(FormikName.COMPANY, event.target.value);
      snackbar({
        message: "Empresa selecionada está com CNPJ no formato incorreto",
        variant: "error",
      });
      return;
    }

    const seletectedCNPJ = findCNPJ(cnpjCompanyMask, cnpjs);
    const nameCompany = seletectedCNPJ?.value || "";

    if (seletectedCNPJ) {
      setFieldValue(FormikName.COMPANY, event.target.value);
      setSelectedCNPJ({
        key: unmaskCNPJ(cnpjCompanyMask),
        value: nameCompany,
      });

      localStorage.setItem(
        LocalStorageKeysOrders.CNPJFromNewOrder,
        unmaskCNPJ(cnpjCompanyMask) || ""
      );
    }
  };

  const handleOnChangeDeliveryAddress = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFieldValue(FormikName.DELIVERY, event.target.value);

    const addressSelected = addressOptions?.find(
      (address) => address.value === event.target.value
    );

    if (addressSelected) {
      setSelectedAddress(addressSelected);
    }
  };

  const handleOnChangeCEP = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(FormikName.CEP, event.target.value);
  };

  const handleOnChangeDistrict = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFieldValue(FormikName.DISTRICT, event.target.value);
  };

  const handleOnChangeCity = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(FormikName.CITY, event.target.value);
  };

  const handleOnChangeWithDrawalBase = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const withdrawalBaseValue = event.target.value;

    const branchValue =
      dataCustomer?.withdrawBasis && dataCustomer?.withdrawBasis.length > 0
        ? dataCustomer.withdrawBasis.find(
            (branch) => branch.name === withdrawalBaseValue
          )?.branchName
        : "Não Informado";

    setFieldValue(FormikName.SUBSIDIARY, branchValue);

    setFieldValue(FormikName.WITHDRAWALBASE, withdrawalBaseValue);

    const withDrawalBaseSelected =
      withDrawalBases?.find((item) => item.value === event.target.value) ||
      null;

    if (filials && filials.length > 0 && withDrawalBaseSelected) {
      setSelectedFilial(filials[withDrawalBaseSelected.key]);
    }
  };

  const handleOnChangeSubsidiary = (subsidiary: string) => {
    setFieldValue(FormikName.SUBSIDIARY, subsidiary);
  };

  const handleOnChangeDateDelivery = (data: MaterialUiPickersDate) => {
    if (data) {
      localStorage.setItem(
        LocalStorageKeysOrders.DateDelivery,
        convertDateInFormatZ(data + "")
      );
    }
    setFieldValue(FormikName.DATEDELIVERY, data);
  };

  const handleOnChangFreight = (optionSelected: string) => {
    setFieldValue(FormikName.FREIGHT, optionSelected);
  };

  const minDate = calculateMinDate(values.freight);

  useEffect(() => {
    const filialID = selectedFilial?.filialID;
    setIsCDM(filialID === "CDM1" || filialID === "CDM2");
  }, [selectedFilial]);

  useEffect(() => {
    if (dataCustomer && dataCustomer.address.length > 0) {
      const filialDefault = dataCustomer.address.find((address) =>
        ["CDM1", "CDM2"].includes(address.filialIdDefault)
      );

      if (filialDefault) {
        setIsCDM(true);
        const matchedWithdrawalBase = dataCustomer.withdrawBasis.find(
          (basis) => basis.filialID === filialDefault.filialIdDefault
        );

        if (matchedWithdrawalBase) {
          setSelectedWithDrawalBase({
            key: matchedWithdrawalBase.withdrawBasis,
            value: matchedWithdrawalBase.name,
          });

          setSelectedFilial({
            filialIndex: dataCustomer.withdrawBasis.findIndex(
              (basis) => basis.filialID === filialDefault.filialIdDefault
            ),
            filialID: matchedWithdrawalBase.filialID,
            branchName: matchedWithdrawalBase.branchName,
          });
        }
      } else {
        setIsCDM(false);
      }
    }
  }, [dataCustomer]);

  // Note: Regra de negocio <> campo de frete deve estar desabilitado apenas para C001(filialdefault);
  const lockDefaultFreightType = dataCustomer?.address.some(
    (address) => address.filialIdDefault === FILIAL_DEFAULT_C001
  );
  return (
    <Grid container gap={4}>
      {/* RAZAO SOCIAL */}
      <Grid item xs={12}>
        {loading ? (
          <CircularProgress />
        ) : (
          <Grid
            spacing={2}
            container
            sx={{
              justifyContent: { xs: "center", md: "space-between" },
            }}
          >
            <Grid item xs={12} md={8}>
              <TextFieldSelect
                id="companyName"
                name={FormikName.COMPANY}
                label="Razão social"
                value={values.companyName}
                placeholder="Selecione uma opção"
                optionslist={cnpjs}
                onChange={handleOnChangeCompanyName}
                onBlur={handleBlur}
                error={touched.companyName && Boolean(errors.companyName)}
                helperText={errors.companyName}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              {!loadingCNPJDetails && (
                <TextFieldSelect
                  id="deliveryAddress"
                  name={FormikName.DELIVERY}
                  label="Endereço de entrega"
                  value={values.deliveryAddress}
                  placeholder="Selecione uma opção"
                  optionslist={addressOptions !== null ? addressOptions : []}
                  onChange={handleOnChangeDeliveryAddress}
                  onBlur={handleBlur}
                  error={
                    touched.deliveryAddress && Boolean(errors.deliveryAddress)
                  }
                  helperText={errors.deliveryAddress}
                  disabled={values.companyName.length === 0 ? true : false}
                />
              )}
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid item xs={12}>
        {loadingCNPJDetails ? (
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            padding={"10rem"}
          >
            <Tooltip
              open={loadingCNPJDetails}
              title={
                <Typography variant={"body1"}>
                  Os Dados estao sendo carregados, por favor aguarde ...
                </Typography>
              }
            >
              <CircularProgress />
            </Tooltip>
          </Grid>
        ) : (
          <Grid container gap={4}>
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                sx={{
                  justifyContent: { xs: "center", md: "space-between" },
                }}
              >
                <Grid item xs={12} md={3}>
                  <TexFieldLoose
                    id="cep"
                    name={FormikName.CEP}
                    label="CEP"
                    value={values.cep}
                    onChange={handleOnChangeCEP}
                    onBlur={handleBlur}
                    error={touched.cep && Boolean(errors.cep)}
                    helperText={errors.cep}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TexFieldLoose
                    id="district"
                    name={FormikName.DISTRICT}
                    label="Bairro"
                    value={values.district}
                    onChange={handleOnChangeDistrict}
                    onBlur={handleBlur}
                    error={touched.district && Boolean(errors.district)}
                    helperText={errors.district}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TexFieldLoose
                    id="city"
                    name={FormikName.CITY}
                    label="Cidade e estado"
                    value={values.city}
                    onChange={handleOnChangeCity}
                    onBlur={handleBlur}
                    error={touched.city && Boolean(errors.city)}
                    helperText={errors.city}
                    disabled
                  />
                </Grid>

                {/* FRETE - esconder quando for mobile(xl) 0px a 599px / tablet(sm) 600px a 899px / tablet(md) 900px a 1119px */}
                <Hidden mdDown>
                  <Grid
                    item
                    className={`input-tipo-de-frete`}
                    xs={4}
                    md={3}
                    sx={{ border: "0px solid blue" }}
                  >
                    <TextFieldCheckBox
                      id="freight"
                      name={FormikName.FREIGHT}
                      label="Frete:"
                      value={values.freight}
                      options={
                        isCDM
                          ? [`${DeliveryType.FOB}`]
                          : [`${DeliveryType.CIF}`, `${DeliveryType.FOB}`]
                      }
                      onChange={handleOnChangFreight}
                      disabled={lockDefaultFreightType}
                    />
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid
                spacing={2}
                container
                sx={{
                  justifyContent: { xs: "space-between", md: "space-between" },
                }}
              >
                <Grid item xs={12} md={4}>
                  <TextFieldSelect
                    id="withDrawalBase"
                    name={FormikName.WITHDRAWALBASE}
                    label="Base de retirada"
                    value={values.withDrawalBase}
                    placeholder="Selecione uma opção"
                    optionslist={withDrawalBases}
                    onChange={handleOnChangeWithDrawalBase}
                    onBlur={handleBlur}
                    error={
                      touched.withDrawalBase && Boolean(errors.withDrawalBase)
                    }
                    helperText={errors.withDrawalBase}
                    disabled={withDrawalBases.length === 0 ? true : false}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TexFieldLoose
                    id="subsidiary"
                    name={FormikName.SUBSIDIARY}
                    label="Filial"
                    value={values.subsidiary || ""}
                    onChange={(e) => handleOnChangeSubsidiary(e.target.value)}
                    onBlur={handleBlur}
                    error={touched.subsidiary && Boolean(errors.subsidiary)}
                    helperText={errors.subsidiary}
                    disabled={true}
                  />
                </Grid>

                {/* DATA DE ENTREGA  */}
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    border: {
                      xs: "0px solid red",
                      md: "0px solid black",
                      lg: "0px solid blue",
                    },
                  }}
                >
                  <CalendarSpecific
                    id="dateDelivery"
                    name={FormikName.DATEDELIVERY}
                    label={"Data da entrega"}
                    value={values.dateDelivery}
                    onChange={handleOnChangeDateDelivery}
                    minDate={minDate}
                    freightType={values.freight}
                    onBlur={undefined}
                  />
                </Grid>

                <Hidden mdUp>
                  <Grid item xs={4.5} md={3} sx={{ border: "0px solid red" }}>
                    <TextFieldCheckBox
                      id="freight"
                      name={FormikName.FREIGHT}
                      label="Frete:"
                      value={isCDM ? `${DeliveryType.CIF}` : values.freight}
                      options={[`${DeliveryType.CIF}`, `${DeliveryType.FOB}`]}
                      onChange={handleOnChangFreight}
                      disabled={lockDefaultFreightType}
                    />
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export const GroupedResponsibleData: React.FC = () => {
  const {
    errors,
    setFieldValue,
    values,
    touched,
    handleBlur,
  } = useFormikContext<FormikInitialValuesNewRequest>();

  const handleOnChangeAdvisor = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFieldValue(FormikName.ADVISOR, event.target.value);
  };

  const handleOnChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(FormikName.EMAIL, event.target.value);
  };

  const handleOnChangeTelephone = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFieldValue(FormikName.TELEPHONE, event.target.value);
  };

  return (
    <Grid container gap={4}>
      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent={"space-between"}>
          {/* Assessor */}
          <Grid item xs={12} md={4}>
            <TexFieldLoose
              id="advisor"
              name={FormikName.ADVISOR}
              label="Assessor"
              value={formatAllFistLetterCapitalized(values.advisor)}
              onChange={handleOnChangeAdvisor}
              onBlur={handleBlur}
              error={touched.advisor && Boolean(errors.advisor)}
              helperText={errors.advisor}
              disabled={true}
            />
          </Grid>

          {/* Telephone  */}
          <Grid item xs={12} md={4}>
            <TexFieldLoose
              id="telephone"
              name={FormikName.TELEPHONE}
              label="Telefone (+DDD)"
              value={formatAllFistLetterCapitalized(values.telephone)}
              onChange={handleOnChangeTelephone}
              onBlur={handleBlur}
              error={touched.telephone && Boolean(errors.telephone)}
              helperText={errors.telephone}
              disabled={true}
            />
          </Grid>

          {/* E-mail  */}
          <Grid item xs={12} md={4}>
            <TexFieldLoose
              id="email"
              name={FormikName.EMAIL}
              label="E-mail"
              type="email"
              value={formatAllFistLetterCapitalized(values.email)}
              onChange={handleOnChangeEmail}
              onBlur={handleBlur}
              error={touched.email && Boolean(errors.email)}
              helperText={errors.email}
              disabled={true}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const GroupedProductData: React.FC = () => {
  const {
    loadingProductPrice,
    productsOptions,
    setSelectedProduct,
    setHistoryLastPaymentProduct,
    productCart,
    dataAllLastPayment,
  } = useForm();

  const {
    errors,
    setFieldValue,
    values,
    touched,
    handleBlur,
  } = useFormikContext<FormikInitialValuesNewRequest>();

  const handleOnChangeProduct = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFieldValue(FormikName.PRODUCT, "");

    const selectedProduct = productsOptions.find(
      (product) => product.value === event.target.value
    );

    if (selectedProduct) {
      handleSelectedLastPayment(selectedProduct);
      setSelectedProduct(selectedProduct);
    }
  };

  const handleSelectedLastPayment = (
    selectedProduct: OptionsSelectTextField | undefined
  ) => {
    let paymentDetails: IPaymentDetails | null = null;

    if (dataAllLastPayment) {
      const entry = Object.entries(dataAllLastPayment).find(
        ([key, _]) => key === selectedProduct?.key
      );

      paymentDetails = entry ? entry[1] : null;

      setHistoryLastPaymentProduct(
        paymentDetails ? paymentDetails?.deadlinePayment.toString() : null
      );
    }
  };

  return (
    <Grid
      container
      className="GroupedProductData"
      justifyContent={"space-between"}
      sx={{
        border: "0px solid red",
        height: { xs: "100%", lg: "95%" },
        gap: { xs: 1, sm: 2, md: 4, lg: 4 },
        pb: 4,
      }}
    >
      <Grid item xs={12} sx={{ border: "0px solid red" }}>
        {/* Descricao do Produto */}
        {loadingProductPrice ? (
          <Tooltip
            open={loadingProductPrice}
            title={
              <Typography variant={"body1"}>
                Os Dados do produto selecionado estao sendo carregados, por
                favor aguarde ...
              </Typography>
            }
          >
            <CircularProgress />
          </Tooltip>
        ) : (
          <TextFieldSelect
            id="product"
            name={FormikName.PRODUCT}
            label="Descrição do produto"
            value={values.product}
            placeholder="Selecione uma opção"
            optionslist={productsOptions}
            onChange={handleOnChangeProduct}
            onBlur={handleBlur}
            error={touched.product && Boolean(errors.product)}
            showkey={"true"}
            itsWithoutCNPJ
          />
        )}
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent={"center"}>
          {productCart.length === 0 ? (
            <ProductCartRedIcon
              style={{ fontSize: "10rem", margin: "10rem" }}
            />
          ) : (
            <ProductsCards products={productCart}></ProductsCards>
          )}
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          p: { xs: 0, md: "0rem 2rem 0rem 2rem" },
          border: "0px solid blue",
        }}
      >
        <Description
          text="  Sugerimos a programação com 24 horas de antecedência. Os pedidos
          programados podem sofrer alterações de preços e ser sujeitos a
          confirmação."
        />
      </Grid>
    </Grid>
  );
};

export const GroupedObservationData: React.FC = () => {
  const { setFieldValue, values, handleBlur } = useFormikContext<
    FormikInitialValuesNewRequest
  >();

  const theme = useTheme();

  const handleOnChangeObservation = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFieldValue(FormikName.OBSERVATION, event.target.value);
  };

  const handleOnChangeCommentsInInvoice = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFieldValue(FormikName.COMMENTS_IN_INVOICE, event.target.checked);
  };

  return (
    <Grid
      container
      justifyContent={"space-between"}
      sx={{ border: "0px solid red", height: "auto" }}
      gap={2}
    >
      <Grid item xs={12}>
        {/* Observacao  - CAMPO NAO OBRIGATORIO */}
        <TexFieldLoose
          id="observation"
          name={FormikName.OBSERVATION}
          label="Observação"
          value={values.observation}
          onChange={handleOnChangeObservation}
          onBlur={handleBlur}
          rows={4}
          multiline={true}
        />
      </Grid>

      <Grid item xs={12} padding={"0rem 0rem 0rem 1rem"}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                name={FormikName.COMMENTS_IN_INVOICE}
                value={values.commentsInInvoice}
                onBlur={handleBlur}
                onChange={handleOnChangeCommentsInInvoice}
              />
            }
            label="Incluir observação na nota fiscal"
            sx={{
              "& .MuiFormControlLabel-label": {
                fontFamily: theme.typography.fontFamily,
                fontSize: "1.5rem",
              },
              "& .MuiSvgIcon-root": {
                fontSize: "2rem",
              },
              "& .Mui-checked": {
                color: "red !important",
              },
            }}
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
};

{
  /* TOTAL | TOTAIS  */
}
export const GroupedTotalData: React.FC = () => {
  const theme = useTheme();
  const { snackbar } = useDialogAlert();
  const { productCart } = useForm();

  const { values } = useFormikContext<FormikInitialValuesNewRequest>();

  const handleTotal = (): number => {
    const parseFloatCustom = (str: string | undefined): number => {
      return !str ? 0 : parseFloat(str);
    };
    const parseFloatCustomReplace = (str: string | undefined) => {
      return !str ? 0 : parseFloat(str.replace(".", ""));
    };
    const totalResults = productCart.reduce((total, produto) => {
      if (!produto.value)
        snackbar({ message: "Valor do produto ausente", variant: "info" });
      if (!produto.priceFreight && values.freight !== "FOB")
        snackbar({ message: "Valor do frete ausente", variant: "info" });

      if (values.freight === "CIF") {
        // caso o valor do frente seja undefined, deve sinalizar com tag ao lado do produto && produto.priceFreight
        return (
          total +
          parseFloatCustomReplace(produto.amount) *
            (parseFloatCustom(produto.value) +
              parseFloatCustom(produto.priceFreight))
        );
      }

      if (values.freight === "FOB") {
        // Se for FOB nao soma o valor do frete
        return (
          total +
          parseFloatCustomReplace(produto.amount) *
            parseFloatCustom(produto.value)
        );
      }

      return total;
    }, 0);

    return totalResults;
  };

  return (
    <Grid
      container
      justifyContent={"space-between"}
      sx={{
        border: "0px solid red",
        height: "auto",
        textAlign: "left",
        alignItems: "center",
      }}
    >
      <Grid item xs={12} md={12} lg={4.8}>
        <Grid
          container
          justifyContent={"space-between"}
          sx={{ alignItems: "center" }}
        >
          <Grid item xs={12}>
            <Grid container justifyContent={"flex-start"}>
              <Typography
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  fontSize: { xs: "1.4rem", md: "1.5rem" },
                  fontWeight: 500,
                  padding: "8px 0 8px 8px",
                }}
              >
                Quantidade total do pedido (L):
              </Typography>
              <Typography
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  fontSize: { xs: "1.4rem", md: "1.5rem" },
                  fontWeight: 700,
                  textAlign: "start",
                  padding: "8px 8px 8px 2px",
                }}
              >
                {productCart
                  .reduce((total, produto) => {
                    let newTotal = total;
                    if (produto.amount) {
                      newTotal =
                        total + parseFloat(produto.amount.replace(".", ""));
                      return newTotal;
                    }
                    return total;
                  }, 0)
                  .toLocaleString("pt-BR")}{" "}
                litros
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={12} lg={4}>
        <Grid
          container
          justifyContent={"flex-start"}
          sx={{ alignItems: "center" }}
        >
          <Grid item xs={12}>
            <Grid container justifyContent={"flex-start"}>
              <Typography
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  fontSize: { xs: "1.4rem", md: "1.5rem" },
                  fontWeight: 500,
                  padding: "8px 0 8px 8px",
                }}
              >
                Preço total do pedido:
              </Typography>
              <Typography
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  fontSize: { xs: "1.4rem", md: "1.5rem" },
                  fontWeight: 700,
                  textAlign: "start",
                  padding: "8px 8px 8px 2px",
                }}
              >
                {formatCurrencyPriceWithTwoDigit(handleTotal())}
                {/* R$ 00,00 */}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={12} lg={3.2}>
        {/* DESKTOP - VERSION */}
        <Hidden mdDown>
          <GroupedButtons />
        </Hidden>
      </Grid>
    </Grid>
  );
};

export const GroupedButtons: React.FC = () => {
  const theme = useTheme();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const {
    loadingSubmitButton,
    resetFormContext,
    newOrders,
    setNewOrders,
    selectedAddress,
    selectedAmount,
    productCart,
  } = useForm();
  const history = useHistory();

  const isButtonDisabled =
    !selectedAddress || selectedAmount === "0" || productCart.length === 0;

  const handleShowModalCancel = () => {
    setShowModal(!showModal);
  };

  const handleShowModalDetails = () => {
    setNewOrders([]);
    setShowConfirmModal(!showConfirmModal);
  };

  const handleClear = () => {
    setNewOrders([]);
    resetFormContext();
  };

  const handleResetForm = () => {
    handleClear();
    handleShowModalCancel();
  };

  const handleNewRequest = () => {
    handleClear();
    handleShowModalDetails();
  };

  const handleConsult = () => {
    handleClear();
    localStorage.setItem(LocalStorageKeysOrders.isOrderFromNewRequest, "true");
    handleShowModalDetails();
    history.push(ROUTES.USER_ROUTES.MY_ORDERS);
  };

  const isNewOrderCompleted = newOrders.length > 0;

  useEffect(() => {
    if (isNewOrderCompleted) {
      setShowConfirmModal(true);
      resetFormContext();
    }
  }, [newOrders]);

  return (
    <>
      {showModal && (
        <ModalCancel
          showModal={handleShowModalCancel}
          handleButtonPrimary={handleShowModalCancel}
          handleButtonContained={handleResetForm}
          title={"Cancelar este pedido?"}
          subtitle={
            "Ao cancelar este pedido, você não poderá mais recuperá-lo. Tem certeza?"
          }
          descriptionButtonPrimary={"Voltar à página anterior"}
          descriptionButtonContained={"Cancelar pedido"}
        />
      )}

      {showConfirmModal && (
        <ModalShowDetails
          showModal={handleShowModalDetails}
          handleButtonPrimary={handleNewRequest}
          handleButtonContained={handleConsult}
          title={
            isNewOrderCompleted
              ? "Detalhes do Pedido"
              : "Aguarde! Carregando o novo pedido"
          }
          bodyDetails={
            isNewOrderCompleted ? (
              <TablePageNewRequest />
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="300px"
                height="100px"
              >
                <CircularProgress />
              </Box>
            )
          }
          descriptionButtonPrimary={
            isNewOrderCompleted ? "Fazer novo pedido" : undefined
          }
          descriptionButtonContained={
            isNewOrderCompleted ? "Consultar pedido" : undefined
          }
        />
      )}

      <Grid container className="groupedButtons">
        <Grid item xs={12} sx={{ paddingTop: { xs: 4, lg: 0 } }}>
          <Grid
            container
            textAlign={"right"}
            sx={{
              justifyContent: { xs: "center", lg: "end" },
              gap: { xs: 6, md: 2 },
            }}
          >
            <Grid item xs={"auto"} sx={{ border: "0px solid red" }}>
              <Button
                onClick={() => handleShowModalCancel()}
                size="large"
                variant="outlined"
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  backgroundColor: theme.palette.background.paper,
                  color: theme.palette.primary.main,
                  border: "1px solid rgba(254,254,250)",
                  textTransform: "capitalize",
                  fontSize: { xs: "1.5rem", lg: "1.2rem" },
                  fontWeight: 700,
                  boxShadow: "none",
                }}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={"auto"} sx={{ border: "0px solid blue" }}>
              {loadingSubmitButton ? (
                <CircularProgress></CircularProgress>
              ) : (
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  disabled={isButtonDisabled}
                  onClick={() => setShowConfirmModal(true)}
                  sx={{
                    fontFamily: theme.typography.fontFamily,
                    backgroundColor: theme.palette.primary.main,
                    textTransform: "capitalize",
                    fontSize: { xs: "1.5rem", lg: "1.2rem" },
                    fontWeight: 700,
                    boxShadow: "none",
                  }}
                >
                  Finalizar
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
