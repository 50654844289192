import React, { useEffect, useState } from "react";
import { Typography, useTheme } from "@material-ui/core";
import FloatingMenuFixedMobile from "@components/FloatingMenuFixedMobile";
import TruckSVG from "@components/TruckSVG";
import ProductList from "./ProductList";
import ProductCompartmentsModal from "./ProductCompartmentsModal";
import MessageInfo from "./MessageInfoTitle";
import {
  Product,
  useProgrammingTransport,
} from "@context/ProgrammingTransport/ProgrammingTransportContext";
import StatusIndicadores from "@components/StatusIndicadores";
import { Grid, useMediaQuery } from "@mui/material";
import GroupMenuBackAndNext from "@components/GroupMenuBackAndNext";

interface SectionTwoProps {
  onNext: () => void;
  onBack: () => void;
}

interface AvailableCompartmentsValues {
  id: number;
  label: string;
  volume: number;
}

const ProgrammingSectionTwo: React.FC<SectionTwoProps> = ({
  onNext,
  onBack,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const {
    compartments,
    occupiedCompartments,
    updateTransportBody,
    setCreateTransportBody,
    rowSelected,
    allAllocatedProducts,
    setAllAllocatedProducts,
  } = useProgrammingTransport();

  const [selectedProductId, setSelectedProductId] = useState<string | null>(
    null
  );
  const currentProduct = selectedProductId
    ? allAllocatedProducts.find((p) => p.id === selectedProductId) || null
    : null;

  // Id dos Compartimentos alocados com produtos (combustíveis)
  const usedCompartmentsByOthers = allAllocatedProducts
    .filter((p) => p.id !== currentProduct?.id)
    .flatMap((p) => p.compartments);

  // Compartimentos disponíveis: se ainda houver, significa não está 100% preenchido
  const availableCompartments = compartments.filter((comp) => {
    const isOccupiedFromStep1 = occupiedCompartments.some(
      (occ) => occ.index === comp.index
    );
    const isUsedByOtherProduct = usedCompartmentsByOthers.includes(comp.index);
    return !isOccupiedFromStep1 && !isUsedByOtherProduct;
  });

  const handleAvailableCompartments = (
    currentProduct: Product
  ): AvailableCompartmentsValues[] => {
    const allCompartments = availableCompartments.map((comp) => ({
      id: comp.index,
      label: `Compartimento ${comp.index}`,
      volume: comp.capacity,
    }));
    const filledCompartments = currentProduct.compartments;
    const availableCompartmentsValues = allCompartments.filter(
      (c) =>
        !filledCompartments.includes(c.id) ||
        currentProduct.compartments.includes(c.id)
    );

    return availableCompartmentsValues;
  };

  const removeBlockedCompartments = () => {
    const blockedIndices = occupiedCompartments.map((b) => b.index);

    const updatedProducts = allAllocatedProducts.map((product) => {
      return {
        ...product,
        compartments: product.compartments.filter(
          (compartment) => !blockedIndices.includes(compartment)
        ),
      };
    });

    return updatedProducts;
  };

  const handleProductCompartmentsChange = (
    productId: string,
    newCompartments: number[]
  ) => {
    setAllAllocatedProducts((prev) =>
      prev.map((p) =>
        p.id === productId ? { ...p, compartments: newCompartments } : p
      )
    );
  };

  const handleConfirm = () => {
    const items = compartments
      .map((compartment) => {
        const isOccupiedFromStep1 = occupiedCompartments.some(
          (occ) => occ.index === compartment.index
        );
        if (isOccupiedFromStep1) {
          return {
            slotIndex: compartment.index,
            type: "blocked",
            volume: compartment.capacity,
          };
        }

        const allocatedProduct = allAllocatedProducts.find((product) =>
          product.compartments.includes(compartment.index)
        );
        if (allocatedProduct) {
          return {
            slotIndex: compartment.index,
            type: "allocated",
            orderRef: allocatedProduct.id,
            volume: compartment.capacity,
            productName: allocatedProduct.name, // Apenas para debug ou exibição
          };
        }
        return null;
      })
      .filter((item) => item !== null);

    updateTransportBody(items as any);
    setCreateTransportBody((prev: any) => ({
      ...prev,
      allAllocatedProducts,
      items,
    }));
    onNext();
  };

  const availableCapacity = `Livre: ${availableCompartments
    .reduce((acc, c) => acc + c.capacity, 0)
    .toLocaleString()} litros`;

  const capacityText = `Capacidade: ${compartments
    .reduce((acc, c) => acc + c.capacity, 0)
    .toLocaleString()} litros`;

  useEffect(() => {
    const newAllProducts = removeBlockedCompartments();
    if (newAllProducts) {
      setAllAllocatedProducts(newAllProducts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!rowSelected?.Items) return;
    if (allAllocatedProducts.length === 0) {
      const mapped = rowSelected.Items.map((item) => ({
        id: item.Ordem_de_venda ?? "",
        name: item.Produto ?? "Produto Desconhecido",
        volume: item.Quantidade ?? 0,
        compartments: [],
      }));
      setAllAllocatedProducts(mapped);
    }
  }, [rowSelected, allAllocatedProducts.length, setAllAllocatedProducts]);

  return (
    <Grid
      container
      spacing={2}
      sx={{
        color: theme.palette.primary.highlightedplus,
      }}
    >
      <Grid item xs={12} md={12} lg={6} sx={{ marginTop: "1rem" }}>
        <Grid item xs={12}>
          <MessageInfo title="Toque no produto para adicionar ao caminhão" />
        </Grid>

        <Grid item xs={12} style={{ marginTop: "4rem" }}>
          <ProductList
            products={allAllocatedProducts}
            onProductClick={(productId) => setSelectedProductId(productId)}
          />
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        md={12}
        lg={6}
        sx={{ textAlign: "center", marginBottom: "1rem" }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            height: "100%",
            minHeight: "35vh",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            borderRadius: "4px",
            backgroundColor: theme.palette.shadesOfDark.background,
          }}
        >
          <Grid
            container
            sx={{
              marginBottom: "2rem",
              justifyContent: "center",
            }}
          >
            <TruckSVG
              compartmentQuantity={compartments.length}
              filledCompartments={allAllocatedProducts.flatMap(
                (prod) => prod.compartments
              )}
              occupiedCompartments={occupiedCompartments.map(
                (comp) => comp.index
              )}
              allocatedColor={theme.palette.primary.main}
              occupiedColor={theme.palette.primary.light}
            />
          </Grid>
          <Grid
            container
            spacing={1}
            sx={{ marginBottom: "1rem", justifyContent: "center" }}
          >
            <Typography
              style={{
                fontSize: "1.2rem",
                fontWeight: 500,
                marginRight: "1rem",
                color: theme.palette.text.primary,
              }}
            >
              {capacityText}
            </Typography>
            <Typography
              style={{
                fontSize: "1.2rem",
                fontWeight: 500,
                marginRight: "1rem",
                color: theme.palette.text.primary,
              }}
            >
              |
            </Typography>
            <Typography
              style={{
                fontSize: "1.2rem",
                fontWeight: 500,
                color: theme.palette.text.primary,
              }}
            >
              {availableCapacity}
            </Typography>
          </Grid>
          <StatusIndicadores />
          {currentProduct && !availableCompartments.length && (
            <Typography
              align="center"
              style={{
                color: theme.palette.error.main,
                fontSize: 14,
                paddingBottom: 70,
                marginTop: "-30px",
              }}
            >
              Não há compartimentos disponíveis
            </Typography>
          )}
        </Grid>
      </Grid>

      {isMobile ? (
        <Grid item xs={12}>
          <FloatingMenuFixedMobile
            firstButtonDescription="Voltar"
            secondButtonDescription="Avançar"
            handleApprove={handleConfirm}
            handleClose={onBack}
          />
        </Grid>
      ) : (
        <Grid container sx={{ margin: "1rem" }}>
          <GroupMenuBackAndNext
            firstButtonDescription="Voltar"
            secondButtonDescription="Avançar"
            handleApprove={handleConfirm}
            handleClose={onBack}
          />
        </Grid>
      )}

      {currentProduct &&
        availableCompartments.length &&
        (() => {
          const availableCompartmentsValues = handleAvailableCompartments(
            currentProduct
          );

          return (
            <ProductCompartmentsModal
              product={currentProduct}
              availableCompartments={availableCompartmentsValues}
              onClose={() => setSelectedProductId(null)}
              onUpdateCompartments={(newComps) =>
                handleProductCompartmentsChange(currentProduct.id, newComps)
              }
            />
          );
        })()}
    </Grid>
  );
};

export default ProgrammingSectionTwo;
