import { KEY } from "@utils/enum";

const cardNextLoadsColumns = [
  {
    label: "N° Agendamento",
    key: KEY.REF,
  },
  {
    label: "Quantidade",
    key: KEY.QUANTITY,
    align: "right",
  },
  {
    label: "Motorista",
    key: KEY.DRIVER,
    minWidth: "130px",
  },
  {
    label: "Agendado para",
    key: KEY.SCHEDULEDATE,
    align: "left",
  },
];

export const cardExpandColumns = [
  {
    label: "N° Agendamento",
    key: KEY.REF,
  },
  {
    label: "Cliente",
    key: KEY.FILIAL,
  },
  {
    label: "Quantidade",
    key: KEY.QUANTITY,
    align: "right",
  },
  {
    label: "Motorista",
    key: KEY.DRIVER,
    minWidth: "130px",
  },
  {
    label: "Agendado para",
    key: KEY.SCHEDULEDATE,
    align: "left",
  },
 
];

export const cardExpandItemsColumns = [
  {
    label: "Ordem de venda",
    key: KEY.ORDER,
  },
  {
    label: "CNPJ/CPF",
    key: KEY.CNPJ,
  },
  {
    label: "Cliente",
    key: KEY.CUSTOMER,
  },
  {
    label: "Quantidade",
    key: KEY.QUANTITY,
  },
  {
    label: "Produto",
    key: KEY.PRODUCT,
  },
  {
    label: "Status",
    key: KEY.STATUS,
  },
 
 
];

const cardNotLoadsColumns = [
  {
    value: KEY.CUSTOMER,
    label: "Cliente",
    width: { lg: "94px", xl: "120px" },
  },
  {
    value: KEY.PRODUCT,
    label: "Produto",
    width: { lg: "94px", xl: "120px" },
  },
  {
    value: KEY.QUANTITY,
    label: "Quantidade",
    width: { lg: "94px", xl: "120px" },
    align: { xs: "left", sm: "right" },
  },
  {
    value: KEY.ORDER,
    label: "Ordem",
    width: { lg: "74px", xl: "120px" },
    maxWidth: "fit-content !important",
    align: { xs: "left", sm: "right" },
  },
  {
    value: KEY.RELEASEDATE,
    label: "Data de entrega",
    width: { lg: "128px", xl: "135px" },
    align: { xs: "left", sm: "right" },
  },
  {
    value: KEY.ACTION,
    label: undefined,
    width: { lg: "105px", xl: "120px" },
    align: "center",
  },
];

export { cardNextLoadsColumns, cardNotLoadsColumns };
