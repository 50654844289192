import React, { ReactElement, useEffect, useState } from "react";
import { useTheme } from "@material-ui/core";
import {
  Collapse,
  Grid,
  IconButton,
  TableBody,
  TableHead,
  TableRow,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { TableSalesOrder } from "@pages/User/RoadLoad/resource/table/TableSalesOrder";

import { Table, TableCell, TableContainer } from "@components/Table";
import PaginationCustom from "@components/Pagination";
import { AddIcon } from "@components/Icons";
import Button from "@components/Button";
import Modal from "@components/Modal";

import {
  TableBodyTypography,
  TableHeadTypography,
  TypographyCaption,
  TypographyTitle,
} from "../typography";
import {
  useRoadLoad,
  limitRowsPerPageDefault,
  LIMIT_ORDER_SALES,
} from "../../context/RoadLoadContext";
import { tableHeaderScheduledLoadOrders } from "./header";
import { IRoadNotLoad, IScheduledLoad, StatusSchedule } from "../../model";
import { useHistory } from "react-router-dom";

import ModalCreatedOrder from "../modal/ModalCreatedOrder";
import ModalDeleteOrder from "../modal/ModalDeleteOrder";

import {
  applyColor,
  cutText,
  formatAllFistLetterCapitalized,
  formatDateAndHourPTBR,
  formatDateWithSlash,
  formatToAmountLiters,
  maskCNPJ,
} from "@utils/index";
import { KEY, STATUSTYPE, STATUSROADLOAD } from "@utils/enum";

import TableSalesOrderGroupedMobile from "./TableSalesOrderGroupedMobile";
import { TableLoadsSkeleton } from "./TableSalesOrderSkeleton";
import TableEmpty from "./TableEmpty";
import LongMenu from "../Menu/LongMenu";
import GetAppIcon from "@mui/icons-material/GetApp";
import ModalCancelProgramming from "../modal/ModalCancelProgramming";
import { useProgrammingTransport } from "@context/ProgrammingTransport/ProgrammingTransportContext";
import ROUTES from "@config/routes";

interface TableColum<T> {
  key: keyof T;
  label: string | Function;
  align?: string;
  width?: string;
}

export interface TableUnscheduleProps {
  status: STATUSROADLOAD;
  headers: { key: string; label: string | (() => React.JSX.Element) }[];
  rows: IRoadNotLoad[];
  loading?: boolean;
  rowsCount: number;
  handleFetchRoadLoad?: (query) => void;
  onClickAddOrder?: (
    scheduleGroupIndex: number,
    roadsSelecteds
  ) => Promise<void>;
}

interface Props<T> {
  name?: string;
  index?: number;
  columns: TableColum<T>[];
  rows?: T[];
  isTableOrders?: boolean;
  onClick?: (scheduleIndex: number, orderIndex: number) => Promise<void>;
  status:
    | STATUSROADLOAD.FINISHED
    | STATUSROADLOAD.SCHEDULED
    | STATUSROADLOAD.CANCELED;
  handleFetchRoadLoadGrouped?: (query) => void;

  tableUnscheduled?: TableUnscheduleProps;
  rowsCount?: number;
  loading?: boolean;
  hiddenPdfReport?: boolean;
  enableEditDriver?: boolean;
}
// Note: Tabela ordem de vendas agrupadas  <> TableScheduleLoad <> TableSchedulingGrouping
const TableSalesOrderGrouped: <T>(props: Props<T>) => ReactElement = (
  props
) => {
  const {
    columns,
    rows,
    isTableOrders,
    index,
    onClick,
    name,
    status,
    tableUnscheduled,
    rowsCount,
    loading,
    handleFetchRoadLoadGrouped,
    hiddenPdfReport,
    enableEditDriver,
  } = props;

  const theme = useTheme();
  const history = useHistory();

  const {
    loading: contextLoading,
    roadsSelected,
    updateRoadSelected,
    handleRoadLoadMethod,
    roadCreated,
  } = useRoadLoad();

  const {
    deleteTransport,
    rowSelected,
    setRowSelected,
  } = useProgrammingTransport();
  const [openCancelProgrammingModal, setOpenCancelProgrammingModal] = useState(
    false
  );

  const [hasProgramming, setHasProgramming] = useState<boolean>();
  const [currentPage, setCurrentPage] = useState(0);
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [loadAddOrder, setLoadAddOrder] = useState<boolean>(false);

  const conditionAllowActionButtons = status === STATUSROADLOAD.SCHEDULED;

  const disabledAddOrder = rows && rows.length >= LIMIT_ORDER_SALES;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const shouldRenderMenuDropdown = <T,>(
    status: string,
    rowData: T
  ): boolean => {
    const isRendeMenu = status === STATUSROADLOAD.SCHEDULED;

    if (isRendeMenu && rowData) {
      setRowSelected(rowData as IScheduledLoad);
    }

    return isRendeMenu;
  };

  const handleCancelCarCharging = async (): Promise<void> => {
    setOpenCancelProgrammingModal(true);
  };

  const handleEditAndCreateCarCharging = () => {
    history.push(ROUTES.USER_ROUTES.NEW_PROGRAMMING);
  };

  const handleCloseCancelProgrammingModal = async (): Promise<void> => {
    if (!rowSelected) return;

    const anyRow = rowSelected;
    if (!anyRow.scheduleGroupID) return;

    try {
      await deleteTransport(anyRow.scheduleGroupID);
      setOpenCancelProgrammingModal(false);
      handleRoadLoadMethod.fetchScheduled();
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnChangePaginationDesktopGrouped = (
    _,
    desktopPageNext: number
  ) => {
    setCurrentPage(desktopPageNext - 1);
    handleFetchRoadLoadGrouped &&
      handleFetchRoadLoadGrouped({
        page: desktopPageNext,
        limit: limitRowsPerPageDefault,
      });
  };

  const handleExpandRow = (rowId: number) => {
    if (expandedRow === rowId) {
      setExpandedRow(null); // NOTE: Fecha o accordion se já estiver expandido
    } else {
      setExpandedRow(rowId); // NOTE: Abre o accordion se estiver fechado
    }
  };

  const deleteOrder = async (groupIndex, rowId: number) => {
    try {
      if (typeof groupIndex === "undefined") return;
      onClick && (await onClick(groupIndex, rowId));
    } catch (err) {
      throw err;
    }
  };

  const addOrder = async (rowId?: number, roadsSelected?): Promise<void> => {
    try {
      setLoadAddOrder(true);
      if (typeof rowId === "undefined") return;
      tableUnscheduled &&
        tableUnscheduled.onClickAddOrder &&
        (await tableUnscheduled.onClickAddOrder(rowId, roadsSelected));
    } catch (error) {
      throw error;
    } finally {
      setLoadAddOrder(false);
    }
  };

  const formatEntry = (key, value, row) => {
    switch (key) {
      case KEY.TOTALAMOUNTLITERS:
      case KEY.QUANTITY:
        return formatToAmountLiters(value);
      case KEY.DELIVERYDATE:
      case KEY.RELEASEDATE:
      case KEY.INVOICEDATE:
      case KEY.BLOCKEDDATE:
        return formatDateWithSlash(value);
      case KEY.SCHEDULEDATE:
        return formatDateAndHourPTBR(value, row["timezone"]);
      case KEY.STATUS:
        switch (value) {
          case STATUSTYPE.FATURADO:
          case STATUSTYPE.LIBERADO:
          case STATUSTYPE.BLOQUEADO:
          case STATUSTYPE.CANCELADO:
            return value;

          default:
            return "Nao informado";
        }

      case KEY.CUSTOMER:
        return `${row["customerID"]} - ${formatAllFistLetterCapitalized(
          value
        )}`;

      case KEY.BPID:
        return `${value} - ${cutText(
          formatAllFistLetterCapitalized(row["bpIdName"]),
          25
        )}`;

      case KEY.REF:
        return value;

      case KEY.CNPJ:
        return maskCNPJ(value);

      case KEY.ICON:
        return value();

      case KEY.FREIGHT:
      case KEY.PLATE:
        return value;

      default:
        return formatAllFistLetterCapitalized(value);
    }
  };

  const shouldRenderCancelScheduling = (status: string): boolean => {
    return (
      status !== STATUSROADLOAD.CANCELED && status !== STATUSROADLOAD.FINISHED
    );
  };

  const shouldRenderReportPDF = (status: string): boolean => {
    return (
      status === STATUSROADLOAD.FINISHED || status === STATUSROADLOAD.SCHEDULED
    );
  };

  useEffect(() => {
    setHasProgramming(
      rowSelected?.Status_Programacao === StatusSchedule.PROGRAMMED
    );
  }, [rowSelected]);

  return loading ? (
    <TableLoadsSkeleton numberOfSkeletonCol={columns?.length} />
  ) : rows?.length === 0 ? (
    <TableEmpty status={status} />
  ) : isMobile ? (
    <TableSalesOrderGroupedMobile
      hiddenPdfReport={hiddenPdfReport}
      status={status}
      rows={rows}
      deleteOrder={deleteOrder}
      addOrder={addOrder}
      tableUnscheduled={tableUnscheduled}
    />
  ) : (
    <TableContainer>
      <Table
        className={name ?? "defaultTableCustom"}
        sx={{
          display: isTableOrders ? "table" : "grid-inline",
          borderCollapse: isTableOrders ? "collapse !important" : "separate",
        }}
      >
        <TableHead>
          <TableRow>
            {columns.map((column, index) => {
              const label =
                typeof column.label === "function"
                  ? column.label()
                  : column.label;
              return (
                <TableCell
                  key={index}
                  sx={{
                    textAlign: column?.align ?? "left",
                    width: column?.width ?? "auto",
                    border: "0px solid red",
                  }}
                >
                  <TableHeadTypography
                    sx={{
                      py: { xs: 0.2, lg: 1.2 },
                      opacity: rows?.length === 0 ? "0.4" : "1",
                    }}
                  >
                    {label}
                  </TableHeadTypography>
                </TableCell>
              );
            })}

            {!isTableOrders && (
              <TableCell /> // Note: Celula para suprir lacuna dos comandos accordion (exibir lista de ordens de venda) e agendar
            )}

            {isTableOrders && tableUnscheduled && conditionAllowActionButtons && (
              <TableCell>
                <Modal
                  className={"modalAddOrder"}
                  onCloseCallback={() => updateRoadSelected(undefined)}
                  disableModalButtonOpen={disabledAddOrder}
                  ModalButton={
                    <IconButton
                      onClick={() => {
                        handleRoadLoadMethod.resetRoadCreated();
                      }}
                      sx={{
                        opacity: disabledAddOrder ? "0.4" : "1",
                        gap: 1,
                      }}
                    >
                      <Tooltip
                        title={
                          disabledAddOrder ? (
                            <TypographyCaption sx={{ fontSize: 11.4 }}>
                              Limite maximo de 10 ordens atingido!
                            </TypographyCaption>
                          ) : (
                            ""
                          )
                        }
                        placement="top"
                      >
                        <span>
                          <AddIcon />
                        </span>
                      </Tooltip>
                    </IconButton>
                  }
                >
                  {({ handleClose }) => {
                    return (
                      <Grid container justifyContent={"center"}>
                        <Grid item>
                          <TypographyTitle sx={{ fontSize: { lg: 28 }, pb: 2 }}>
                            Adicionar Carregamento Rodoviario
                          </TypographyTitle>
                        </Grid>

                        <Grid item xs={12}>
                          {roadsSelected && roadsSelected.length > 0 && (
                            <TypographyCaption>
                              <b style={{ color: "#000" }}>
                                {roadsSelected.length}
                              </b>{" "}
                              Carregamento(s) selecionado(s)
                            </TypographyCaption>
                          )}
                        </Grid>

                        <TableSalesOrder
                          hiddenScheduleButton={true}
                          multiple={true}
                          status={tableUnscheduled.status}
                          headers={tableUnscheduled.headers}
                          rows={tableUnscheduled.rows}
                          loading={tableUnscheduled.loading}
                          rowsCount={tableUnscheduled.rowsCount}
                          handleFetchRoadLoad={
                            tableUnscheduled.handleFetchRoadLoad
                          }
                          amountSalesOrder={rows?.length ?? 0}
                        />

                        {roadsSelected && roadsSelected.length > 0 && (
                          <Grid
                            container
                            justifyContent={"right"}
                            alignItems={"center"}
                            gap={2}
                            sx={{ pt: 2 }}
                          >
                            <Grid item>
                              <Button
                                variant="outlined"
                                onClick={() => handleClose()}
                              >
                                Cancelar
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                variant={"contained"}
                                loading={loadAddOrder}
                                onClick={async () => {
                                  try {
                                    await addOrder(index, roadsSelected);
                                    handleClose();
                                  } catch (err) {
                                    throw err;
                                  }
                                }}
                              >
                                Agendar selecionados
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    );
                  }}
                </Modal>
              </TableCell>
            )}
          </TableRow>
        </TableHead>

        <TableBody
          sx={{ borderSpacing: isTableOrders ? "0px 0px" : "0px 12px" }}
        >
          {rows?.map((row, rowIndex) => {
            const conditionDisabledDeleteOrder = rows?.length <= 1;

            return (
              <React.Fragment key={rowIndex}>
                {/* NOTE: Primeria Tabela */}
                <TableRow>
                  {columns.map((column, colIndex) => {
                    const key = column.key;
                    const value = row[column.key];
                    return (
                      <TableCell
                        key={colIndex}
                        sx={{
                          textAlign: column?.align ?? "left",
                          width: column?.width ?? "auto",
                        }}
                      >
                        <TableBodyTypography
                          sx={{
                            color: applyColor(key, value as string, theme),
                          }}
                        >
                          {formatEntry(key, value ?? column.label, row)}
                        </TableBodyTypography>
                      </TableCell>
                    );
                  })}

                  {row["salesOrder"]?.length >= 0 && (
                    <TableCell sx={{ display: "flex", height: "auto" }}>
                      <Grid
                        container
                        wrap="nowrap"
                        alignItems={"center"}
                        justifyContent={"space-around"}
                        gap={2}
                        sx={{ border: "0px solid red" }}
                      >
                        <Grid item className={"Grid-accordion"}>
                          <IconButton
                            aria-expanded={expandedRow === rowIndex}
                            onClick={() => handleExpandRow(rowIndex)}
                          >
                            <ExpandMoreIcon
                              sx={{
                                fontSize: "30px",
                                transform:
                                  expandedRow === rowIndex
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                              }}
                            />
                          </IconButton>
                        </Grid>

                        {shouldRenderReportPDF(status) && (
                          <Grid item>
                            {contextLoading?.pdfReport?.[rowIndex] ? (
                              <CircularProgress />
                            ) : (
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  if (
                                    row?.["Ref"] &&
                                    row?.["scheduleGroupID"]
                                  ) {
                                    handleRoadLoadMethod.fetchDownloadPDF(
                                      row["Ref"],
                                      row["scheduleGroupID"],
                                      rowIndex
                                    );
                                  }
                                }}
                              >
                                <GetAppIcon
                                  className="reportIcon"
                                  color="primary"
                                  sx={{
                                    width: "2rem",
                                    height: "2rem",
                                    cursor: "pointer",
                                    ml: "10px",
                                  }}
                                />
                              </IconButton>
                            )}
                          </Grid>
                        )}

                        {shouldRenderMenuDropdown(status, row) && (
                          <Grid item className={"group-action-menu-dropdown"}>
                            <LongMenu
                              status={status}
                              rowIndex={rowIndex}
                              row={row}
                              rescheduleAllowed={conditionAllowActionButtons}
                              handleCancelScheduling={
                                handleRoadLoadMethod.fetchCancelScheduling
                              }
                              cancelSchedulingAllowed={shouldRenderCancelScheduling(
                                status
                              )}
                              handleCancelCarCharging={handleCancelCarCharging}
                              handleEditAndCreateCarCharging={
                                handleEditAndCreateCarCharging
                              }
                              scheduleCarCharging
                              editCarCharging
                              cancelCarCharging
                              editDriverAllowed={enableEditDriver}
                              hasProgramming={hasProgramming}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </TableCell>
                  )}

                  {isTableOrders && conditionAllowActionButtons && (
                    <TableCell>
                      <ModalDeleteOrder
                        disabled={conditionDisabledDeleteOrder}
                        onClick={async () => await deleteOrder(index, rowIndex)}
                        order={row["order"]}
                      />
                    </TableCell>
                  )}
                </TableRow>

                {/* NOTE: Segunda Tabela = quando clica em expandir*/}
                <TableRow
                  className="row-collapse"
                  sx={{
                    display: expandedRow === rowIndex ? "table-row" : "none",
                    position: "relative",
                    top: "-14px",
                  }}
                >
                  <TableCell
                    colSpan={columns.length + 2}
                    sx={{ border: "0px solid red" }}
                  >
                    <Collapse
                      in={expandedRow === rowIndex}
                      timeout="auto"
                      unmountOnExit
                    >
                      <TableSalesOrderGrouped
                        name={"Ordem de Venda"}
                        index={rowIndex}
                        columns={tableHeaderScheduledLoadOrders}
                        rows={row["salesOrder"] ?? []}
                        isTableOrders={true}
                        onClick={onClick}
                        tableUnscheduled={tableUnscheduled}
                        status={status}
                      />
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>

      {roadCreated && (
        <ModalCreatedOrder
          hasBeenEdit={roadCreated.hasBeenEdit}
          open={roadCreated?.active}
          order={roadCreated?.ref}
          driverName={roadCreated?.handlerMetadata?.driverName}
          scheduleDateTime={roadCreated?.scheduleResolvedDateTime}
          timezone={roadCreated.scheduleTimezone}
          onClick={handleRoadLoadMethod.resetRoadCreated}
        />
      )}

      {!isTableOrders && (
        <PaginationCustom
          count={rowsCount ?? 0}
          page={currentPage}
          rowsPerPage={limitRowsPerPageDefault}
          onChangeHandle={handleOnChangePaginationDesktopGrouped}
        />
      )}
      <ModalCancelProgramming
        open={openCancelProgrammingModal}
        onClose={() => setOpenCancelProgrammingModal(false)}
        refSchedule={rowSelected ? rowSelected.Ref : "N/A"}
        handleCancelProgramming={handleCloseCancelProgrammingModal}
      />
    </TableContainer>
  );
};

export default TableSalesOrderGrouped;
