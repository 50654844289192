import React from "react";

interface PathData {
  id: number;
  d: string;
}

type RenderPathsProps = (
  pathsData: PathData[],
  getColor: (id: number) => string,
  onClickCompartment?: (id: number) => void
) => JSX.Element[];

type RenderTwoLineLayoutProps = (
  totalCompartments: number,
  getColor: (id: number) => string,
  onClickCompartment?: (id: number) => void
) => JSX.Element;

/**
 * NOTE: Esta função auxiliar recebe um array de objetos { id, d },
 *       onde 'id' é o índice do compartimento e 'd' são as coordenadas do <path>.
 *       Ela retorna JSX com <path> (ou <rect>, se preferir) para cada objeto.
 */
const renderPaths: RenderPathsProps = (
  pathsData,
  getColor,
  onClickCompartment
) => {
  return pathsData.map((slot) => (
    <path
      key={slot.id}
      d={slot.d}
      fill={getColor(slot.id)}
      onClick={() => onClickCompartment?.(slot.id)}
      style={{ cursor: "pointer" }}
    />
  ));
};

/**
 * NOTE: Linha superior para 6 a 10 compartimentos (slots de 1 a 5).
 *       Para layouts de 6..10, todos usam exatamente essa mesma "fila de cima".
 */
const TOP_ROW_5 = [
  {
    id: 1,
    d: "M4 8C4 5.79086 5.79086 4 8 4H33.8V21H8C5.79086 21 4 19.2091 4 17V8Z",
  },
  {
    id: 2,
    d: "M35.8008 4H65.6008V21H35.8008V4Z",
  },
  {
    id: 3,
    d: "M67.5996 4H97.3996V21H67.5996V4Z",
  },
  {
    id: 4,
    d: "M99.4004 4H129.2V21H99.4004V4Z",
  },
  {
    id: 5,
    d:
      "M131.199 4H156.999C159.208 4 160.999 5.79086 160.999 8V17C160.999 19.2091 159.208 21 156.999 21H131.199V4Z",
  },
];

/**
 * NOTE: Linha inferior para 6 a 10 compartimentos (slots de 6 a 10).
 *       Dependendo do total (6, 7, 8, 9, 10), renderizamos apenas parte desse array.
 */
const BOTTOM_ROW_5 = [
  {
    id: 6,
    d:
      "M4 28C4 25.7909 5.79086 24 8 24H33.8V41H8C5.79086 41 4 39.2091 4 37V28Z",
  },
  {
    id: 7,
    d: "M35.8008 24H65.6008V41H35.8008V24Z",
  },
  {
    id: 8,
    d: "M67.5996 24H97.3996V41H67.5996V24Z",
  },
  {
    id: 9,
    d: "M99.4004 24H129.2V41H99.4004V24Z",
  },
  {
    id: 10,
    d:
      "M131.199 24H156.999C159.208 24 160.999 25.7909 160.999 28V37C160.999 39.2091 159.208 41 156.999 41H131.199V24Z",
  },
];

/**
 * NOTE: Função que desenha de 6 a 10 compartimentos usando duas linhas (top e bottom).
 *       Ele sempre desenha a linha superior com 5 slots (IDs 1..5),
 *       e na linha inferior apenas a quantidade necessária (6..N).
 */
const renderTwoLineLayout: RenderTwoLineLayoutProps = (
  totalCompartments,
  getColor,
  onClickCompartment
) => {
  // Calculando os slots necessários para a linha inferior
  const neededBottomSlots = BOTTOM_ROW_5.slice(0, totalCompartments - 5);

  return (
    <>
      {renderPaths(TOP_ROW_5, getColor, onClickCompartment)}
      {renderPaths(neededBottomSlots, getColor, onClickCompartment)}
    </>
  );
};

/**
 * NOTE: Este objeto `TruckLayouts` define layouts para 1..10 compartimentos.
 *       1..5 ficam "manuais" pois cada um tem caminhos bem específicos.
 *       6..10 usam a função `renderTwoLineLayout` para evitar repetição.
 */
export const TruckLayouts: Record<
  number,
  (
    getColor: (id: number) => string,
    onClickCompartment?: (id: number) => void
  ) => JSX.Element
> = {
  // -------------------------------------------------
  //                1 a 5 Compartimentos (manuais)
  // -------------------------------------------------

  1: (getColor, onClick) => (
    <>
      <path
        d="M4 8C4 5.79086 5.79086 4 8 4H160C162.209 4 164 5.79086 164 8V38C164 40.2091 162.209 42 160 42H8C5.79086 42 4 40.2091 4 38V8Z"
        fill={getColor(1)}
        onClick={() => onClick?.(1)}
        style={{ cursor: "pointer" }}
      />
    </>
  ),

  2: (getColor, onClick) => (
    <>
      {/* Compartimento 1 */}
      <path
        d="M4 8C4 5.79086 5.79086 4 8 4H82V42H8C5.79086 42 4 40.2091 4 38V8Z"
        fill={getColor(1)}
        onClick={() => onClick?.(1)}
        style={{ cursor: "pointer" }}
      />
      {/* Compartimento 2 */}
      <path
        d="M85 4H159C161.209 4 163 5.79086 163 8V38C163 40.2091 161.209 42 159 42H85V4Z"
        fill={getColor(2)}
        onClick={() => onClick?.(2)}
        style={{ cursor: "pointer" }}
      />
    </>
  ),

  3: (getColor, onClick) => (
    <>
      {/* Compartimento 1 */}
      <path
        d="M4 8C4 5.79086 5.79086 4 8 4H55V42H8C5.79086 42 4 40.2091 4 38V8Z"
        fill={getColor(1)}
        onClick={() => onClick?.(1)}
        style={{ cursor: "pointer" }}
      />
      {/* Compartimento 2 */}
      <rect
        x="58"
        y="4"
        width="52"
        height="38"
        fill={getColor(2)}
        onClick={() => onClick?.(2)}
        style={{ cursor: "pointer" }}
      />
      {/* Compartimento 3 */}
      <path
        d="M114 4H160C162.209 4 164 5.79086 164 8V38C164 40.2091 162.209 42 160 42H114V4Z"
        fill={getColor(3)}
        onClick={() => onClick?.(3)}
        style={{ cursor: "pointer" }}
      />
    </>
  ),

  4: (getColor, onClick) => (
    <>
      {/* Compartimento 1 */}
      <path
        d="M4 8C4 5.79086 5.79086 4 8 4H41V42H8C5.79086 42 4 40.2091 4 38V8Z"
        fill={getColor(1)}
        onClick={() => onClick?.(1)}
        style={{ cursor: "pointer" }}
      />
      {/* Compartimento 2 */}
      <path
        d="M45 4H82V42H45V4Z"
        fill={getColor(2)}
        onClick={() => onClick?.(2)}
        style={{ cursor: "pointer" }}
      />
      {/* Compartimento 3 */}
      <path
        d="M86 4H123V42H86V4Z"
        fill={getColor(3)}
        onClick={() => onClick?.(3)}
        style={{ cursor: "pointer" }}
      />
      {/* Compartimento 4 */}
      <path
        d="M127 4H160C162.209 4 164 5.79086 164 8V38C164 40.2091 162.209 42 160 42H127V4Z"
        fill={getColor(4)}
        onClick={() => onClick?.(4)}
        style={{ cursor: "pointer" }}
      />
    </>
  ),

  5: (getColor, onClick) => (
    <>
      {/* Compartimento 1 */}
      <path
        d="M4 8C4 5.79086 5.79086 4 8 4H35V41H8C5.79086 41 4 39.2091 4 37V8Z"
        fill={getColor(1)}
        onClick={() => onClick?.(1)}
        style={{ cursor: "pointer" }}
      />
      {/* Compartimento 2 */}
      <path
        d="M37 4H67V41H37V4Z"
        fill={getColor(2)}
        onClick={() => onClick?.(2)}
        style={{ cursor: "pointer" }}
      />
      {/* Compartimento 3 */}
      <path
        d="M69 4H99V41H69V4Z"
        fill={getColor(3)}
        onClick={() => onClick?.(3)}
        style={{ cursor: "pointer" }}
      />
      {/* Compartimento 4 */}
      <path
        d="M101 4H131V41H101V4Z"
        fill={getColor(4)}
        onClick={() => onClick?.(4)}
        style={{ cursor: "pointer" }}
      />
      {/* Compartimento 5 */}
      <path
        d="M133 4H160C162.209 4 164 5.79086 164 8V37C164 39.2091 162.209 41 160 41H133V4Z"
        fill={getColor(5)}
        onClick={() => onClick?.(5)}
        style={{ cursor: "pointer" }}
      />
    </>
  ),

  // -------------------------------------------------
  //    6 a 10 (usando a função genérica: 2 linhas)
  // -------------------------------------------------

  // NOTE: 6 -> (5 slots na top row) + (1 slot na bottom row)
  6: (getColor, onClick) => renderTwoLineLayout(6, getColor, onClick),
  // NOTE: 7 -> (5 slots na top row) + (2 slots na bottom row) e assim por diante
  7: (getColor, onClick) => renderTwoLineLayout(7, getColor, onClick),
  8: (getColor, onClick) => renderTwoLineLayout(8, getColor, onClick),
  9: (getColor, onClick) => renderTwoLineLayout(9, getColor, onClick),
  10: (getColor, onClick) => renderTwoLineLayout(10, getColor, onClick),
};
