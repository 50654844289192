import React, { useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { CheckBoxChecked, CheckBoxUnChecked } from "@components/Icons";

interface TextFieldCheckBoxProps {
  id?: string;
  name: string;
  label?: string;
  value: string;
  options: string[];
  sx?;
  onChange?: (option: string) => void;
  onBlur?: (event) => void;
  disabled?: boolean;
}

const TextFieldCheckBox: React.FC<TextFieldCheckBoxProps> = ({
  options,
  disabled = false,
  ...props
}) => {
  const [, setSelectedOption] = useState<string>(options[0]);

  const handleCheckboxChange = (option: string) => {
    setSelectedOption(option);
    props.onChange && props.onChange(option);
  };

  return (
    <FormControl
      id={props.id}
      sx={{
        display: "inline-flex",
        flexDirection: "initial",
        alignItems: "center",
        justifycontent: "center",
        height: "100%",
        width: "100%",
        opacity: disabled ? 0.6 : 1,
        cursor: disabled ? "not-allowed" : "pointer",
        ...props.sx,
      }}
    >
      {/****** LABEL *****/}
      <Grid container gap={1.5} sx={{ border: "0px solid black" }}>
        <Grid item xs={12} sx={{ border: "0px solid blue" }}>
          <InputLabel
            sx={{
              display: "flex",
              margin: "0px",
              transform: "translate(0px, -6px) scale(0.75)",
            }}
          >
            <Typography
              sx={{
                textAlign: "left",
                color: "#3E3D3D",
                fontFamily: "montserrat",
                fontWeight: 700,
                fontSize: "1.8rem",
              }}
            >
              {props.label}
            </Typography>
          </InputLabel>
        </Grid>

        {/*** BODY ***/}
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "2rem",
          }}
        >
          {options.map((option, index) => (
            <Tooltip
              title={disabled ? "Esse campo não pode ser alterado" : ""}
              placement="top"
              arrow
              key={index}
            >
              <FormControlLabel
                key={index}
                control={
                  <TextField
                    id={`meu-textfield-custom-${index}`}
                    variant="standard"
                    disabled={disabled}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          id="meu-InputAdornment-custom"
                          sx={{
                            marginTop: "0px !important",
                            "& .MuiInput-root": {
                              border: "1px solid red",
                              marginTop: "0px !important",
                            },
                          }}
                        >
                          <Checkbox
                            id={"Checkbox-TextField-Custom"}
                            checked={props.value === option}
                            onChange={() => handleCheckboxChange(option)}
                            inputProps={{ "aria-label": "controlled" }}
                            disabled={disabled}
                            icon={
                              <CheckBoxUnChecked
                                style={{ fontSize: "1.5rem" }}
                              />
                            }
                            checkedIcon={
                              <CheckBoxChecked style={{ fontSize: "1.5rem" }} />
                            }
                            sx={{
                              padding: "0px",
                              "&.Mui-checked": {
                                color: "#D91F05 !important",
                              },
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      border: "0px solid red",
                      display: "flex",
                      width: "auto",
                      "& label": {
                        // Controle de estilo antes de digitar
                        color: "#3E3D3D",
                        fontFamily: "montserrat",
                        fontWeight: 700,
                        fontSize: "1.8rem",
                        borderRadius: "1rem",
                        opacity: "1",
                        top: "-12px",
                        minWidth: "fit-content",
                      },
                      "& .MuiInputBase-root:before": {
                        border: "none !important",
                      },
                      "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before": {
                        border: "0px solid white !important",
                      },
                      "& input": {
                        display: "none !important",
                      },
                      "& .MuiInputBase-adornedStart": {
                        margin: "0px !important",
                      },
                    }}
                  />
                }
                label={option}
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontFamily: "montserrat",
                    fontWeight: 500,
                    color: "#3E3D3D",
                  },
                  width: "fit-content",
                  margin: "0px",
                }}
              />
            </Tooltip>
          ))}
        </Grid>
      </Grid>
    </FormControl>
  );
};

export default TextFieldCheckBox;
