import React from "react";
import { Box, Divider, Grid, IconButton } from "@mui/material";
import {
  TableHeadTypography,
  TypographyTitle,
} from "@pages/User/RoadLoad/resource/typography";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

interface FloatingMenuListProps {
  children: React.ReactNode;
  title?: string;
  subtitle?: string;
  handleClose: () => void;
}

const FloatingMenuList: React.FC<FloatingMenuListProps> = ({
  children,
  title,
  subtitle,
  handleClose,
}) => {
  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1000,
        }}
      />
      <Box
        sx={{
          position: "fixed",
          bottom: 2,
          left: 0,
          right: 0,
          bgcolor: "background.paper",
          boxShadow: 3,
          zIndex: 2000,
          maxHeight: "80vh",
          overflowY: "auto",
          overflowX: "auto",
          borderTopLeftRadius: "1rem",
          borderTopRightRadius: "1rem",
          p: "2rem",
        }}
      >
        <Grid container alignItems="center" sx={{ alignItems: "baseline" }}>
          <Grid item xs>
            {title && (
              <TypographyTitle justifySelf={"center"}>{title}</TypographyTitle>
            )}
            {subtitle && (
              <TableHeadTypography justifySelf={"center"} mt={"1rem"}>
                {subtitle}
              </TableHeadTypography>
            )}
          </Grid>
          <Grid item>
            <IconButton onClick={handleClose}>
              <CloseOutlinedIcon fontSize="large" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container>
          <Divider
            style={{ width: "100%", marginTop: "2rem", marginBottom: "1rem" }}
          />
        </Grid>

        <Grid container>{children}</Grid>
      </Box>
    </>
  );
};

export default FloatingMenuList;
