import { makeStyles, Theme, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContainer: {
      textAlign: "center",
      padding: theme.spacing(3),
    },
    icon: {
      width: "64px",
      height: "64px",
    },
    title: {
      fontWeight: "bold",
      color: theme.palette.text.primary,
    },
    programNumber: {
      color: theme.palette.primary.main,
      paddingRight: theme.spacing(0.25),
    },
    subtitle: {
      color: theme.palette.shadesOfDark.steel,
    },
  })
);

export default useStyles;
