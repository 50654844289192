import { makeStyles } from "@material-ui/core/styles";

export const useTruckInteractiveStyles = makeStyles((theme) => ({
  modalContainer: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  modalTitle: {
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
  },
  compartmentList: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  compartmentItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(0.8, 0),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));
