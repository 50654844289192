import React from "react";
import { SvgIcon, SvgIconProps, Theme } from "@material-ui/core";
import {
  SvgIcon as SvgIconCustom,
  SvgIconProps as SvgIconCustomProps,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import { ReactComponent as photoIcon } from "../../assets/PhotoIcon.svg";
import { ReactComponent as arrowDown } from "../../assets/arrowDown.svg";
import { ReactComponent as arrowLeftImg } from "../../assets/arrowLeft.svg";
import { ReactComponent as arrowLeftDark } from "../../assets/arrowLeftDark.svg";
import { ReactComponent as arrowRightImg } from "../../assets/arrowRight.svg";
import { ReactComponent as arrowRightDark } from "../../assets/arrowRightDark.svg";
import { ReactComponent as arrowLeftRed } from "../../assets/arrowLeftRed.svg";
import { ReactComponent as avatarUserImg } from "../../assets/avatarUser.svg";
import { ReactComponent as billedSVG } from "../../assets/billed.svg";
import { ReactComponent as blockSVG } from "../../assets/block.svg";
import { ReactComponent as cactusImg } from "../../assets/cactus.svg";
import { ReactComponent as comissionIcon } from "../../assets/calculator/commission.svg";
import { ReactComponent as literIcon } from "../../assets/calculator/gasolina.svg";
import { ReactComponent as calendarImg } from "../../assets/calendar.svg";
import { ReactComponent as campaignMetricsImg } from "../../assets/campaignMetrics.svg";
import { ReactComponent as cancelImg } from "../../assets/cancel.svg";
import { ReactComponent as checkBoxchecked } from "../../assets/checkBoxChecked.svg";
import { ReactComponent as checkBoxUnchecked } from "../../assets/checkBoxUnChecked.svg";
import { ReactComponent as checkedImg } from "../../assets/checked.svg";
import { ReactComponent as circleBlocked } from "../../assets/circleBlocked.svg";
import { ReactComponent as circleCheck } from "../../assets/circleCheck.svg";
import { ReactComponent as circleCheckDisabled } from "../../assets/circleCheckDisabled.svg";
import { ReactComponent as clockSVG } from "../../assets/clock.svg";
import { ReactComponent as closeImg } from "../../assets/close.svg";
import { ReactComponent as closeRed } from "../../assets/closeRed.svg";
import { ReactComponent as coletaImg } from "../../assets/coleta.svg";
import { ReactComponent as coletaBackgroundImg } from "../../assets/coletaIconActiveBackground.svg";
import { ReactComponent as communicatedIcon } from "../../assets/communication/IconForCommunicated.svg";
import { ReactComponent as attention } from "../../assets/communication/attention.svg";
import { ReactComponent as bellNotification } from "../../assets/communication/bell.svg";
import { ReactComponent as bellSoft } from "../../assets/bellsoft.svg";
import { ReactComponent as imagePicture } from "../../assets/communication/image-picture.svg";
import { ReactComponent as redTrash } from "../../assets/contact-us/lixeira.svg";
import { ReactComponent as checkGreen } from "../../assets/contact-us/check-green.svg";
import { ReactComponent as imgEmpty } from "../../assets/contact-us/img-empty.svg";
import { ReactComponent as rightGrenIcon } from "../../assets/contact-us/right-green.svg";
import { ReactComponent as docIcon } from "../../assets/docx.svg";
import { ReactComponent as dolar } from "../../assets/dolar.svg";
import { ReactComponent as addUser } from "../../assets/driverVsCustomer/add_user.svg";
import { ReactComponent as addImg } from "../../assets/add.svg";
import { ReactComponent as threeDots } from "../../assets/driverVsCustomer/more_vert.svg";
import { ReactComponent as EmployeeIconImg } from "../../assets/employeeicon.svg";
import { ReactComponent as fileUploadImg } from "../../assets/fileUpload.svg";
import { ReactComponent as filteraImg } from "../../assets/filter.svg";
import { ReactComponent as fuelProduct } from "../../assets/fuelProduct.svg";
import { ReactComponent as gasStationImg } from "../../assets/gas-station.svg";
import { ReactComponent as userHome } from "../../assets/homeAdmin/01user.svg";
import { ReactComponent as paperHome } from "../../assets/homeAdmin/02paper.svg";
import { ReactComponent as paperTwoHome } from "../../assets/homeAdmin/03paper.svg";
import { ReactComponent as blockedHome } from "../../assets/homeAdmin/04blocked.svg";
import { ReactComponent as songHome } from "../../assets/homeAdmin/05song.svg";
import { ReactComponent as gasolinaHome } from "../../assets/homeAdmin/06gasolina.svg";
import { ReactComponent as telefoneHome } from "../../assets/homeAdmin/07telefone.svg";
import { ReactComponent as imgFileIcon } from "../../assets/icon_file_img.svg";
import { ReactComponent as announcementsIconActive } from "../../assets/iconsSidebar/announcementsActiveIcon.svg";
import { ReactComponent as announcementsIcon } from "../../assets/iconsSidebar/announcementsIcon.svg";
import { ReactComponent as campaignMetricsIconActive } from "../../assets/iconsSidebar/campaignMetricsActiveIcons.svg";
import { ReactComponent as changePasswordIconActive } from "../../assets/iconsSidebar/changePasswordActiveIcon.svg";
import { ReactComponent as changePasswordIcon } from "../../assets/iconsSidebar/changePasswordIcon.svg";
import { ReactComponent as checkListIcon } from "../../assets/iconsSidebar/checklistIcon.svg";
import { ReactComponent as checkListIconPrimary } from "../../assets/iconsSidebar/checklistIconActive.svg";
import { ReactComponent as coletaImgActive } from "../../assets/iconsSidebar/coletaActiveIcon.svg";
import { ReactComponent as contactUsIconActive } from "../../assets/iconsSidebar/contactUsActiveIcon.svg";
import { ReactComponent as contactUsIcon } from "../../assets/iconsSidebar/contactUsIcon.svg";
import { ReactComponent as editIconActive } from "../../assets/iconsSidebar/editActiveIcon.svg";
import { ReactComponent as editIcon } from "../../assets/iconsSidebar/editIcon.svg";
import { ReactComponent as editIconBlackIcon } from "../../assets/iconsSidebar/editBlackIcon.svg";
import { ReactComponent as busTransitIconIcon } from "../../assets/iconsSidebar/busTransitIcon.svg";
import { ReactComponent as employeesIconActive } from "../../assets/iconsSidebar/employeesActiveIcon.svg";
import { ReactComponent as employeesIcon } from "../../assets/iconsSidebar/employeesIcon.svg";
import { ReactComponent as goalsIconActive } from "../../assets/iconsSidebar/goalsActiveIcon.svg";
import { ReactComponent as goalsIcon } from "../../assets/iconsSidebar/goalsIcon.svg";
import { ReactComponent as homeImgActive } from "../../assets/iconsSidebar/homeActiveIcon.svg";
import { ReactComponent as homeImg } from "../../assets/iconsSidebar/homeIcon.svg";
import { ReactComponent as listIconActive } from "../../assets/iconsSidebar/listActiveIcon.svg";
import { ReactComponent as listIcon } from "../../assets/iconsSidebar/listIcon.svg";
import { ReactComponent as logoutIconActive } from "../../assets/iconsSidebar/logoutActiveIcon.svg";
import { ReactComponent as logoutIcon } from "../../assets/iconsSidebar/logoutIcon.svg";
import { ReactComponent as materialsIconActive } from "../../assets/iconsSidebar/materialsActiveIcon.svg";
import { ReactComponent as materialsIcon } from "../../assets/iconsSidebar/materialsIcon.svg";
import { ReactComponent as hamburguerIcon } from "../../assets/iconsSidebar/menuHamburguerIcon.svg";
import { ReactComponent as metricsIconActive } from "../../assets/iconsSidebar/metricsActiveIcon.svg";
import { ReactComponent as metricsIcon } from "../../assets/iconsSidebar/metricsIcon.svg";
import { ReactComponent as priceImgActive } from "../../assets/iconsSidebar/priceActiveIcon.svg";
import { ReactComponent as priceImg } from "../../assets/iconsSidebar/priceIcon.svg";
import { ReactComponent as simulationIconActive } from "../../assets/iconsSidebar/simulationActiveIcon.svg";
import { ReactComponent as simulationIcon } from "../../assets/iconsSidebar/simulationIcon.svg";
import { ReactComponent as unionIconActive } from "../../assets/iconsSidebar/unionActiveIcon.svg";
import { ReactComponent as unionIcon } from "../../assets/iconsSidebar/unionIcon.svg";
import { ReactComponent as marketImg } from "../../assets/market.svg";
import { ReactComponent as warningIcon } from "../../assets/myOrders/Icon.svg";
import { ReactComponent as warnRedImg } from "../../assets/warnRed.svg";
import { ReactComponent as arrowLeftGrayImg } from "../../assets/myOrders/arrowLeftGray.svg";
import { ReactComponent as arrowRightGrayImg } from "../../assets/myOrders/arrowRightGray.svg";
import { ReactComponent as calendarChangeDeliveryDateIcon } from "../../assets/myOrders/calendarChangeDeliveryDate.svg";
import { ReactComponent as calendarChangeDeliveryDateDisabledIcon } from "../../assets/myOrders/calendarChangeDeliveryDateDisabled.svg";
import { ReactComponent as calendarCustomerIcon } from "../../assets/myOrders/calendarcustomer.svg";
import { ReactComponent as closeIconRadiusIcon } from "../../assets/myOrders/closeIconRadius.svg";
import { ReactComponent as folderDumpImg } from "../../assets/myOrders/dump.svg";
import { ReactComponent as filterBarImg } from "../../assets/myOrders/icon-filter.svg";
import { ReactComponent as alertYellowIcon } from "../../assets/alertYellowIcon.svg";
import { ReactComponent as alertDeliveryDateIcon } from "../../assets/myOrders/iconAlertDeliveryDate.svg";
import { ReactComponent as checkDeliveryDateIcon } from "../../assets/myOrders/iconCheckDeliveryDate.svg";
import { ReactComponent as locatorIcon } from "../../assets/myOrders/localizador.svg";
import { ReactComponent as locatorIconDisabled } from "../../assets/myOrders/localizadorDisabled.svg";
import { ReactComponent as folderPaperGray } from "../../assets/myOrders/papelCinza.svg";
import { ReactComponent as folderPaperImg } from "../../assets/myOrders/papelDobrado.svg";
import { ReactComponent as stepNoneIcon } from "../../assets/myOrders/step-none-gray.svg";
import { ReactComponent as stepOkIcon } from "../../assets/myOrders/step-ok-green.svg";
import { ReactComponent as nozzleSVG } from "../../assets/nozzle.svg";
import { ReactComponent as nozzleActiveSVG } from "../../assets/nozzleActive.svg";
import { ReactComponent as nozzleCircleSVG } from "../../assets/nozzleCircle.svg";
import { ReactComponent as observationPencil } from "../../assets/observationPencil.svg";
import { ReactComponent as endGoalIcon } from "../../assets/performanceManagement/fimMeta.svg";
import { ReactComponent as timeIcon } from "../../assets/performanceManagement/horario.svg";
import { ReactComponent as goalVolumeIcon } from "../../assets/performanceManagement/metaVolume.svg";
import { ReactComponent as moneyIcon } from "../../assets/performanceManagement/money.svg";
import { ReactComponent as projectionIcon } from "../../assets/performanceManagement/projecao.svg";
import { ReactComponent as saleIcon } from "../../assets/performanceManagement/vendaMedia.svg";
import { ReactComponent as saleDayIcon } from "../../assets/performanceManagement/venderPorDia.svg";
import { ReactComponent as saleVolumeIcon } from "../../assets/performanceManagement/volumeVendas.svg";
import { ReactComponent as productCart } from "../../assets/productCart.svg";
import { ReactComponent as productCartRed } from "../../assets/productCartRed.svg";
import { ReactComponent as lockWithCircleIcon } from "../../assets/resetPassword/cadeado.svg";
import { ReactComponent as notVisibleIcon } from "../../assets/resetPassword/novisible.svg";
import { ReactComponent as visibleIcon } from "../../assets/resetPassword/visible.svg";
import { ReactComponent as visibleIconBlack } from "../../assets/resetPassword/visibleBlack.svg";
import { ReactComponent as reticenceSVG } from "../../assets/reticence.svg";
import { ReactComponent as roadLoadSVG } from "../../assets/roadLoad.svg";
import { ReactComponent as roadActive } from "../../assets/roadActive.svg";
import { ReactComponent as roadLoadRedSVG } from "../../assets/roadLoadRed.svg";
import { ReactComponent as roadLoadRedFullSVG } from "../../assets/roadLoadRedFull.svg";
import { ReactComponent as scheduleSVG } from "../../assets/schedule.svg";
import { ReactComponent as shoppingCartImg } from "../../assets/shopping-cart.svg";
import { ReactComponent as shoppingCartIcon } from "../../assets/shoppingCart.svg";
import { ReactComponent as success } from "../../assets/success.svg";
import { ReactComponent as ticket } from "../../assets/ticket.svg";
import { ReactComponent as transfer } from "../../assets/transfer.svg";
import { ReactComponent as trashImg } from "../../assets/trash.svg";
import { ReactComponent as trashBaseImg } from "../../assets/trashBase.svg";
import { ReactComponent as warnImg } from "../../assets/warn.svg";
import { ReactComponent as warnTriangleSVG } from "../../assets/warnTriangle.svg";
import { ReactComponent as whatsappImg } from "../../assets/whatsapp.svg";
import { ReactComponent as checkSVG } from "../../assets/check.svg";
import { ReactComponent as blockFinancialSVG } from "../../assets/blockFinancial.svg";
import { ReactComponent as salesImg } from "../../assets/sales.svg";
import { ReactComponent as salesImgActive } from "../../assets/salesActive.svg";
import { ReactComponent as iconStepPending } from "../../assets/iconStepPending.svg";
import { ReactComponent as iconStepCurrent } from "../../assets/iconStepCurrent.svg";
import { ReactComponent as iconStepFinished } from "../../assets/iconStepSelected.svg";

export const TrashIconPlus: React.FC<SvgIconCustomProps> = (props) => {
  const { style, ...otherProps } = props;

  return (
    <SvgIconCustom
      component={trashBaseImg}
      viewBox="0 0 24 24"
      style={style}
      {...otherProps}
    />
  );
};

export const BlockFinancialIcon: React.FC<SvgIconCustomProps> = (props) => {
  const { style, ...otherProps } = props;

  return (
    <SvgIconCustom
      component={blockFinancialSVG}
      viewBox="0 0 43 44"
      style={{
        fill: "none",
        height: "43px",
        width: "44px",
        ...style,
      }}
      {...otherProps}
    />
  );
};

export const CheckIcon: React.FC<SvgIconCustomProps> = (props) => {
  const { style, ...otherProps } = props;

  return (
    <SvgIconCustom
      component={checkSVG}
      viewBox="0 0 43 44"
      style={{
        fill: "none",
        height: "43px",
        width: "44px",
        ...style,
      }}
      {...otherProps}
    />
  );
};

export const LocatorIconDisabled: React.FC<SvgIconCustomProps> = (props) => {
  const { style, sx, ...otherProps } = props;

  return (
    <SvgIconCustom
      component={locatorIconDisabled}
      viewBox="0 0 23 24"
      style={{
        fill: "none",
        height: "24px",
        width: "24px",
      }}
      {...otherProps}
    />
  );
};

export const LocatorIcon: React.FC<SvgIconCustomProps> = (props) => {
  const { style, sx, ...otherProps } = props;

  return (
    <SvgIconCustom
      component={locatorIcon}
      viewBox="0 0 23 24"
      style={{
        fill: "none",
        height: "24px",
        width: "24px",
      }}
      {...otherProps}
    />
  );
};

export const LockWithCircleIcon: React.FC<SvgIconCustomProps> = (props) => {
  const { style, sx, ...otherProps } = props;

  return (
    <SvgIconCustom
      component={lockWithCircleIcon}
      viewBox="0 0 44 44"
      style={{
        fill: "none",
        height: "80px",
        width: "80px",
        margin: "auto",
      }}
      {...otherProps}
    />
  );
};

export const VisibleIcon: React.FC<SvgIconCustomProps> = (props) => {
  const { style, sx, ...otherProps } = props;

  return (
    <SvgIconCustom
      component={visibleIcon}
      viewBox="0 0 16 16"
      style={{
        fill: "none",
        height: "20px",
        width: "20px",
        margin: "auto",
      }}
      {...otherProps}
    />
  );
};

export const VisibleIconBlack: React.FC<SvgIconCustomProps> = (props) => {
  const { style, sx, ...otherProps } = props;

  return (
    <SvgIconCustom
      component={visibleIconBlack}
      viewBox="0 0 16 16"
      style={{
        fill: "none",
        height: "20px",
        width: "25px",
        margin: "auto",
      }}
      {...otherProps}
    />
  );
};

export const NotVisibleIcon: React.FC<SvgIconCustomProps> = (props) => {
  const { style, sx, ...otherProps } = props;

  return (
    <SvgIconCustom
      component={notVisibleIcon}
      viewBox="0 0 16 16"
      style={{
        fill: "none",
        height: "20px",
        width: "20px",
        margin: "auto",
      }}
      {...otherProps}
    />
  );
};

export const ScheduleIcon: React.FC<SvgIconCustomProps> = (props) => {
  const { style, ...otherProps } = props;
  const classes = useStyles();

  return (
    <SvgIconCustom
      classes={{ root: classes.root }}
      component={scheduleSVG}
      viewBox="0 0 17 17"
      style={style}
      {...otherProps}
    />
  );
};

export const BlockIcon: React.FC<SvgIconCustomProps> = (props) => {
  const { style, ...otherProps } = props;
  const classes = useStyles();

  return (
    <SvgIconCustom
      classes={{ root: classes.root }}
      component={blockSVG}
      viewBox="0 0 45 46"
      style={style}
      {...otherProps}
    />
  );
};

export const NozzleCircleIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      component={nozzleCircleSVG}
      viewBox="0 0 54 54"
      style={{ ...props.style, fill: "none" }}
    />
  );
};

export const BilledIcon: React.FC<SvgIconCustomProps> = (props) => {
  const { style, ...otherProps } = props;
  const classes = useStyles();

  return (
    <SvgIconCustom
      classes={{ root: classes.root }}
      component={billedSVG}
      viewBox="0 0 50 50"
      style={style}
      {...otherProps}
    />
  );
};

export const ClockIcon: React.FC<SvgIconCustomProps> = (props) => {
  const { style, ...otherProps } = props;
  const classes = useStyles();

  return (
    <SvgIconCustom
      classes={{ root: classes.root }}
      component={clockSVG}
      viewBox="0 0 43 44"
      style={style}
      {...otherProps}
    />
  );
};

export const RoadBusTransitIcon: React.FC<SvgIconCustomProps> = (props) => {
  const { style, ...otherProps } = props;

  return (
    <SvgIconCustom
      component={busTransitIconIcon}
      viewBox="0 0 22 16"
      style={{
        fill: "none",
        height: "25px",
        width: "25px",
        margin: "auto",
      }}
      {...otherProps}
      {...otherProps}
    />
  );
};

export const RoadLoadRedFullIcon: React.FC<SvgIconCustomProps> = (props) => {
  const { style, ...otherProps } = props;
  const classes = useStyles();

  return (
    <SvgIconCustom
      classes={{ root: classes.root }}
      component={roadLoadRedFullSVG}
      viewBox="0 0 40 40"
      style={style}
      {...otherProps}
    />
  );
};

export const RoadLoadRedIcon: React.FC<SvgIconCustomProps> = (props) => {
  const { style, ...otherProps } = props;
  const classes = useStyles();

  return (
    <SvgIconCustom
      classes={{ root: classes.root }}
      component={roadLoadRedSVG}
      viewBox="0 0 43 44"
      style={style}
      {...otherProps}
    />
  );
};

export const RoadLoadIcon: React.FC<SvgIconCustomProps> = (props) => {
  const { style, ...otherProps } = props;
  const classes = useStyles();

  return (
    <SvgIconCustom
      classes={{ root: classes.root }}
      component={roadLoadSVG}
      viewBox="0 0 27 27"
      style={style}
      {...otherProps}
    />
  );
};

export const RoadActive: React.FC<SvgIconCustomProps> = (props) => {
  const { style, ...otherProps } = props;
  const classes = useStyles();

  return (
    <SvgIconCustom
      classes={{ root: classes.root }}
      component={roadActive}
      viewBox="0 0 27 27"
      style={style}
      {...otherProps}
    />
  );
};

export const WarnTriangleIcon: React.FC<SvgIconCustomProps> = (props) => {
  const { style, sx, ...otherProps } = props;

  return (
    <SvgIconCustom
      component={warnTriangleSVG}
      viewBox="0 0 100 100"
      style={{
        fill: "none",
        height: "100px",
        width: "100px",
        margin: "auto",
      }}
      {...otherProps}
    />
  );
};

export const NozzleReticenceIcon: React.FC<SvgIconCustomProps> = (props) => {
  const { style, sx, ...otherProps } = props;

  return (
    <SvgIconCustom
      component={reticenceSVG}
      viewBox="0 0 26 6"
      style={{
        fill: "none",
        height: "26px",
        width: "16px",
        margin: "auto",
      }}
      {...otherProps}
    />
  );
};

export const NozzleActiveIcon: React.FC<SvgIconCustomProps> = (props) => {
  const { style, sx, ...otherProps } = props;

  return (
    <SvgIconCustom
      component={nozzleActiveSVG}
      viewBox="0 0 20 24"
      style={{
        fill: "none",
        height: "24px",
        width: "24px",
        margin: "auto",
      }}
      {...otherProps}
    />
  );
};

export const NozzleIcon: React.FC<SvgIconCustomProps> = (props) => {
  const { style, ...otherProps } = props;

  return (
    <SvgIconCustom
      component={nozzleSVG}
      viewBox="0 0 20 24"
      style={{
        fill: "none",
        height: "24px",
        width: "24px",
        margin: "auto",
      }}
      {...otherProps}
    />
  );
};

export const CommissionIcon: React.FC<SvgIconCustomProps> = (props) => {
  const { style, ...otherProps } = props;

  return (
    <SvgIconCustom
      component={comissionIcon}
      viewBox="0 0 32 32"
      style={{
        fill: "none",
        height: "32px",
        width: "32px",
        margin: "auto",
      }}
      {...otherProps}
    />
  );
};

export const LiterIcon: React.FC<SvgIconCustomProps> = (props) => {
  const { style, ...otherProps } = props;

  return (
    <SvgIconCustom
      component={literIcon}
      viewBox="0 0 32 28"
      style={{
        fill: "none",
        height: "32px",
        width: "28px",
        margin: "auto",
      }}
      {...otherProps}
    />
  );
};

export const CalendarChangeDeliveryDateIcon: React.FC<SvgIconCustomProps> = (
  props
) => {
  const { style, ...otherProps } = props;

  return (
    <SvgIconCustom
      component={calendarChangeDeliveryDateIcon}
      viewBox="0 0 24 24"
      style={{
        fill: "none",
        height: "24px",
        width: "24px",
        margin: "auto",
      }}
      {...otherProps}
    />
  );
};

export const ArrowDownIconPlus: React.FC<SvgIconCustomProps> = (props) => {
  const { style, ...otherProps } = props;

  return (
    <SvgIconCustom
      component={arrowDown}
      viewBox="0 0 12 7"
      style={{ fill: "none", ...style }}
      {...otherProps}
    />
  );
};

export const ArrowDownIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      component={arrowDown}
      viewBox="0 0 12 7"
      style={{ fill: "none" }}
      {...props}
    />
  );
};

export const CalendarChangeDeliveryDateDisabledIcon: React.FC<SvgIconCustomProps> = (
  props
) => {
  const { style, ...otherProps } = props;

  return (
    <SvgIconCustom
      component={calendarChangeDeliveryDateDisabledIcon}
      viewBox="0 0 24 24"
      style={{
        fill: "none",
        height: "24px",
        width: "24px",
        margin: "auto",
      }}
      {...otherProps}
    />
  );
};

export const CheckDeliveryDateIcon: React.FC<SvgIconCustomProps> = (props) => {
  const { style, ...otherProps } = props;

  return (
    <SvgIconCustom
      component={checkDeliveryDateIcon}
      viewBox="0 0 100 100"
      style={{ fill: "none", ...style }}
      {...otherProps}
    />
  );
};

export const AlertDeliveryDateIcon: React.FC<SvgIconCustomProps> = (props) => {
  const { style, ...otherProps } = props;

  return (
    <SvgIconCustom
      component={alertDeliveryDateIcon}
      viewBox="0 0 100 100"
      style={{ fill: "none", ...style }}
      {...otherProps}
    />
  );
};

export const AlertYellowIcon: React.FC<SvgIconCustomProps> = (props) => {
  const { style, ...otherProps } = props;

  return (
    <SvgIconCustom
      component={alertYellowIcon}
      viewBox="0 0 24 24"
      style={{ fill: "none", ...style }}
      {...otherProps}
    />
  );
};

export const CircleBlockedIcon: React.FC<SvgIconCustomProps> = (props) => {
  const { style, ...otherProps } = props;

  return (
    <SvgIconCustom
      component={circleBlocked}
      viewBox="0 0 40 40"
      style={{ fill: "none", ...style }}
      {...otherProps}
    />
  );
};

export const CircleCheckDisabledIcon: React.FC<SvgIconCustomProps> = (
  props
) => {
  const { style, ...otherProps } = props;

  return (
    <SvgIconCustom
      component={circleCheckDisabled}
      viewBox="0 0 40 40"
      style={{ fill: "none", ...style }}
      {...otherProps}
    />
  );
};

export const CircleCheckIcon: React.FC<SvgIconCustomProps> = (props) => {
  const { style, ...otherProps } = props;

  return (
    <SvgIconCustom
      component={circleCheck}
      viewBox="0 0 40 40"
      style={{ fill: "none", ...style }}
      {...otherProps}
    />
  );
};

export const ShoppingCartOrder: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={shoppingCartIcon}
      viewBox="0 0 100 100"
      style={{
        fill: "none",
        height: "80px",
        width: "80px",
        margin: "auto",
      }}
      {...props}
    />
  );
};

export const AttentionIcon: React.FC<SvgIconCustomProps> = (props) => {
  return (
    <SvgIconCustom
      {...props}
      component={attention}
      viewBox="0 0 100 100"
      style={{ fill: "none" }}
    />
  );
};

export const ImagePictureIcon: React.FC<SvgIconCustomProps> = (props) => {
  return (
    <SvgIconCustom
      {...props}
      component={imagePicture}
      viewBox="0 0 100 100"
      style={{ fill: "none" }}
    />
  );
};

export const BellNotification: React.FC<SvgIconCustomProps> = (props) => {
  return (
    <SvgIconCustom
      {...props}
      component={bellNotification}
      viewBox="0 0 19 23"
      style={{ fill: "none" }}
    />
  );
};

export const BellSoft: React.FC<SvgIconCustomProps> = (props) => {
  return (
    <SvgIconCustom
      {...props}
      component={bellSoft}
      viewBox="0 0 100 100"
      style={{ fill: "none" }}
    />
  );
};

export const ArrowDownSelectIcon: React.FC<SvgIconCustomProps> = (props) => {
  return (
    <SvgIconCustom
      {...props}
      component={arrowDown}
      viewBox="0 0 12 7"
      style={{ fill: "none" }}
    />
  );
};

export const CommunicatedIcon: React.FC<SvgIconCustomProps> = (props) => {
  return (
    <SvgIconCustom
      {...props}
      component={communicatedIcon}
      viewBox="0 0 22 21"
      style={{ fill: "none" }}
    />
  );
};

export const UploadIcon: React.FC<SvgIconCustomProps> = (props) => {
  return (
    <SvgIconCustom
      {...props}
      component={photoIcon}
      viewBox="-15 10 100 63"
      style={{ fill: "none" }}
    />
  );
};

export const CheckGreenIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      component={checkGreen}
      viewBox="0 0 100 100"
      style={{ ...props.style, fill: "none" }}
      {...props}
    />
  );
};

export const RedTrashIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      component={redTrash}
      viewBox="0 0 16 20"
      style={{ ...props.style, fill: "none" }}
      {...props}
    />
  );
};

export const TrashIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      component={trashImg}
      viewBox="0 0 16 20"
      style={{ ...props.style, fill: "none" }}
      {...props}
    />
  );
};

export const AddIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      component={addImg}
      viewBox="0 0 20 20"
      style={{ ...props.style, fill: "none" }}
      {...props}
    />
  );
};

export const TrashBaseIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={trashBaseImg}
      viewBox="0 0 24 24"
      style={{ ...props.style, color: "black" }}
      {...props}
    />
  );
};

export const ImgEmptyIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      component={imgEmpty}
      viewBox="0 0 24 24"
      style={{ ...props.style, fill: "none" }}
      {...props}
    />
  );
};

export const RightGrenIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      component={rightGrenIcon}
      viewBox="0 0 16 12"
      style={{ ...props.style, fill: "none" }}
      {...props}
    />
  );
};

export const EditIconBlack: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      component={editIconBlackIcon}
      viewBox="0 0 18 25"
      style={{ ...props.style, fill: "none", width: "25px", height: "24px" }}
      {...props}
    />
  );
};

export const EditIconActive: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      component={editIconActive}
      viewBox="0 0 20 26"
      style={{ ...props.style, fill: "none" }}
      {...props}
    />
  );
};

export const EditIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      component={editIcon}
      viewBox="0 0 20 26"
      style={{ ...props.style, fill: "none" }}
      {...props}
    />
  );
};

export const CloseIconRadiusIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={closeIconRadiusIcon}
      viewBox="0 0 14 14"
      style={{
        fill: "none",
        height: "80px",
        width: "80px",
        margin: "auto",
      }}
      {...props}
    />
  );
};

export const WarningIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={warningIcon}
      viewBox="0 0 100 100"
      style={{
        fill: "none",
        height: "80px",
        width: "80px",
        margin: "auto",
      }}
      {...props}
    />
  );
};

export const WarnRedIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      component={warnRedImg}
      viewBox="0 0 100 100"
      style={{ height: "10rem", width: "10rem", ...props.style, fill: "none" }}
    />
  );
};

export const UserHomeIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      component={userHome}
      viewBox="0 0 54 54"
      style={{ ...props.style, fill: "none" }}
    />
  );
};

export const PaperHomeIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      component={paperHome}
      viewBox="0 0 54 54"
      style={{ ...props.style, fill: "none" }}
    />
  );
};
export const PaperTwoHomeIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      component={paperTwoHome}
      viewBox="0 0 54 54"
      style={{ ...props.style, fill: "none" }}
    />
  );
};
export const BlockedHomeIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      component={blockedHome}
      viewBox="0 0 54 54"
      style={{ ...props.style, fill: "none" }}
    />
  );
};
export const SongHomeIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      component={songHome}
      viewBox="0 0 54 54"
      style={{ ...props.style, fill: "none" }}
    />
  );
};

export const TelefoneHomeIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      component={telefoneHome}
      viewBox="0 0 54 54"
      style={{ ...props.style, fill: "none" }}
    />
  );
};
export const GasolinaHomeIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      component={gasolinaHome}
      viewBox="0 0 54 54"
      style={{ ...props.style, fill: "none" }}
    />
  );
};
export const SuccessIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      component={success}
      viewBox="0 0 24 18"
      style={{ ...props.style, fill: "none" }}
    />
  );
};
export const TicketIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      component={ticket}
      viewBox="0 0 19 12"
      style={{ ...props.style, fill: "none" }}
    />
  );
};

export const TransferIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      component={transfer}
      viewBox="0 0 19 12"
      style={{ fill: "none", ...props.style }}
    />
  );
};

export const CloseRedIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      component={closeRed}
      viewBox="0 0 14 14"
      style={{ ...props.style, fill: "none" }}
    />
  );
};

export const FuelProductIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      component={fuelProduct}
      viewBox="0 0 40 40"
      style={{ ...props.style, fill: "none" }}
    />
  );
};

export const DolarIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      component={dolar}
      viewBox="0 0 13 22"
      style={{ ...props.style, fill: "none" }}
    />
  );
};

export const ObservationPencilIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      component={observationPencil}
      viewBox="0 0 25 23"
      style={{ ...props.style, fill: "none" }}
    />
  );
};

export const ProductCartRedIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      component={productCartRed}
      viewBox="0 0 100 100"
      style={{ ...props.style, fill: "none" }}
    />
  );
};

export const ProductCartIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      component={productCart}
      viewBox="0 0 26 23"
      style={{ ...props.style, fill: "none" }}
    />
  );
};

export const CheckBoxChecked: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      component={checkBoxchecked}
      viewBox="0 0 12 12"
      style={{ ...props.style, fill: "none" }}
    />
  );
};

export const CheckBoxUnChecked: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      component={checkBoxUnchecked}
      viewBox="0 0 12 12"
      style={{ ...props.style, fill: "none" }}
    />
  );
};

export const StepOkIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={stepOkIcon}
      viewBox="0 0 40 40"
      style={{
        fill: "none",
        height: "40px",
        width: "40px",
        margin: "auto",
      }}
      {...props}
    />
  );
};

export const StepNoneIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={stepNoneIcon}
      viewBox="0 0 40 40"
      style={{
        fill: "none",
        height: "40px",
        width: "40px",
        margin: "auto",
      }}
      {...props}
    />
  );
};

export const CalendarIconCustomer: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={calendarCustomerIcon}
      viewBox="0 0 24 24"
      style={{
        fill: "none",
        height: "24px",
        width: "24px",
        margin: "auto",
      }}
      {...props}
    />
  );
};

export const FilterIconBar: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={filterBarImg}
      viewBox="0 0 120 120"
      style={{ fill: "none", height: "90px", width: "90px" }}
      {...props}
    />
  );
};

export const FilterIconBarMobile: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={filterBarImg}
      viewBox="0 0 120 120"
      style={{ fill: "none", height: "50px", width: "50px" }}
      {...props}
    />
  );
};

export const CactusIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={cactusImg}
      viewBox="0 0 120 120"
      style={{ fill: "none", height: "10rem", width: "10rem" }}
      {...props}
    />
  );
};

export const FoldedPaperIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={folderPaperImg}
      viewBox="0 0 19 24"
      style={{ fill: "none" }}
      {...props}
    />
  );
};

export const FoldedPaperDisabledIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={folderPaperGray}
      viewBox="0 0 19 24"
      style={{ fill: "none" }}
      {...props}
    />
  );
};

export const DumpIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={folderDumpImg}
      viewBox="0 0 24 24"
      style={{ fill: "none" }}
      {...props}
    />
  );
};

export const ColetaWithBackgroungIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={coletaBackgroundImg}
      viewBox="0 0 119 119"
      style={{ fill: "none" }}
      {...props}
    />
  );
};

export const CheckedIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={checkedImg}
      viewBox="0 0 16 12"
      style={{ fill: "none" }}
      {...props}
    />
  );
};

export const FileUploadIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={fileUploadImg}
      viewBox="0 0 24 24"
      style={{ fill: "none" }}
      {...props}
    />
  );
};

export const WarnIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={warnImg}
      viewBox="0 0 100 100"
      style={{ fill: "none" }}
      {...props}
    />
  );
};

export const CheckListIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={checkListIcon}
      viewBox="0 0 18 24"
      style={{ fill: "none" }}
      {...props}
    />
  );
};

export const CheckListIconPrimary: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={checkListIconPrimary}
      viewBox="0 0 18 24"
      style={{ fill: "none" }}
      {...props}
    />
  );
};

export const CampaignMetricsIconActive: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={campaignMetricsIconActive}
      viewBox="0 0 25 25"
      style={{ fill: "none" }}
      {...props}
    />
  );
};

export const CampaignMetricsIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ fill: "none" }}
      component={campaignMetricsImg}
      viewBox="0 0 24 24"
      {...props}
    />
  );
};

export const ArrowRightIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ height: 8, width: 8, fill: "none" }}
      component={arrowRightImg}
      viewBox="0 0 7 12"
      {...props}
    />
  );
};

export const ArrowLeftIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ height: 8, width: 8, fill: "none" }}
      component={arrowLeftImg}
      viewBox="0 0 7 12"
      {...props}
    />
  );
};

export const ArrowRightDarkIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ height: 8, width: 8, fill: "none" }}
      component={arrowRightDark}
      viewBox="0 0 7 12"
      {...props}
    />
  );
};

export const ArrowLeftRedIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ height: 25, width: 25, fill: "none" }}
      component={arrowLeftRed}
      viewBox="0 0 20 20"
      {...props}
    />
  );
};

export const ArrowLeftDarkIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ height: 8, width: 8, fill: "none" }}
      component={arrowLeftDark}
      viewBox="0 0 7 12"
      {...props}
    />
  );
};

export const ArrowRightIconGray: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ height: 11, width: 11, fill: "none" }}
      component={arrowRightGrayImg}
      viewBox="0 0 7 12"
      {...props}
    />
  );
};

export const ArrowLeftIconGray: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ height: 11, width: 11, fill: "none" }}
      component={arrowLeftGrayImg}
      viewBox="0 0 7 12"
      {...props}
    />
  );
};

export const FilterIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ height: 119, width: 119, fill: "none" }}
      component={filteraImg}
      viewBox="0 0 119 119"
      {...props}
    />
  );
};

export const ShoppingCartIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={shoppingCartImg}
      viewBox="0 0 475.084 475.085"
      {...props}
    />
  );
};

export const CancelIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon component={cancelImg} viewBox="0 0 511.76 511.76" {...props} />
  );
};

export const WhatsappIcon: React.FC<SvgIconProps> = (props) => {
  return <SvgIcon component={whatsappImg} viewBox="0 0 512 512" {...props} />;
};

export const GasStationIcon: React.FC<SvgIconProps> = (props) => {
  return <SvgIcon component={gasStationImg} viewBox="0 0 512 512" {...props} />;
};

export const HamburguerIcon: React.FC<SvgIconProps> = (props) => {
  return <SvgIcon component={hamburguerIcon} viewBox="0 0 24 24" {...props} />;
};

export const HomeIcon: React.FC<SvgIconProps> = (props) => {
  return <SvgIcon component={homeImg} viewBox="0 0 25 25" {...props} />;
};

export const HomeIconActive: React.FC<SvgIconProps> = (props) => {
  return <SvgIcon component={homeImgActive} viewBox="0 0 25 25" {...props} />;
};
export const PriceIcon: React.FC<SvgIconProps> = (props) => {
  return <SvgIcon component={priceImg} viewBox="0 0 25 25" {...props} />;
};

export const PriceIconActive: React.FC<SvgIconProps> = (props) => {
  return <SvgIcon component={priceImgActive} viewBox="0 0 25 25" {...props} />;
};

export const SalesIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ fill: "none" }}
      component={salesImg}
      viewBox="0 0 25 25"
      {...props}
    />
  );
};

export const SalesIconActive: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ fill: "none" }}
      component={salesImgActive}
      viewBox="0 0 25 25"
      {...props}
    />
  );
};

export const StepFinishedIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ fill: "none" }}
      component={iconStepFinished}
      viewBox="0 0 25 25"
      {...props}
    />
  );
};

export const StepCurrentIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ fill: "none" }}
      component={iconStepCurrent}
      viewBox="0 0 25 25"
      {...props}
    />
  );
};

export const StepPendingIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ fill: "none" }}
      component={iconStepPending}
      viewBox="0 0 25 25"
      {...props}
    />
  );
};

export const ColetaIconActive: React.FC<SvgIconProps> = (props) => {
  return <SvgIcon component={coletaImgActive} viewBox="0 0 25 25" {...props} />;
};

export const MetricsIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ fill: "none" }}
      component={metricsIcon}
      viewBox="0 0 25 25"
      {...props}
    />
  );
};

export const MetricsIconActive: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ fill: "none" }}
      component={metricsIconActive}
      viewBox="0 0 25 25"
      {...props}
    />
  );
};

export const UnionIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ fill: "none" }}
      component={unionIcon}
      viewBox="0 0 22 28"
      {...props}
    />
  );
};

export const UnionIconActive: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ fill: "none" }}
      component={unionIconActive}
      viewBox="0 0 22 28"
      {...props}
    />
  );
};

export const ListIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ fill: "none" }}
      component={listIcon}
      viewBox="0 0 20 26"
      {...props}
    />
  );
};

export const ListIconActive: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ fill: "none" }}
      component={listIconActive}
      viewBox="0 0 20 26"
      {...props}
    />
  );
};

export const AnnouncementsIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon component={announcementsIcon} viewBox="0 0 25 25" {...props} />
  );
};

export const AnnouncementsIconActive: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={announcementsIconActive}
      viewBox="0 0 25 25"
      {...props}
    />
  );
};
export const MaterialsIcon: React.FC<SvgIconProps> = (props) => {
  return <SvgIcon component={materialsIcon} viewBox="0 0 25 25" {...props} />;
};
export const MaterialsIconActive: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon component={materialsIconActive} viewBox="0 0 25 25" {...props} />
  );
};

export const EmployeesIcon: React.FC<SvgIconProps> = (props) => {
  return <SvgIcon component={employeesIcon} viewBox="0 0 25 25" {...props} />;
};

export const EmployeesIconActive: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon component={employeesIconActive} viewBox="0 0 25 25" {...props} />
  );
};

export const ContactUsIcon: React.FC<SvgIconProps> = (props) => {
  return <SvgIcon component={contactUsIcon} viewBox="0 0 25 25" {...props} />;
};
export const ContactUsIconActive: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon component={contactUsIconActive} viewBox="0 0 25 25" {...props} />
  );
};

export const ChangePasswordIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon component={changePasswordIcon} viewBox="0 0 25 25" {...props} />
  );
};

export const ChangePasswordIconActive: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={changePasswordIconActive}
      viewBox="0 0 25 25"
      {...props}
    />
  );
};

export const LogoutIcon: React.FC<SvgIconProps> = (props) => {
  return <SvgIcon component={logoutIcon} viewBox="0 0 25 25" {...props} />;
};
export const LogoutIconActive: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon component={logoutIconActive} viewBox="0 0 25 25" {...props} />
  );
};

export const GoalsIcon: React.FC<SvgIconProps> = (props) => {
  return <SvgIcon component={goalsIcon} viewBox="0 0 25 25" {...props} />;
};
export const GoalsIconActive: React.FC<SvgIconProps> = (props) => {
  return <SvgIcon component={goalsIconActive} viewBox="0 0 25 25" {...props} />;
};
export const SimulationIcon: React.FC<SvgIconProps> = (props) => {
  return <SvgIcon component={simulationIcon} viewBox="0 0 29 29" {...props} />;
};

export const SimulationIconActive: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon component={simulationIconActive} viewBox="0 0 29 29" {...props} />
  );
};

export const EmployeeIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ fill: "none" }}
      component={EmployeeIconImg}
      viewBox="0 0 40 40"
      {...props}
    />
  );
};

export const CloseIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      style={{ ...props.style, fill: "none", fontSize: "1.5rem" }}
      component={closeImg}
      viewBox="0 0 14 14"
    />
  );
};

export const AvatarUserIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ fill: "none" }}
      component={avatarUserImg}
      viewBox="0 0 119 119"
      {...props}
    />
  );
};

export const MarketIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ fill: "none", width: "40px", height: "40px" }}
      component={marketImg}
      viewBox="0 0 40 40"
      {...props}
    />
  );
};

export const CalendarIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ fill: "none", width: "16px", height: "16px" }}
      component={calendarImg}
      viewBox="0 0 16 16"
      {...props}
    />
  );
};

export const ColetaIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      style={{ fill: "none" }}
      component={coletaImg}
      viewBox="0 0 27 24"
      {...props}
    />
  );
};

export const PhotoDownloadIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={photoIcon}
      viewBox="-15 10 100 63"
      style={{
        fill: "none",
        height: "7rem",
        width: "7rem",
      }}
      {...props}
    />
  );
};

export const DocIcon: React.FC<SvgIconProps> = (props) => {
  return <SvgIcon component={docIcon} viewBox="0 0 20 24" {...props} />;
};

export const ImgFileIcon: React.FC<SvgIconProps> = (props) => {
  return <SvgIcon component={imgFileIcon} viewBox="0 0 80 80" {...props} />;
};

export const EndGoalIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={endGoalIcon}
      viewBox="0 0 80 80"
      {...props}
      style={{
        fill: "none",
        height: 60,
        width: 60,
      }}
    />
  );
};

export const TimeIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={timeIcon}
      viewBox="0 0 50 50"
      {...props}
      style={{
        fill: "none",
        height: 50,
        width: 50,
      }}
    />
  );
};

export const GoalVolumeIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={goalVolumeIcon}
      viewBox="0 0 80 80"
      {...props}
      style={{
        fill: "none",
        height: 60,
        width: 60,
      }}
    />
  );
};

export const ProjectionIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={projectionIcon}
      viewBox="0 0 50 50"
      {...props}
      style={{
        fill: "none",
        height: 50,
        width: 50,
      }}
    />
  );
};

export const SaleDayIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={saleDayIcon}
      viewBox="0 0 80 80"
      {...props}
      style={{
        fill: "none",
        height: 60,
        width: 60,
      }}
    />
  );
};

export const SaleVolumeIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={saleVolumeIcon}
      viewBox="0 0 50 50"
      {...props}
      style={{
        fill: "none",
        height: 50,
        width: 50,
      }}
    />
  );
};

export const SaleIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={saleIcon}
      viewBox="0 0 50 50"
      {...props}
      style={{
        fill: "none",
        height: 50,
        width: 50,
      }}
    />
  );
};

export const MoneyIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={moneyIcon}
      viewBox="0 0 50 50"
      {...props}
      style={{
        fill: "none",
        height: 50,
        width: 50,
      }}
    />
  );
};

export const AddUser: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={addUser}
      {...props}
      style={{
        fill: "none",
        height: 50,
        width: "100%",
      }}
    />
  );
};

export const ThreeDots: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      component={threeDots}
      {...props}
      style={{
        fill: "none",
        height: "100%",
        width: "100%",
      }}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      "&.MuiSvgIcon-root": {
        fill: "none",
        fontSize: "inherit",
        height: "inherit",
        width: "inherit",
        margin: "auto",
      },
      [theme?.breakpoints?.down("sm")]: {
        // 'sm' para dispositivos mobile, ajuste conforme necessário
        "&.MuiSvgIcon-root": {
          width: "30px", // Exemplo de width diferente para mobile
        },
      },
    },
  });
});
