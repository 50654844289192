import React from "react";
import { TruckLayouts } from "./TruckLayouts";
import { useTheme } from "@material-ui/core";

interface TruckSVGProps {
  onClickCompartment?: (id: number) => void;
  filledCompartments: number[];
  occupiedCompartments: number[];
  allocatedColor?: string;
  occupiedColor?: string;
  compartmentQuantity: number;
}

const TruckSVG: React.FC<TruckSVGProps> = ({
  onClickCompartment,
  filledCompartments,
  occupiedCompartments,
  allocatedColor,
  occupiedColor,
  compartmentQuantity,
}) => {
  const theme = useTheme();

  const defaultAllocatedColor = allocatedColor || theme.palette.success.main;
  const defaultOccupiedColor = occupiedColor || theme.palette.error.main;

  //NOTE: Função para determinar a cor de cada compartimento
  const getColor = (compartmentId: number) => {
    if (filledCompartments.includes(compartmentId)) {
      return defaultAllocatedColor;
    } else if (occupiedCompartments.includes(compartmentId)) {
      return defaultOccupiedColor;
    }
    return theme.palette.background.paper; //NOTE: Compartimento vazio (cor de fundo do tema)
  };

  //NOTE: Se a quantidade de compartimentos for maior que 10, limitamos a 10
  const layoutKey = compartmentQuantity > 10 ? 10 : compartmentQuantity;
  const LayoutToRender = TruckLayouts[layoutKey];

  //NOTE: Se não houver layout para a quantidade especificada, não renderiza nada
  if (!LayoutToRender) {
    return null;
  }

  return (
    <svg
      width="214"
      height="84"
      viewBox="0 0 214 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ maxWidth: "100%" }}
    >
      <path
        d="M167.001 22.4925V41C167.001 43.2091 165.21 45 163.001 45H83.5011H4.0011C1.79196 45 0.00109863 43.2091 0.00109863 41V22.5981V4.19612C0.00109863 1.98698 1.79196 0.196114 4.0011 0.196114H24.3827C37.8033 0.196114 75.3739 0.150857 107.883 0.0905151L162.995 0.00613371C165.207 0.00274768 167.001 1.7946 167.001 4.00613V22.4925Z"
        fill={theme.palette.grey[200]}
      />
      <path
        d="M203.017 8.62842C203.251 9.26373 205.424 15.0317 207.815 21.4684C210.223 27.9051 212.597 34.275 213.098 35.6125L214.001 38.0534L213.968 53.1672L213.918 68.2809L213.55 68.9664C213.148 69.702 212.346 70.4711 211.711 70.7219C211.51 70.7887 209.671 70.8723 207.631 70.8723L203.92 70.889L203.201 68.6989C201.161 62.4962 197.516 57.932 192.852 55.7251C190.846 54.7722 189.692 54.4879 187.502 54.3709C181.45 54.07 176.133 57.4639 172.305 64.0678L171.836 64.8536H161.504H151.172L150.202 63.4826C147.394 59.4534 143.364 56.5276 138.984 55.374C137.613 55.0229 136.961 54.9394 135.089 54.9394C132.748 54.9226 131.477 55.14 129.571 55.8422C124.288 57.7983 120.543 63.0814 119.908 69.4679L119.791 70.6215L116.882 70.6717L113.973 70.7051L114.073 69.3342C114.458 64.5526 117.668 57.8819 121.613 53.7022C124.021 51.1442 126.144 49.7398 128.485 49.1379C129.755 48.8203 130.324 48.8036 150.487 48.8036H171.184L171.084 28.2729L170.984 7.7256L173.893 7.62529C175.498 7.55842 182.603 7.50826 189.692 7.50826H202.582L203.017 8.62842Z"
        fill={theme.palette.grey[200]}
      />
      <path
        d="M56.292 48.9869C61.642 49.7726 67.6775 56.3765 70.5364 64.5687C71.1382 66.3075 71.7234 69.0159 71.7234 70.1026V70.8717L68.8478 70.8383L65.9721 70.7881L65.7548 69.1998C64.9523 62.9972 61.6587 58.3159 56.3923 55.875C54.1185 54.8217 52.2795 54.4372 49.4039 54.4372C46.6954 54.4205 45.5418 54.6211 43.4854 55.4403C41.3287 56.293 38.7875 58.2825 38.7875 59.1184V59.503H35.8617H32.9359V59.1017C32.9359 58.8509 32.6182 58.3995 32.0498 57.8478C28.4051 54.2198 21.4501 53.317 15.6989 55.7412C10.5996 57.8812 7.13886 62.412 6.11902 68.2803C5.96855 69.0995 5.85152 70.0358 5.85152 70.32V70.8717H2.92574H0V70.32C0 69.25 0.534956 66.7422 1.17027 64.853C2.75855 60.0881 5.2998 55.992 8.64355 52.8155C10.516 51.0433 12.1043 50.0234 14.0103 49.338L15.2976 48.8865L35.2765 48.8698C46.4279 48.8531 55.7068 48.9033 56.292 48.9869Z"
        fill={theme.palette.grey[200]}
      />
      <path
        d="M189.842 57.7473C194.373 58.7337 198.018 62.1778 199.472 66.859C200.024 68.6479 200.041 71.8913 199.489 73.7638C198.135 78.4618 194.54 81.8557 189.742 82.9926C188.388 83.3102 185.83 83.3102 184.375 82.9926C177.872 81.6217 173.324 75.302 174.21 68.8653C174.645 65.6553 175.765 63.4317 178.056 61.1579C179.243 59.9709 179.778 59.5863 181.082 58.9343C183.222 57.881 184.844 57.4965 187.067 57.5132C188.037 57.5132 189.291 57.6135 189.842 57.7473Z"
        fill={theme.palette.grey[200]}
      />
      <path
        d="M139.137 57.8287C141.445 58.3971 143.618 59.6677 145.357 61.4734C146.427 62.5601 147.029 63.4796 147.714 64.9342C148.617 66.8401 148.868 68.0104 148.868 70.3677C148.868 72.8588 148.634 73.9288 147.513 76.2193C146.845 77.5735 146.494 78.0417 145.139 79.3959C143.367 81.1681 141.929 82.0374 139.605 82.7731C138.519 83.1074 138.017 83.1576 135.927 83.1409C133.754 83.1409 133.369 83.0907 132.166 82.6895C125.077 80.3154 121.415 72.9592 123.873 66.0209C124.475 64.3156 125.227 63.0617 126.531 61.6573C128.37 59.6677 130.444 58.4306 132.968 57.8287C134.757 57.394 137.365 57.394 139.137 57.8287Z"
        fill={theme.palette.grey[200]}
      />
      <path
        d="M53.6662 57.8482C58.3976 59.0854 62.0089 62.7635 63.1123 67.4447C63.4634 68.9996 63.4634 71.6244 63.1123 73.2127C61.524 80.2011 54.4854 84.5815 47.5471 82.9096C43.4343 81.9399 40.1909 79.1479 38.5692 75.219C35.5264 67.8962 40.0237 59.3863 47.7812 57.7479C49.4029 57.3968 52.1448 57.4469 53.6662 57.8482Z"
        fill={theme.palette.grey[200]}
      />
      <path
        d="M25.0627 57.829C25.7315 57.9794 27.0021 58.481 27.8882 58.9157C29.209 59.5677 29.744 59.969 30.931 61.156C34.5255 64.7171 35.7126 69.2479 34.3416 74.0294C33.7398 76.1193 32.7701 77.7243 31.0982 79.4129C28.6907 81.8538 26.0491 83.0409 22.6385 83.1913C20.1307 83.3251 18.509 82.9907 16.2185 81.8705C14.6637 81.1182 14.2959 80.8507 12.8748 79.4129C11.0691 77.5905 10.1496 76.0524 9.5143 73.7954C8.96258 71.8727 8.96258 68.8299 9.49758 66.9407C10.8351 62.2929 14.6302 58.7151 19.2446 57.7454C20.8329 57.411 23.5246 57.4444 25.0627 57.829Z"
        fill={theme.palette.grey[200]}
      />

      {/* NOTE: 
        Aqui chamamos o layout de compartimentos.
        Observe que estes "paths" ou "rect" são os que você
        definiu em TruckLayouts[layoutKey].
        Agore temos um caminhão completamente dinâmico
      */}
      {LayoutToRender(getColor, onClickCompartment)}
    </svg>
  );
};

export default TruckSVG;
